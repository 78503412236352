/* eslint-disable func-names */
import * as yup from 'yup';

import { CSC } from 'pages/Admin/_ProposalsFormUtils/constants';
import { store } from '../../../../store/company/reducer';
import {
	string,
	stringRequired,
	stringLongRequired,
	number,
	numberPositiveRequired,
	numberRequired,
	boolean,
	dateRequired
} from 'lib/utils/formUtils';

const state = store.getState();

export const createYupSchema = (companyCode: string) => {
	let schema;
	if (companyCode === 'PT') {
		const general = {
			// GENERAL INFORMATION - left
			collaborationType: stringRequired,
			name: stringRequired,
			username: string,
			email: stringRequired.email('Must be a valid email'),
			jiraCandidateApplication: string,
			startDate: dateRequired,
			// GENERAL INFORMATION - right
			company: stringRequired,
			businessUnit: stringRequired,
			division: stringRequired,
			department: stringRequired,
			collaborationRole: stringRequired
		};
		const careerFinancial = {
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: boolean,
			careerPosition: stringRequired,
			careerJobRole: stringRequired,
			careerManager: stringRequired,
			careerJobRoleLevel: stringRequired,
			// CAREER & FINANCIAL - CAREER INFO
			holdersNumber: numberRequired,
			disabled: boolean,
			maritalStatus: stringRequired,
			tsuExemption: boolean,
			dependentsNumber: numberRequired,
			taxZone: string,
			surtaxZone: string,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: numberPositiveRequired,
			irsToApply: numberRequired, // readOnly
			ihtValue: numberRequired, // readOnly
			ihtPercentage: number,
			hasTwelfths: boolean,
			vacationsTwelfth: numberRequired, // readOnly
			annualGrossValue: number, // readOnly
			christmasTwelfth: numberRequired, // readOnly
			grossBaseValue: numberRequired, // readOnly
			totalTaxableValue: numberRequired, // readOnly
			foodAllowanceType: stringRequired,
			foodAllowance: numberPositiveRequired,
			foodAllowanceDays: numberRequired,
			nationalAllowanceDays: number,
			nationalAllowance: number.when('nationalAllowanceDays', {
				is: (val: number) => val > 0,
				then: numberRequired,
				otherwise: number
			}),
			internationalAllowanceDays: number,
			internationalAllowance: number.when('internationalAllowanceDays', {
				is: (val: number) => val > 0,
				then: numberRequired,
				otherwise: number
			}),
			travelPass: number,
			additionalExpenses: number,
			nurseryCheck: number,
			lifeInsurance: number,
			hourlyRate: number,
			budget: number,
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: boolean,
			prizeBonus: number,
			prizeBonusPercentage: number,
			monthsApplicable: number,
			eligiblePrizeValue: number,
			prizeBonusGoals: yup.array().when('prizeBonus', {
				is: (val: number) => Number(val) > 0,
				then: yup
					.array()
					.of(
						yup.object().shape({
							id: string,
							goalDetails: stringLongRequired,
							goalTypeId: numberRequired,
							prizePercentage: numberPositiveRequired,
							prizeValue: numberPositiveRequired
						})
					)
					.min(1, 'Must have at least one prize goal when MAXIMUM PRIZE BONUS is set.')
					.test('not-100-percent', 'The sum of all prize goals must be 100%', (data): boolean => {
						if (data) {
							const totalPercentages = data.reduce(
								(total, current) =>
									current?.prizePercentage ? current.prizePercentage + total : total,
								0
							);
							// return true -> the error will NOT show
							// return false -> the error will show
							return totalPercentages === 100;
						}
						return true;
					})
					.test('not-empty-percentages', "Can't exist goals with no percentage value", data =>
						// return true -> the error will NOT show
						// return false -> the error will show
						data ? !data.map(e => e && Number(e.prizePercentage)).includes(0) : true
					),
				otherwise: yup.array()
			}),
			annualPrizeIRS: stringRequired,
			totalComponentsPrizePercentage: stringRequired,
			totalComponentsPrizeValue: stringRequired
		};
		const otherBenefits = {
			commsPlafondFlag: boolean,
			commsPlafond: number.when('commsPlafondFlag', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			healthInsuranceCheck: boolean,
			healthInsuranceValue: number.when('healthInsuranceCheck', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			includeFamilyMembers: boolean,
			numberMembersDep: number.when('healthInsuranceCheck', {
				is: true,
				then: numberRequired,
				otherwise: number
			}),
			familyMembersComments: string,
			otherbenefits: boolean,
			otherBenefitsList: yup.array().of(
				yup.object().shape({
					id: string,
					goalDetails: stringLongRequired,
					prizeValue: numberPositiveRequired
				})
			),
			benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag'], {
				is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean) =>
					!healthInsuranceCheck || !commsPlafondFlag,
				then: stringRequired,
				otherwise: string
			})
		};

		schema = yup.object().shape({
			// PROPOSAL DB INFO
			proposalId: string,
			token: string,
			// GENERAL INFORMATION
			...general,
			// CAREER & FINANCIAL
			...careerFinancial,
			// OTHER BENEFITS
			...otherBenefits
		});
	} else if (companyCode === 'HR') {
		const general = {
			// GENERAL INFORMATION - left
			collaborationType: stringRequired,
			name: stringRequired,
			username: string,
			email: stringRequired.email('Must be a valid email'),
			jiraCandidateApplication: string,
			startDate: dateRequired,
			// GENERAL INFORMATION - right
			company: stringRequired,
			businessUnit: stringRequired,
			division: stringRequired,
			department: stringRequired,
			collaborationRole: stringRequired
		};
		const careerFinancial = {
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: boolean,
			careerPosition: stringRequired,
			careerJobRole: stringRequired,
			careerManager: stringRequired,
			// CAREER & FINANCIAL - CAREER INFO
			holdersNumber: numberRequired,
			maritalStatus: stringRequired,
			dependentsNumber: numberRequired,
			surtaxZone: string,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: numberPositiveRequired,
			travelPass: number,
			additionalExpenses: number,
			nurseryCheck: number,
			lifeInsurance: number,
			hourlyRate: number,
			budget: number
		};
		const otherBenefits = {
			commsPlafondFlag: boolean,
			commsPlafond: number.when('commsPlafondFlag', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			healthInsuranceCheck: boolean,
			healthInsuranceValue: number.when('healthInsuranceCheck', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			includeFamilyMembers: boolean,
			numberMembersDep: number.when('healthInsuranceCheck', {
				is: true,
				then: numberRequired,
				otherwise: number
			}),
			familyMembersComments: string,
			otherbenefits: boolean,
			otherBenefitsList: yup.array().of(
				yup.object().shape({
					id: string,
					goalDetails: stringLongRequired,
					prizeValue: numberPositiveRequired
				})
			),
			benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag'], {
				is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean) =>
					!healthInsuranceCheck || !commsPlafondFlag,
				then: stringRequired,
				otherwise: string
			})
		};

		schema = yup.object().shape({
			// PROPOSAL DB INFO
			proposalId: string,
			token: string,
			// GENERAL INFORMATION
			...general,
			// CAREER & FINANCIAL
			...careerFinancial,
			// OTHER BENEFITS
			...otherBenefits
		});
	}
	return schema;
};

export const createDefaultValues = (companyCode: string, referenceData: any) => {
	// PROPOSAL DB INFO
	if (companyCode === 'PT') {
		return {
			// PROPOSAL DB INFO
			proposalId: '',
			token: '',
			// ------------------------------------------- GENERAL INFORMATION
			collaborationType: CSC,
			name: '',
			email: '',
			jiraCandidateApplication: '',
			startDate: '',
			//
			company: state.defaultCompanyKey,
			businessUnit: '',
			division: '',
			department: '',
			collaborationRole: 'EMPLOYEE',
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - USER DATA
			// importFromAnotherProposal: false, // form helper
			importFromAnotherProposal: true, // form helper
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: false,
			careerPosition: '',
			careerJobRole: '',
			careerJobRoleLevel: '',
			careerManager: '',
			// CAREER & FINANCIAL - TAXES
			holdersNumber: '1',
			disabled: false,
			maritalStatus: 'Single',
			tsuExemption: false,
			dependentsNumber: 0,
			taxZone: undefined,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: '0',
			irsToApply: '0',
			ihtValue: '0',
			ihtPercentage: '25.00',
			hasTwelfths: true,
			vacationsTwelfth: '0',
			christmasTwelfth: '0',
			irsTwelfth: '',
			prizeValue: '0',
			username: '',
			workScheduleExemptionValue: '0',
			annualGrossValue: '0',
			grossBaseValue: '0',
			totalTaxableValue: '0',
			foodAllowanceType: 'Card',
			foodAllowance: 0,
			foodAllowanceDays: '20',
			nationalAllowanceDays: '0',
			nationalAllowance: '0',
			internationalAllowanceDays: '0',
			internationalAllowance: '0',
			travelPass: '0',
			additionalExpenses: '0',
			nurseryCheck: '0',
			lifeInsurance: '0',
			hourlyRate: 0,
			budget: 0,
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: false,
			payBonusSalary: false,
			bonusSalary: 0,
			prizeBonus: '0',
			prizeBonusPercentage: 0,
			monthsApplicable: '12',
			eligiblePrizeValue: 0,
			prizeBonusGoals: [],
			annualPrizeIRS: '0',
			totalComponentsPrizePercentage: '0',
			totalComponentsPrizeValue: '0',
			careerAndFinancialNotes: '',
			// OTHER BENEFITS
			commsPlafondFlag: true,
			commsPlafond: referenceData?.xphrMonStdComPlafond,
			healthInsuranceCheck: true,
			healthInsuranceValue: referenceData?.xphrMonStdHeaInsCost,
			includeFamilyMembers: false,
			numberMembersDep: '0',
			familyMembersComments: '',
			otherbenefits: true,
			otherBenefitsList: [{ goalDetails: 'Remote Work Allowance', prizeValue: 15 }],
			benefitsComments: ''
		};
	} else if (companyCode === 'HR') {
		return {
			// PROPOSAL DB INFO
			proposalId: '',
			token: '',
			// ------------------------------------------- GENERAL INFORMATION
			collaborationType: CSC,
			name: '',
			email: '',
			jiraCandidateApplication: '',
			startDate: '',
			//
			company: 'HR',
			businessUnit: '',
			division: '',
			department: '',
			collaborationRole: 'EMPLOYEE',
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - USER DATA
			// importFromAnotherProposal: false, // form helper
			importFromAnotherProposal: true, // form helper
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: false,
			careerPosition: '',
			careerJobRole: '',
			careerJobRoleLevel: '',
			careerManager: '',
			// CAREER & FINANCIAL - TAXES
			holdersNumber: '1',
			disabled: false,
			maritalStatus: 'Single',
			tsuExemption: false,
			dependentsNumber: 0,
			taxZone: undefined,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: '0',
			irsToApply: '0',
			ihtValue: '0',
			ihtPercentage: '25.00',
			hasTwelfths: false,
			vacationsTwelfth: '0',
			christmasTwelfth: '0',
			irsTwelfth: '',
			prizeValue: '0',
			username: '',
			workScheduleExemptionValue: '0',
			annualGrossValue: '0',
			grossBaseValue: '0',
			totalTaxableValue: '0',
			foodAllowanceType: 'Card',
			foodAllowance: referenceData?.xphrCrFoodAllowValue,
			foodAllowanceDays: '20',
			nationalAllowanceDays: '0',
			nationalAllowance: '0',
			internationalAllowanceDays: '0',
			internationalAllowance: '0',
			travelPass: '0',
			additionalExpenses: '0',
			nurseryCheck: '0',
			lifeInsurance: '0',
			hourlyRate: 0,
			budget: 0,
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: false,
			prizeBonus: '0',
			prizeBonusPercentage: 0,
			monthsApplicable: '12',
			eligiblePrizeValue: 0,
			prizeBonusGoals: [],
			annualPrizeIRS: '0',
			totalComponentsPrizePercentage: '0',
			totalComponentsPrizeValue: '0',
			careerAndFinancialNotes: '',
			// OTHER BENEFITS
			commsPlafondFlag: true,
			commsPlafond: referenceData?.xphrMonStdComPlafond,
			healthInsuranceCheck: true,
			healthInsuranceValue: referenceData?.xphrMonStdHeaInsCost,
			includeFamilyMembers: false,
			numberMembersDep: '0',
			familyMembersComments: '',
			otherbenefits: true,
			otherBenefitsList: [{ goalDetails: 'Transportation Allowance', prizeValue: 33 }],
			benefitsComments: ''
		};
	}
};
