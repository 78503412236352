import { createReducer } from 'typesafe-actions';
import { GuidesState, GuidesActions as Actions } from 'typings/store/guidesTypes';
import { legacy_createStore as createStore } from 'redux';

const INITIAL_STATE: GuidesState = {
	guide: null,
	userGuide: null
};

const GuidesReducer = createReducer(INITIAL_STATE, {
	[Actions.GET_GUIDE]: (state, { payload }) => ({
		...state,
		guide: payload
	}),
	[Actions.GET_USER_GUIDE]: (state, { payload }) => ({
		...state,
		userGuide: payload
	}),
	[Actions.MARK_USER_GUIDE_AS_READ]: (state, { payload }) => ({
		...state,
		loading: false
	})
});

export const store = createStore(GuidesReducer);

export default GuidesReducer;
