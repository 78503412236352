/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import * as yup from 'yup';

import { COLLABTYPE, EMPLOYEE } from 'pages/Admin/_ProposalsFormUtils/constants';

import {
	string,
	stringRequired,
	stringLongRequired,
	number,
	numberPositiveRequired,
	numberZeroOrPositiveRequired,
	numberRequired,
	boolean,
	dateDefault,
	dateRequired,
	passwordSecure,
	numberPositive,
	nissRequired
} from 'lib/utils/formUtils';
import {
	BALWURK_EMPLOYEE_ID,
	INSTANCE,
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_HR,
	XPAND_EMPLOYEE_ID_UK,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_DE
} from 'lib/utils/constants';
import { store } from '../../../../../store/company/reducer';

const state = store.getState();
const digitsOnly = value => /^\d+$/.test(value);

const general = formFields => {
	return {
		// GENERAL INFORMATION - left
		collaborationType: formFields?.formType ? stringRequired : string,
		name: formFields?.name ? stringRequired : string,
		username: formFields?.username ? stringRequired : string,
		email: formFields?.email ? stringRequired : string,
		jiraCandidateApplication: string,
		startDate: formFields?.startDate ? dateRequired : dateDefault,
		// GENERAL INFORMATION - right
		company: formFields?.company ? stringRequired : string,
		businessUnit: formFields?.businessUnit ? stringRequired : string,
		division: formFields?.division ? stringRequired : string,
		department: formFields?.department ? stringRequired : string,
		employeeCategory: formFields?.employeeCategory ? stringRequired : string,
		collaborationRole: formFields?.collabRole ? stringRequired : string
	};
};
const generalHR = formFields => {
	return {
		// GENERAL INFORMATION - left
		collaborationType: formFields?.formType ? stringRequired : string,
		name: formFields?.name ? stringRequired : string,
		username: formFields?.username ? stringRequired : string,
		email: formFields?.email ? stringRequired : string,
		jiraCandidateApplication: string,
		startDate: formFields?.startDate ? dateRequired : dateDefault,
		// GENERAL INFORMATION - right
		company: formFields?.company ? stringRequired : string,
		businessUnit: formFields?.businessUnit ? stringRequired : string,
		division: formFields?.division ? stringRequired : string,
		department: formFields?.department ? stringRequired : string,
		employeeCategory: formFields?.employeeCategory ? stringRequired : string,
		collaborationRole: formFields?.collabRole ? stringRequired : string
	};
};

const employeeCategoryCompare = INSTANCE.toLowerCase().includes('xpand')
	? [XPAND_EMPLOYEE_ID, XPAND_EMPLOYEE_ID_HR, XPAND_EMPLOYEE_ID_UK, XPAND_EMPLOYEE_ID_SE, XPAND_EMPLOYEE_ID_DE]
	: [BALWURK_EMPLOYEE_ID];

const careerFinancial = formFields => {
	return {
		// ------------------------------------------- CAREER & FINANCIAL
		// CAREER & FINANCIAL - CAREER INFO
		softwareDevelopmentCenter: boolean,
		careerPosition: formFields?.careerPosition ? stringRequired : string,
		careerJobRole: formFields?.careerJobRole ? stringRequired : string,
		careerManager: formFields?.careerManager ? stringRequired : string,
		careerJobRoleLevel: formFields?.careerJobRoleLevel ? stringRequired : string,
		// CAREER & FINANCIAL - CAREER INFO
		socialSecurityNumber: formFields?.socialSecurityNumber
			? stringRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: nissRequired,
					otherwise: string
			  })
			: string,
		holdersNumber: formFields?.holders
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		disabled: boolean,
		maritalStatus: formFields?.maritalStatus
			? stringRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		tsuExemption: boolean,
		dependentsNumber: formFields?.numberDependents
			? numberRequired.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberRequired;
					} else {
						return number;
					}
			  })
			: number,
		taxZone: string,
		// CAREER & FINANCIAL - GENERAL AMOUNTS
		baseValue: formFields?.baseValue
			? number.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberPositiveRequired;
					} else {
						return number;
					}
			  })
			: number,
		irsToApply: formFields?.irsTax ? numberRequired : number, // readOnly
		ihtValue: formFields?.workScheduleExemptionValue ? numberRequired : string, // readOnly
		ihtPercentage: number,
		annualGrossValue: number,
		hasTwelfths: boolean,
		vacationsTwelfth: formFields?.vacationsTwelfth
			? numberRequired.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberRequired;
					} else {
						return number;
					}
			  })
			: number, // readOnly
		christmasTwelfth: formFields?.christmasTwelfth
			? numberRequired.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberRequired;
					} else {
						return number;
					}
			  })
			: number, // readOnly
		grossBaseValue: formFields?.baseValue ? numberRequired : number, // readOnly
		totalTaxableValue: formFields?.totalTaxableValue ? numberRequired : number, // readOnly
		foodAllowanceType: formFields?.foodAllowanceType
			? stringRequired.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return stringRequired;
					} else {
						return string;
					}
			  })
			: string,
		foodAllowance: formFields?.foodAllowance
			? numberRequired.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberRequired;
					} else {
						return number;
					}
			  })
			: number,
		foodAllowanceDays: formFields?.foodAllowanceDays
			? numberRequired.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberRequired;
					} else {
						return number;
					}
			  })
			: number,
		nationalAllowanceDays: number,
		nationalAllowance: formFields?.nationalAllowance
			? number.when('nationalAllowanceDays', {
					is: (val: number) => val > 0,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		internationalAllowanceDays: number,
		internationalAllowance: formFields?.internationalAllowance
			? number.when('internationalAllowanceDays', {
					is: (val: number) => val > 0,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		travelPass: number,
		additionalExpenses: number,
		nurseryCheck: number,
		lifeInsurance: number,
		hourlyRate: formFields?.hourlyRate
			? number.when('employeeCategory', function (this, employeeCategory, schema) {
					if (!employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberPositiveRequired;
					} else {
						return number;
					}
			  })
			: number,
		budget: formFields?.monthlyBudget
			? number.when('employeeCategory', function (this, employeeCategory, schema) {
					if (!employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberPositive;
					} else {
						return number;
					}
			  })
			: number,
		companySocSec: formFields?.companySocialSecurityPercentage ? numberPositiveRequired : number,
		estimuloMedida: boolean,
		// CAREER & FINANCIAL - PRIZE BONUS
		createPrizeBonusErp: boolean,
		prizeBonus: number,
		prizeBonusPercentage: number,
		monthsApplicable: number,
		eligiblePrizeValue: number,
		prizeBonusGoals: yup.array().when('prizeBonus', {
			is: (val: number) => val > 0,
			then: yup
				.array()
				.of(
					yup.object().shape({
						id: string,
						goalDetails: stringLongRequired,
						goalTypeId: numberRequired,
						prizePercentage: numberPositiveRequired,
						prizeValue: numberPositiveRequired
					})
				)
				.min(1, 'Must have at least one prize goal when MAXIMUM PRIZE BONUS is set.')
				.test('not-100-percent', 'The sum of all prize goals must be 100%', (data): boolean => {
					if (data) {
						const totalPercentages = data.reduce(
							(total, current) => (current?.prizePercentage ? current.prizePercentage + total : total),
							0
						);
						// return true -> the error will NOT show
						// return false -> the error will show
						return totalPercentages === 100;
					}
					return true;
				})
				.test('not-empty-percentages', "Can't exist goals with no percentage value", data =>
					// return true -> the error will NOT show
					// return false -> the error will show
					data ? !data.map(e => e && Number(e.prizePercentage)).includes(0) : true
				),
			otherwise: yup.array()
		}),
		annualPrizeIRS: formFields?.irsTaxInPrizeMonth ? stringRequired : string,
		totalComponentsPrizePercentage: string.when('employeeCategory', {
			is:
				XPAND_EMPLOYEE_ID ||
				XPAND_EMPLOYEE_ID_HR ||
				XPAND_EMPLOYEE_ID_UK ||
				XPAND_EMPLOYEE_ID_SE ||
				XPAND_EMPLOYEE_ID_DE ||
				BALWURK_EMPLOYEE_ID,
			then: stringRequired, //EMPLOYEE
			otherwise: string //CONTRACTOR
		}),
		totalComponentsPrizeValue: string.when('employeeCategory', {
			is:
				XPAND_EMPLOYEE_ID ||
				XPAND_EMPLOYEE_ID_HR ||
				XPAND_EMPLOYEE_ID_UK ||
				XPAND_EMPLOYEE_ID_SE ||
				XPAND_EMPLOYEE_ID_DE ||
				BALWURK_EMPLOYEE_ID,
			then: stringRequired, //EMPLOYEE
			otherwise: string //CONTRACTOR
		})
	};
};
const careerFinancialHR = formFields => {
	return {
		// ------------------------------------------- CAREER & FINANCIAL
		// CAREER & FINANCIAL - CAREER INFO
		softwareDevelopmentCenter: boolean,
		careerPosition: formFields?.careerPosition ? stringRequired : string,
		careerJobRole: formFields?.careerJobRole ? stringRequired : string,
		careerManager: formFields?.careerManager ? stringRequired : string,
		// CAREER & FINANCIAL - CAREER INFO
		pensionFundNumber: formFields?.pensionFund
			? stringRequired
					.test('Digits only', 'The field should have digits only', digitsOnly)
					.max(11, 'This field exceeds the maximum limit (11)')
					.min(11, 'This field must have (11) digits')
					.test(
						'duplicatedPensionFundNumber',
						'This pension fund number is already in use.',
						function (payload) {
							return !this.parent.allPensionFundNumber?.includes(payload);
						}
					)
			: string,
		holdersNumber: formFields?.holders
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		disabled: boolean,
		maritalStatus: formFields?.maritalStatus
			? stringRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		tsuExemption: boolean,
		dependentsNumber: formFields?.numberDependents
			? numberRequired.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberRequired;
					} else {
						return number;
					}
			  })
			: number,
		surtaxZone: formFields?.surtaxZone ? stringRequired : string,
		// CAREER & FINANCIAL - GENERAL AMOUNTS
		payBonusSalary: boolean,
		bonusSalary: formFields?.bonusSalary
			? number.when('payBonusSalary', {
					is: (val: boolean) => val,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		baseValue: formFields?.baseValue
			? number.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberPositiveRequired;
					} else {
						return number;
					}
			  })
			: number,
		irsToApply: formFields?.irsTax ? numberRequired : number, // readOnly
		ihtValue: formFields?.workScheduleExemptionValue ? numberRequired : string, // readOnly
		ihtPercentage: number,
		annualGrossValue: number
	};
};

const otherBenefits = formFields => {
	return {
		commsPlafondFlag: boolean,
		commsPlafond: formFields?.communicationsPlafondValue
			? number.when('commsPlafondFlag', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		healthInsuranceCheck: boolean,
		healthInsuranceValue: formFields?.healthInsuranceValue
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		includeFamilyMembers: boolean,
		numberMembersDep: formFields?.includeFamilyMembers
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		familyMembersComments: string,
		otherbenefits: boolean,
		otherBenefitsList: yup.array().of(
			yup.object().shape({
				id: string,
				goalDetails: string.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return stringRequired;
					} else {
						return string;
					}
				}),
				prizeValue: number.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberPositiveRequired;
					} else {
						return number;
					}
				})
			})
		),
		benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag', 'employeeCategory'], {
			is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean, employeeCategory: string) =>
				employeeCategoryCompare.includes(employeeCategory) && (!healthInsuranceCheck || !commsPlafondFlag),
			then: stringRequired,
			otherwise: string
		})
	};
};
const otherBenefitsHR = formFields => {
	return {
		commsPlafondFlag: boolean,
		commsPlafond: formFields?.communicationsPlafondValue
			? number.when('commsPlafondFlag', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		healthInsuranceCheck: boolean,
		healthInsuranceValue: formFields?.healthInsuranceValue
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		includeFamilyMembers: boolean,
		numberMembersDep: formFields?.includeFamilyMembers
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		familyMembersComments: string,
		otherbenefits: boolean,
		otherBenefitsList: yup.array().of(
			yup.object().shape({
				id: string,
				goalDetails: string.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return stringRequired;
					} else {
						return string;
					}
				}),
				prizeValue: number.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return numberPositiveRequired;
					} else {
						return number;
					}
				})
			})
		),
		benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag', 'employeeCategory'], {
			is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean, employeeCategory: string) =>
				employeeCategoryCompare.includes(employeeCategory) && (!healthInsuranceCheck || !commsPlafondFlag),
			then: stringRequired,
			otherwise: string
		})
	};
};

const contractCompany = formFields => {
	return {
		contractType: formFields?.contractType
			? string.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return stringRequired;
					} else {
						return string;
					}
			  })
			: string,
		contractPosition: formFields?.contractPosition
			? string.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return stringRequired;
					} else {
						return string;
					}
			  })
			: string,
		customerInContract: formFields?.customerInContract
			? string.when('contractType', {
					is: (value: string) =>
						// 20 - fixed term
						value === '20',
					then: stringRequired,
					otherwise: string
			  })
			: string,
		traineeType: string.when('contractType', {
			is: (value: string) =>
				// 80 - trainee
				value === '80',
			then: stringRequired,
			otherwise: string
		}),
		fixedTermContractDuration: string.when('contractType', {
			is: (value: string) =>
				// 20 - fixed term
				// 80 - trainee
				value === '20' || value === '80',
			then: stringRequired,
			otherwise: string
		}),
		endDate: dateDefault,
		primaryOffice: formFields?.primaryOffice ? stringRequired : string,
		workingOffice: formFields?.contractType ? stringRequired : string,
		employeeManager: formFields?.employeeManager ? stringRequired : string,
		holidayType: formFields?.holidayType ? stringRequired : string,
		contactListLevel: string,
		referralType: string,
		reference: string.when('referralType', {
			is: (value: string) => value === 'Employee',
			then: stringRequired,
			otherwise: string
		}),
		referralId: string
	};
};
const contractCompanyHR = formFields => {
	return {
		contractType: formFields?.contractType
			? string.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return stringRequired;
					} else {
						return string;
					}
			  })
			: string,
		contractPosition: formFields?.contractPosition
			? string.when('employeeCategory', function (this, employeeCategory, schema) {
					if (employeeCategoryCompare.includes(employeeCategory) || employeeCategory === undefined) {
						return stringRequired;
					} else {
						return string;
					}
			  })
			: string,
		traineeType: string.when('contractType', {
			is: (value: string) =>
				// 80 - trainee
				value === '80',
			then: formFields?.traineeType ? stringRequired : string,
			otherwise: string
		}),
		fixedTermContractDuration: string.when('contractType', {
			is: (value: string) =>
				// 20 - fixed term
				// 80 - trainee
				value === '20' || value === '80',
			then: stringRequired,
			otherwise: string
		}),
		endDate: dateDefault,
		primaryOffice: formFields?.primaryOffice ? stringRequired : string,
		workingOffice: formFields?.contractType ? stringRequired : string,
		employeeManager: formFields?.employeeManager ? stringRequired : string,
		holidayType: formFields?.holidayType ? stringRequired : string,
		contactListLevel: string
	};
};
const projects = {
	projects: yup.array().of(
		yup.object().shape({
			projectId: stringRequired,
			projectName: stringRequired
		})
	)
};

export const schema = formFields => {
	if (formFields?.country == 'HR') {
		return yup.object().shape({
			// PROPOSAL DB INFO
			proposalId: string,
			token: string,
			// GENERAL INFORMATION
			...generalHR(formFields?.formFieldsConfig),
			// CAREER & FINANCIAL
			...careerFinancialHR(formFields?.formFieldsConfig),
			// OTHER BENEFITS
			...otherBenefitsHR(formFields?.formFieldsConfig),
			// CONTRACT & COMPANY
			...contractCompanyHR(formFields?.formFieldsConfig),
			// PROJECTS
			...projects
		});
	}

	return yup.object().shape({
		// PROPOSAL DB INFO
		proposalId: string,
		token: string,
		// GENERAL INFORMATION
		...general(formFields?.formFieldsConfig),
		// CAREER & FINANCIAL
		...careerFinancial(formFields?.formFieldsConfig),
		// OTHER BENEFITS
		...otherBenefits(formFields?.formFieldsConfig),
		// CONTRACT & COMPANY
		...contractCompany(formFields?.formFieldsConfig),
		// PROJECTS
		...projects
	});
};

export const createDefaultValues = (employeeCode: string, referenceData: any) => {
	if (employeeCode == 'HR') {
		return {
			// PROPOSAL DB INFO
			proposalId: '',
			token: '',
			// ------------------------------------------- GENERAL INFORMATION
			collaborationType: COLLABTYPE,
			name: '',
			companySocSec: 0,
			username: '',
			email: '',
			jiraCandidateApplication: '',
			startDate: null,
			//
			company: state.defaultCompanyKey,
			businessUnit: '',
			division: '',
			department: '',
			employeeCategory: INSTANCE.toLowerCase().includes('xpand') ? XPAND_EMPLOYEE_ID : BALWURK_EMPLOYEE_ID,
			collaborationRole: 'EMPLOYEE',
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - USER DATA
			// importFromAnotherProposal: false, // form helper
			importFromAnotherProposal: true, // form helper
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: false,
			careerPosition: '',
			careerJobRole: '',
			careerManager: '',
			// CAREER & FINANCIAL - TAXES
			pensionFundNumber: '',
			holdersNumber: '1',
			annualGrossValue: 0,
			companyName: '',
			fullName: '',
			internshipDescription: '',
			irsTwelfth: '',
			disabled: false,
			maritalStatus: 'Single',
			tsuExemption: false,
			estimuloMedida: false,
			dependentsNumber: '0',
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			payBonusSalary: true,
			bonusSalary: '0',
			baseValue: 0,
			irsToApply: '0',
			ihtValue: '0',
			ihtPercentage: '0',
			hasTwelfths: false,
			vacationsTwelfth: '0',
			christmasTwelfth: '0',
			grossBaseValue: '0',
			totalTaxableValue: '0',
			nationalAllowanceDays: '0',
			nationalAllowance: '0',
			internationalAllowanceDays: '0',
			internationalAllowance: '0',
			travelPass: '0',
			additionalExpenses: '0',
			nurseryCheck: '0',
			lifeInsurance: '0',
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: false,
			prizeBonus: '0',
			prizeBonusPercentage: 0,
			monthsApplicable: '12',
			eligiblePrizeValue: 0,
			prizeBonusGoals: [],
			annualPrizeIRS: '0',
			totalComponentsPrizePercentage: '0',
			totalComponentsPrizeValue: '0',
			careerAndFinancialNotes: '',
			// OTHER BENEFITS
			commsPlafondFlag: true,
			commsPlafond: referenceData?.xphrMonStdComPlafond,
			healthInsuranceCheck: true,
			healthInsuranceValue: referenceData?.xphrMonStdHeaInsCost,
			includeFamilyMembers: false,
			numberMembersDep: '0',
			familyMembersComments: '',
			otherbenefits: true,
			otherBenefitsList: [{ goalDetails: 'Transportation Allowance', prizeValue: 33 }],
			benefitsComments: '',
			// CONTRACT & COMPANY
			contractType: '',
			customerInContract: '',
			traineeType: '',
			fixedTermContractDuration: '',
			endDate: '',
			contractPosition: '',
			primaryOffice: '',
			workingOffice: '',
			employeeManager: '',
			holidayType: '',
			contactListLevel: '',
			// PROJECTS
			admissionProcessSheetId: undefined as undefined | number,
			projects: [] as any,
			usersList: [] as any,
			emailsList: [] as any
		};
	}
	return {
		// PROPOSAL DB INFO
		proposalId: '',
		token: '',
		// ------------------------------------------- GENERAL INFORMATION
		collaborationType: COLLABTYPE,
		name: '',
		companySocSec: '23.75',
		username: '',
		email: '',
		jiraCandidateApplication: '',
		startDate: null,
		//
		company: state.defaultCompanyKey,
		businessUnit: '',
		division: '',
		department: '',
		employeeCategory: INSTANCE.toLowerCase().includes('xpand') ? XPAND_EMPLOYEE_ID : BALWURK_EMPLOYEE_ID,
		collaborationRole: 'EMPLOYEE',
		// ------------------------------------------- CAREER & FINANCIAL
		// CAREER & FINANCIAL - USER DATA
		// importFromAnotherProposal: false, // form helper
		importFromAnotherProposal: true, // form helper
		// CAREER & FINANCIAL - CAREER INFO
		softwareDevelopmentCenter: false,
		careerPosition: '',
		careerJobRole: '',
		careerJobRoleLevel: '',
		careerManager: '',
		// CAREER & FINANCIAL - TAXES
		socialSecurityNumber: '',
		holdersNumber: '1',
		annualGrossValue: 0,
		companyName: '',
		fullName: '',
		internshipDescription: '',
		irsTwelfth: '',
		disabled: false,
		maritalStatus: 'Single',
		tsuExemption: false,
		estimuloMedida: false,
		dependentsNumber: '0',
		taxZone: undefined,
		// CAREER & FINANCIAL - GENERAL AMOUNTS
		baseValue: 0,
		hourlyRate: 0,
		budget: 0,
		irsToApply: '0',
		ihtValue: '0',
		ihtPercentage: '25.00',
		hasTwelfths: true,
		vacationsTwelfth: '0',
		christmasTwelfth: '0',
		grossBaseValue: '0',
		totalTaxableValue: '0',
		foodAllowanceType: referenceData?.xphrFoodAllowType,
		foodAllowance: referenceData?.xphrCrFoodAllowValue,
		foodAllowanceDays: referenceData?.xphrFoodAllowanceDays,
		nationalAllowanceDays: '0',
		nationalAllowance: '0',
		internationalAllowanceDays: '0',
		internationalAllowance: '0',
		travelPass: '0',
		additionalExpenses: '0',
		nurseryCheck: '0',
		lifeInsurance: '0',
		// CAREER & FINANCIAL - PRIZE BONUS
		createPrizeBonusErp: false,
		prizeBonus: '0',
		prizeBonusPercentage: 0,
		monthsApplicable: '12',
		eligiblePrizeValue: 0,
		prizeBonusGoals: [],
		annualPrizeIRS: '0',
		totalComponentsPrizePercentage: '0',
		totalComponentsPrizeValue: '0',
		careerAndFinancialNotes: '',
		// OTHER BENEFITS
		commsPlafondFlag: true,
		commsPlafond: '25',
		healthInsuranceCheck: true,
		healthInsuranceValue: referenceData?.xphrMonStdHeaInsCost,
		includeFamilyMembers: false,
		numberMembersDep: '0',
		familyMembersComments: '',
		otherbenefits: true,
		otherBenefitsList: [{ goalDetails: 'Remote Work Allowance', prizeValue: 15 }],
		benefitsComments: '',
		// CONTRACT & COMPANY
		contractType: '',
		customerInContract: '',
		traineeType: '',
		fixedTermContractDuration: '',
		endDate: '',
		contractPosition: '',
		primaryOffice: '',
		workingOffice: '',
		employeeManager: '',
		holidayType: '',
		contactListLevel: '',
		referralType: '',
		reference: '',
		referralId: '',
		// PROJECTS
		admissionProcessSheetId: undefined as undefined | number,
		projects: [] as any,
		usersList: [] as any,
		emailsList: [] as any
	};
};
