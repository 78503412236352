/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingOverlay, Dialog } from 'xpand-ui/core';
import { format } from 'date-fns';
import { DatePicker, Select as LibSelect, Input as LibInput, Checkbox } from 'xpand-ui/forms';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import { useForm } from 'react-hook-form';
import { createYupSchema, createDefaultValues } from './yupSchema';

//* COMPONENT INTERFACES

const NewExpenseLine: FC<any> = ({
	personalInfo,
	accountExpenseSheetId,
	expenseSheetData,
	accountEmployeeId,
	isEdit,
	setIsEdit,
	editData,
	accountEmployeeCode,
	dateOptions,
	description,
	setOpenModalNewExpenseLine,
	openModalNewExpenseLine,
	shouldOpenModal,
	setShouldOpenModal,
	getNewExpenseLineData,
	getExpenseSheetData,
	submitNewExpenseLine,
	setAddNewExpenseLine,
	submitUpdateExpenseSheet
}) => {
	const classes = useStyles();
	const { newExpenseLineData, newExpenseLine } = personalInfo;
	const [selectedExpense, setSelectedExpense] = useState();
	const [entryIdToUpdate, setEntryIdToUpdate] = useState(0);
	const [isCopyAndAdd, setIsCopyAndAdd] = useState(false);
	const [currentYupSchema, setCurrentYupSchema] = useState(() =>
		createYupSchema(
			false,
			newExpenseLineData
				? newExpenseLineData?.results?.accountEmployeeExpenseSheet?.expenseSheetDate
				: new Date().toISOString(),
			false,
			false
		)
	);
	const [currentDefaultValues, setCurrentDefaultValues] = useState(() =>
		createDefaultValues(
			false,
			newExpenseLineData
				? newExpenseLineData?.results?.accountEmployeeExpenseSheet?.expenseSheetDate
				: new Date().toISOString(),
			false,
			false
		)
	);
	const [changedInSelect, setChangedInSelect] = useState(false);

	useEffect(() => {
		if (isEdit) {
			setValue('projectName', editData?.accountProjectId);
			setValue('expenseName', editData?.accountExpenseId);
			setValue('description', editData?.description);
			setValue('expenseEntryDate', editData?.accountExpenseEntryDate);
			setValue('reimburse', editData?.reimburse2);
			setValue('billable', editData?.billable2);
			setValue('paymentMethod', editData?.accountPaymentMethodId ? editData?.accountPaymentMethodId : 9999);
			setValue('currency', editData?.accountCurrencyId);
			setValue('tripQuantity', editData?.quantity);
			setValue('rate', editData?.rate);
			setValue('amount', editData?.netAmount);
			setValue('destinationCountry', editData?.customField1);
			setEntryIdToUpdate(editData?.accountExpenseEntryId);
			setCurrentYupSchema(e => createYupSchema(false, editData.expenseSheetDate, false, false));
		}
	}, [isEdit, editData]);
	const loadnewExpenseLineData = () => {
		getNewExpenseLineData({
			accountEmployeeId: accountEmployeeId,
			accountEmployeeExpenseSheetId: accountExpenseSheetId
		});
	};
	const {
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
		trigger,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(currentYupSchema),
		reValidateMode: 'onChange',
		defaultValues: currentDefaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (newExpenseLine && shouldOpenModal) {
			reset({
				projectName: newExpenseLine.results?.insertedEntry.accountProjectId,
				expenseName: newExpenseLine.results?.insertedEntry.accountExpenseId,
				description: newExpenseLine.results?.insertedEntry.description,
				expenseEntryDate: newExpenseLine.results?.insertedEntry.accountExpenseEntryDate,
				paymentMethod: newExpenseLine.results?.insertedEntry.accountPaymentMethodId
					? newExpenseLine.results?.insertedEntry.accountPaymentMethodId
					: 9999,
				currency: newExpenseLine.results?.insertedEntry.accountCurrencyId,
				tripQuantity: newExpenseLine.results?.insertedEntry.quantity,
				rate: newExpenseLine.results?.insertedEntry.rate,
				amount: newExpenseLine.results?.insertedEntry.amount,
				destinationCountry: newExpenseLine.results?.insertedEntry.customField1,
				reimburse: newExpenseLine.results?.insertedEntry.reimburse,
				billable: newExpenseLine.results?.insertedEntry.isBillable
			});
		}
	}, [newExpenseLine, shouldOpenModal, reset]);

	//Force check boxes checked if expense name required it so
	useEffect(() => {
		const expense = newExpenseLineData?.results?.accountExpenses?.find(
			expense => expense?.accountExpenseId === watch('expenseName')
		);
		//Used when whenever expense name is changed in the select input
		if (expense && changedInSelect) {
			setValue('billable', expense.isBillable);
			setValue('reimburse', expense.reimburse ? expense?.reimburse : false);
			setChangedInSelect(false);
		}
		//Used to load the current billable, reimburse when dialog open and its not edit
		if (expense && !changedInSelect && !isEdit && !shouldOpenModal) {
			setValue('billable', expense.isBillable);
			setValue('reimburse', expense.reimburse ? expense?.reimburse : false);
		}
	}, [watch('expenseName')]);

	useEffect(() => {
		if (selectedExpense && !newExpenseLine && !isEdit) {
			setCurrentYupSchema(
				createYupSchema(
					selectedExpense.disabledEditingOfRate,
					newExpenseLineData?.results?.accountEmployeeExpenseSheet?.expenseSheetDate,
					selectedExpense?.reimburse,
					selectedExpense?.isBillable
				)
			);

			setCurrentDefaultValues(
				createDefaultValues(
					selectedExpense.disabledEditingOfRate,
					newExpenseLineData?.results?.accountEmployeeExpenseSheet?.expenseSheetDate,
					selectedExpense?.reimburse,
					selectedExpense?.isBillable
				)
			);
			if (
				selectedExpense.defaultExpenseRate <= 0 ||
				selectedExpense.defaultExpenseRate == undefined ||
				!isEdit ||
				!isCopyAndAdd
			) {
				setValue('tripQuantity', 0);
			}
			if (selectedExpense?.reimburse) {
				setValue('reimburse', selectedExpense.reimburse ? selectedExpense?.reimburse : false);
			} else {
				setValue('reimburse', false);
			}
			if (selectedExpense?.isBillable) {
				setValue('billable', true);
			} else {
				setValue('billable', false);
			}
		}
	}, [selectedExpense]);

	const getCurrentMonthLastDay = () => {
		const today = new Date();
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		return `${('0' + lastDay.getDate()).slice(-2)}/${('0' + (lastDay.getMonth() + 1)).slice(
			-2
		)}/${lastDay.getFullYear()}`;
	};

	const getMonthYear = dateString => {
		const [day, month, year] = dateString.split('/');
		return { month, year };
	};

	const onSubmit = (data: unknown) => {
		let payload = data;
		payload.accountExpenseSheetId = accountExpenseSheetId;
		payload.isEdit = isEdit;
		payload.accountEmployeeId = newExpenseLineData?.results?.userData.id;
		payload.accountExpenseEntryId = entryIdToUpdate;
		const filteredCurrencies = newExpenseLineData?.results?.vueAccountCurrencies?.filter(
			currency => currency.accountCurrencyId === watch('currency')
		);
		payload.exchangeRate = filteredCurrencies[0].exchangeRate;
		payload.accountEmployeeName =
			newExpenseLineData?.results?.userData.employeeCode +
			' - ' +
			newExpenseLineData?.results?.userData.firstName +
			' ' +
			newExpenseLineData?.results?.userData.lastName;
		payload.expenseEntryDate = format(new Date(payload.expenseEntryDate), 'yyyy-MM-dd HH:mm:ss');

		// Find the corresponding date option
		const currentDateOption = dateOptions.find(option => option.id === getCurrentMonthLastDay());

		// Extract month and year from currentDateOption.id to determine if sheet update should be done.
		const currentDate = getMonthYear(currentDateOption.id);

		const formatToDDMMYYYY = dateString => {
			const [year, month, day] = dateString.split('-');
			return `${day}/${month}/${year}`;
		};

		// Extract month and year from expenseSheetData.results.accountEmployeeExpenseSheet.modifiedOn to determine if sheet update should be done.
		const modifiedDate = getMonthYear(
			formatToDDMMYYYY(expenseSheetData.results.accountEmployeeExpenseSheet.expenseSheetDate)
		);

		// Function to calculate the difference in months between two dates
		const getMonthDifference = (date1, date2) => {
			const totalMonths1 = date1.year * 12 + date1.month;
			const totalMonths2 = date2.year * 12 + date2.month;
			return Math.abs(totalMonths1 - totalMonths2);
		};

		// Check if the difference is at least 3 months before submitting
		const monthDifference = getMonthDifference(currentDate, modifiedDate);

		// Update the sheet if the current month/year is at least 2 months more recent than the current sheet one.
		if (monthDifference > 1) {
			submitUpdateExpenseSheet({
				accountEmployeeExpenseSheetId: accountExpenseSheetId,
				accountEmployeeId: accountEmployeeCode,
				expenseSheetDate: currentDateOption.id,
				description: description
			});
		}

		submitNewExpenseLine(data);
		if (!isCopyAndAdd) {
			setOpenModalNewExpenseLine(false);
		}
		if (!shouldOpenModal) {
			reset();
		}
		getExpenseSheetData({ accountEmployeeExpenseSheetId: accountExpenseSheetId });
	};

	// Necessary to correctly filter the options for project name.
	const allOptions = newExpenseLineData?.results?.accountExpenses || [];

	const enabledOptions = allOptions.filter(e => !e.isDisabled);

	const mileageOptions = allOptions.filter(
		e =>
			(e.accountExpenseName.includes('Tier') && e.accountExpenseName.includes('(XP)')) ||
			e.accountExpenseName.includes('EXP.91') ||
			e.accountExpenseName.includes('EXP.92')
	);

	const finalOptions = [
		...enabledOptions,
		...mileageOptions.filter(
			mileageOption =>
				!enabledOptions.some(enabledOption => enabledOption.accountExpenseId === mileageOption.accountExpenseId)
		)
	];

	const selectOptions = finalOptions.map(e => ({
		id: e.accountExpenseId,
		label: e.accountExpenseName
	}));

	const confirmActions = !isEdit
		? [
				{
					id: 'cancel',
					label: 'Cancel',
					color: 'secondary',
					variant: 'text',
					onClick: () => {
						setAddNewExpenseLine(null);
						setOpenModalNewExpenseLine(false);
						setShouldOpenModal(false);
						reset();
					}
				},
				{
					id: 'add',
					label: 'Add',
					color: 'primary',
					variant: 'contained',
					onClick: () => {
						setAddNewExpenseLine(null);
						setShouldOpenModal(false);
					},
					type: 'submit',
					form: 'new-expense-line-modal'
				},
				{
					id: 'addAndCopy',
					label: 'Add & Copy',
					color: 'primary',
					variant: 'contained',
					onClick: e => {
						trigger()
							.then(result => {
								if (result) {
									setShouldOpenModal(true);
									if (Object.keys(errors).length === 0) {
										onSubmit(watch());
										setIsCopyAndAdd(true);
									}
								}
							})
							.catch(error => {
								console.error('Error in trigger:', error);
							});
					},
					type: 'button'
				}
		  ]
		: [
				{
					id: 'cancel',
					label: 'Cancel',
					color: 'secondary',
					variant: 'text',
					onClick: () => {
						setOpenModalNewExpenseLine(false);
						setShouldOpenModal(false);
						setIsEdit(false);
						reset();
					}
				},
				{
					id: 'update',
					label: 'Update',
					color: 'primary',
					variant: 'contained',
					onClick: () => {
						setShouldOpenModal(false);
						// setIsEdit(false);
					},
					type: 'submit',
					form: 'new-expense-line-modal'
				}
		  ];

	useEffect(() => {
		if (newExpenseLineData?.results?.accountExpenses) {
			const expense = newExpenseLineData.results?.accountExpenses.find(
				expense => expense.accountExpenseId === watch('expenseName')
			);
			if (expense) {
				setSelectedExpense(expense);
				setValue('rate', expense.defaultExpenseRate);
			}
		}
	}, [newExpenseLineData, watch('expenseName')]);

	useEffect(() => {
		if (openModalNewExpenseLine) {
			loadnewExpenseLineData();
		}
	}, [openModalNewExpenseLine]);

	const isLoading = newExpenseLineData === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{/* NEW EXPENSE LINE MODAL */}
			<Dialog
				modal={{
					open: Boolean(openModalNewExpenseLine),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							openModalNewExpenseLine(false);
						}
					},
					content: (
						<form id="new-expense-line-modal" onSubmit={handleSubmit(onSubmit)}>
							<Grid container direction="column" justifyContent="center" alignItems="space-between">
								<Grid container justifyContent="center" className={classes.gridRowFilters}>
									<Typography variant="h2">Expense Entry Information</Typography>
								</Grid>
								{/* 1st Row */}
								<Grid item xs={12} className={classes.newExpenseGridRow}>
									<Grid item xs={2}></Grid>
									<Grid xs={4} className={classes.newExpenseGrid}>
										<LibSelect
											required
											name="projectName"
											label="Project Name"
											options={
												newExpenseLineData?.results?.accountProjects.map((e: any) => ({
													id: e.id,
													label: e.projectName
												})) || []
											}
											control={control}
											errors={errors}
											hasSearchOption={true}
										/>
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid xs={4}>
										<LibSelect
											required
											name="expenseName"
											label="Expense Name"
											additionalOnChange={e => {
												setValue('amount', null);
												setValue('tripQuantity', 0);
												setChangedInSelect(true);
											}}
											options={selectOptions || []}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
								{/* 2nd Row */}
								<Grid item xs={12} className={classes.newExpenseGridRow}>
									<Grid item xs={2}></Grid>
									<Grid xs={4}>
										<LibInput
											name="description"
											label="Description"
											multiline
											minRows={2}
											maxRows={4}
											maxLength={200}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid xs={4}>
										<DatePicker
											required
											name="expenseEntryDate"
											label="Expense Entry Date"
											format="DD/MM/YYYY"
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
								{/* 3rd Row */}
								<Grid item xs={12} className={classes.newExpenseGridRow}>
									<Grid item xs={2}></Grid>
									<Grid xs={4}>
										<Checkbox
											color="primary"
											name="reimburse"
											label="Reimburse"
											disabled={selectedExpense?.defaultExpenseRate > 0}
											helperText="The expense will be reimbursed to the employee. The employee must attach the document of the expense"
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid xs={4}>
										<LibSelect
											required
											name="paymentMethod"
											label="Payment Method"
											options={[
												{ id: 9999, label: 'None' },
												...(newExpenseLineData?.results?.accountPaymentMethods.map(
													(e: any) => ({
														id: e.accountPaymentMethodId,
														label: e.paymentMethod
													})
												) || [])
											]}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
								{/* 4th Row */}
								<Grid item xs={12} className={classes.newExpenseGridRow}>
									<Grid item xs={2}></Grid>
									<Grid xs={4}>
										<Checkbox
											color="primary"
											name="billable"
											label="Billable"
											disabled={selectedExpense?.defaultExpenseRate > 0}
											helperText="The client is expected to pay the expense"
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid xs={4}>
										<LibSelect
											required
											name="currency"
											label="Currency"
											options={
												newExpenseLineData?.results?.vueAccountCurrencies.map((e: any) => ({
													id: e.accountCurrencyId,
													label: e.currencyCode
												})) || []
											}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
								{/* 5th Row (conditional) */}
								{selectedExpense?.defaultExpenseRate > 0 && (
									<Grid item xs={12} className={classes.newExpenseGridRow}>
										<Grid item xs={2}></Grid>
										<Grid xs={4}>
											<LibInput
												// required
												name="tripQuantity"
												label={
													selectedExpense?.accountExpenseName.startsWith('EXP.40.')
														? 'QUANTITY (DAYS)'
														: 'QUANTITY (NR. OF TRIPS)'
												}
												additionalOnChange={e => {
													setValue('amount', e * selectedExpense.defaultExpenseRate);
													trigger('amount');
												}}
												control={control}
												errors={errors}
											/>
										</Grid>
										<Grid item xs={1}></Grid>
										<Grid xs={4}>
											<LibInput
												required={selectedExpense?.disabledEditingOfRate}
												name="rate"
												label="Rate"
												disabled
												control={control}
												errors={errors}
											/>
										</Grid>
										<Grid item xs={2}></Grid>
									</Grid>
								)}
								{/* 6th Row */}
								<Grid item xs={12} className={classes.newExpenseGridRow}>
									<Grid item xs={2}></Grid>
									<Grid xs={4}>
										<LibInput
											required
											disabled={selectedExpense?.defaultExpenseRate > 0}
											name="amount"
											label="Amount"
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid xs={4}>
										<LibSelect
											required
											name="destinationCountry"
											label="Destination Country"
											options={
												newExpenseLineData?.results?.accountCustomFields.dropDownOptions
													.split('+')
													.map(option => ({ id: option.trim(), label: option.trim() })) || []
											}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
							</Grid>
						</form>
					)
				}}
				title={isEdit ? ' Edit Expense' : 'New Expense'}
				actions={confirmActions}
				scroll="body"
				fullWidth
				maxWidth="lg"
			/>
		</>
	);
};
export default NewExpenseLine;
