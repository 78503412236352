/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Checkbox } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { addNotification } from 'lib/utils/notifications';

// import { IProjectTabs } from '../../NewProject';
import { DeepMap, FieldError } from 'react-hook-form';
import { useStyles } from './styles';
import { IAdmissionSheetTabs } from '../../AdmissionSheet';
import { useSelector } from 'react-redux';

//* COMPONENT INTERFACES
// interface IProjectTeam extends IProjectTabs {}

const markRequiredValues = (data: any, formFieldsConfig: any, isEmployee: boolean): any => {
	const checkS = (val: string) =>
		Boolean(data[val] !== null && typeof data[val] !== 'undefined' && data[val].trim() !== '');

	const checkN = (val: string) =>
		data[val] !== null && typeof data[val] !== 'undefined' && (Number(data[val]) === 0 ? 0 : Number(data[val]));

	const requiredValues = {
		// General Info Tab
		fullName: checkS('fullName'),
		placeOfBirth: checkS('placeOfBirth'),
		gender: checkS('gender'),
		dateOfBirth: checkS('dateOfBirth'),
		...((data.validatePlate && {
			licensePlate: checkS('licensePlate')
		}) ||
			{}),
		// Contact Info Tab
		address: checkS('address'),
		mobilePhone: checkS('mobilePhone'),
		city: checkS('city'),
		emergencyContact: checkS('emergencyContact'),
		zipCode: checkS('zipCode'),
		emergencyContactName: checkS('emergencyContactName'),
		email: checkS('email'),
		nationality: checkS('nationality'),
		// ID Info Tab
		docType: checkS('docType'),
		fiscalNumber: checkS('fiscalNumber'),
		citizenCardNumber: checkS('citizenCardNumber'),
		...((data.docType === 'CC' && {
			socialSecurityNumber: checkS('socialSecurityNumber')
		}) ||
			{}),
		hasIDDoc: checkS('hasIDDoc'),
		pensionFundNumber: checkS('pensionFundNumber'),
		// Family Aggregate Info Tab
		maritalStatus: checkS('maritalStatus'),
		holdersNumber: checkS('holdersNumber'),
		dependentsNumber: checkS('dependentsNumber'),
		// Qualification Info Tab
		courseQualificationId: checkS('courseQualificationId'),
		courseInstitution: checkS('courseInstitution'),
		educationCode: checkS('educationCode'),
		courseStartDate: checkS('courseStartDate'),
		educationStatus: checkS('educationStatus'),
		...((data.educationStatus !== 'Ongoing' && {
			courseEndDate: checkS('courseEndDate')
		}) ||
			{}),
		// courseEndDate: checkS('courseEndDate'),
		courseName: checkS('courseName'),
		careerStartDate: checkS('careerStartDate'),
		// coursePostBologna: checkS('coursePostBologna'),
		professionalExperience: checkS('professionalExperience'),
		// Banking Info Tab
		bankId: checkS('bankId'),
		bankNib: checkS('bankNib'),
		hasBankDoc: checkS('hasBankDoc')
	};

	if (!formFieldsConfig.fullName) delete requiredValues.fullName;
	if (!formFieldsConfig.placeOfBirth) delete requiredValues.placeOfBirth;
	if (!formFieldsConfig.gender) delete requiredValues.gender;
	if (!formFieldsConfig.dateOfBirth) delete requiredValues.dateOfBirth;
	if (!formFieldsConfig.licensePlate) delete requiredValues.licensePlate;
	if (!formFieldsConfig.address || !isEmployee) delete requiredValues.address;
	if (!formFieldsConfig.mobilePhone) delete requiredValues.mobilePhone;
	if (!formFieldsConfig.city || !isEmployee) delete requiredValues.city;
	if (!formFieldsConfig.emergencyContact || !isEmployee) delete requiredValues.emergencyContact;
	if (!formFieldsConfig.zipCode || !isEmployee) delete requiredValues.zipCode;
	if (!formFieldsConfig.emergencyContactName || !isEmployee) delete requiredValues.emergencyContactName;
	if (!formFieldsConfig.email) delete requiredValues.email;
	if (!formFieldsConfig.nationality) delete requiredValues.nationality;
	if (!formFieldsConfig.idDocumentType) delete requiredValues.docType;
	if (!formFieldsConfig.fiscalNumber) delete requiredValues.fiscalNumber;
	if (!formFieldsConfig.citizenCardNumber) delete requiredValues.citizenCardNumber;
	if (!formFieldsConfig.socialSecurityNumber || !isEmployee) delete requiredValues.socialSecurityNumber;
	if (!formFieldsConfig.pensionFund) delete requiredValues.pensionFundNumber;
	if (!formFieldsConfig.maritalStatus) delete requiredValues.maritalStatus;
	if (!formFieldsConfig.holdersNumber) delete requiredValues.holdersNumber;
	if (!formFieldsConfig.numberDependents) delete requiredValues.dependentsNumber;
	if (!formFieldsConfig.courseQualificationId || !isEmployee) delete requiredValues.courseQualificationId;
	if (!formFieldsConfig.courseInstitution || !isEmployee) delete requiredValues.courseInstitution;
	if (!formFieldsConfig.educationCode || !isEmployee) delete requiredValues.educationCode;
	if (!formFieldsConfig.courseStartDate || !isEmployee) delete requiredValues.courseStartDate;
	if (!formFieldsConfig.educationStatus || !isEmployee) delete requiredValues.educationStatus;
	if (!formFieldsConfig.courseEndDate || !isEmployee) delete requiredValues.courseEndDate;
	if (!formFieldsConfig.courseName || !isEmployee) delete requiredValues.courseName;
	if (!formFieldsConfig.careerStartDate || !isEmployee) delete requiredValues.careerStartDate;
	if (!formFieldsConfig.professionalExperience || !isEmployee) delete requiredValues.professionalExperience;
	if (!formFieldsConfig.bankId) delete requiredValues.bankId;
	if (!formFieldsConfig.bankNumber || !isEmployee) delete requiredValues.bankNib;
	if (!formFieldsConfig.hasBankDoc || !isEmployee) delete requiredValues.hasBankDoc;
	if (!formFieldsConfig.coursePostBologna || !isEmployee) delete requiredValues.coursePostBologna;
	if (!formFieldsConfig.taxZone) delete requiredValues.taxZone;
	if (!formFieldsConfig.hasIdDoc || !isEmployee) delete requiredValues.hasIDDoc;

	return requiredValues;
};

const getMissingFieldsNames = (
	errors: DeepMap<any, FieldError>
): {
	names: string[];
	tabs: Set<number>;
} => {
	const fields = {
		// General Info Tab
		fullName: {
			name: 'Full Name',
			tab: 0
		},
		placeOfBirth: {
			name: 'Place Of Birth',
			tab: 0
		},
		gender: {
			name: 'Gender',
			tab: 0
		},
		dateOfBirth: {
			name: 'Date Of Birth',
			tab: 0
		},
		validatePlate: {
			name: 'Validate Plate',
			tab: 0
		},
		licensePlate: {
			name: 'License Plate',
			tab: 0
		},
		// Contact Info Tab
		address: {
			name: 'Address',
			tab: 1
		},
		mobilePhone: {
			name: 'Mobile Phone',
			tab: 1
		},
		city: {
			name: 'City',
			tab: 1
		},
		emergencyContact: {
			name: 'Emergency Contact',
			tab: 1
		},
		zipCode: {
			name: 'Zip Code',
			tab: 1
		},
		emergencyContactName: {
			name: 'Emergency Contact Name',
			tab: 1
		},
		email: {
			name: 'Email',
			tab: 1
		},
		nationality: {
			name: 'Nationality',
			tab: 1
		},
		// ID Info Tab
		docType: {
			name: 'Doc Type',
			tab: 2
		},
		fiscalNumber: {
			name: 'Fiscal Number',
			tab: 2
		},
		citizenCardNumber: {
			name: 'Citizen Card Number',
			tab: 2
		},
		socialSecurityNumber: {
			name: 'Social Security Number',
			tab: 2
		},
		pensionFundNumber: {
			name: 'Pension Fund Number',
			tab: 2
		},
		hasIDDoc: {
			name: 'ID Proof',
			tab: 2
		},
		// Family Aggregate Info Tab
		maritalStatus: {
			name: 'Marital Status',
			tab: 3
		},
		holdersNumber: {
			name: 'Holders Number',
			tab: 3
		},
		dependentsNumber: {
			name: 'Dependants Number',
			tab: 3
		},
		// Qualification Info Tab
		courseQualificationId: {
			name: 'courseQualificationId',
			tab: 4
		},
		courseInstitution: {
			name: 'courseInstitution',
			tab: 4
		},
		educationCode: {
			name: 'educationCode',
			tab: 4
		},
		courseStartDate: {
			name: 'courseStartDate',
			tab: 4
		},
		educationStatus: {
			name: 'educationStatus',
			tab: 4
		},
		courseEndDate: {
			name: 'courseEndDate',
			tab: 4
		},
		courseName: {
			name: 'courseName',
			tab: 4
		},
		careerStartDate: {
			name: 'careerStartDate',
			tab: 4
		},
		coursePostBologna: {
			name: 'coursePostBologna',
			tab: 4
		},
		professionalExperience: {
			name: 'professionalExperience',
			tab: 4
		},
		// Banking Info Tab
		bankId: {
			name: 'Bank Id',
			tab: 5
		},
		bankNib: {
			name: 'Bank Nib',
			tab: 5
		},
		hasBankDoc: {
			name: 'Iban Proof',
			tab: 5
		},
		// Banking Info Tab
		biometricSystemsConsent: {
			name: 'Biometric Systems Consent',
			tab: 6
		},
		intranetSystemsConsent: {
			name: 'Intranet Systems Consent',
			tab: 6
		},
		promotionalMediaConsent: {
			name: 'Promotional Media Consent',
			tab: 6
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		names: fieldsMissing.map(e => e?.name),
		tabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

const getAdditionalErrors = (
	errors: DeepMap<any, FieldError>
): {
	additionalNames: string[];
	additionalTabs: Set<number>;
} => {
	const fields = {
		// General Info Tab
		fullName: {
			name: 'Full Name',
			tab: 0
		},
		placeOfBirth: {
			name: 'Place Of Birth',
			tab: 0
		},
		gender: {
			name: 'Gender',
			tab: 0
		},
		dateOfBirth: {
			name: 'Date Of Birth',
			tab: 0
		},
		validatePlate: {
			name: 'Validate Plate',
			tab: 0
		},
		licensePlate: {
			name: 'License Plate',
			tab: 0
		},
		// Contact Info Tab
		address: {
			name: 'Address',
			tab: 1
		},
		mobilePhone: {
			name: 'Mobile Phone',
			tab: 1
		},
		city: {
			name: 'City',
			tab: 1
		},
		emergencyContact: {
			name: 'Emergency Contact',
			tab: 1
		},
		zipCode: {
			name: 'Zip Code',
			tab: 1
		},
		emergencyContactName: {
			name: 'Emergency Contact Name',
			tab: 1
		},
		email: {
			name: 'Email',
			tab: 1
		},
		nationality: {
			name: 'Nationality',
			tab: 1
		},
		// ID Info Tab
		docType: {
			name: 'Doc Type',
			tab: 2
		},
		fiscalNumber: {
			name: 'Fiscal Number',
			tab: 2
		},
		citizenCardNumber: {
			name: 'Citizen Card Number',
			tab: 2
		},
		socialSecurityNumber: {
			name: 'Social Security Number',
			tab: 2
		},
		pensionFundNumber: {
			name: 'Pension Fund Number',
			tab: 2
		},
		hasIDDoc: {
			name: 'ID Proof',
			tab: 2
		},
		// Family Aggregate Info Tab
		maritalStatus: {
			name: 'Marital Status',
			tab: 3
		},
		holdersNumber: {
			name: 'Holders Number',
			tab: 3
		},
		dependentsNumber: {
			name: 'Dependants Number',
			tab: 3
		},
		// Qualification Info Tab
		courseQualificationId: {
			name: 'courseQualificationId',
			tab: 4
		},
		courseInstitution: {
			name: 'courseInstitution',
			tab: 4
		},
		educationCode: {
			name: 'educationCode',
			tab: 4
		},
		courseStartDate: {
			name: 'courseStartDate',
			tab: 4
		},
		educationStatus: {
			name: 'educationStatus',
			tab: 4
		},
		courseEndDate: {
			name: 'courseEndDate',
			tab: 4
		},
		courseName: {
			name: 'courseName',
			tab: 4
		},
		careerStartDate: {
			name: 'careerStartDate',
			tab: 4
		},
		coursePostBologna: {
			name: 'coursePostBologna',
			tab: 4
		},
		professionalExperience: {
			name: 'professionalExperience',
			tab: 4
		},
		// Banking Info Tab
		bankId: {
			name: 'Bank Id',
			tab: 5
		},
		bankNib: {
			name: 'Bank Nib',
			tab: 5
		},
		hasBankDoc: {
			name: 'Iban Proof',
			tab: 5
		},
		// Banking Info Tab
		biometricSystemsConsent: {
			name: 'Biometric Systems Consent',
			tab: 6
		},
		intranetSystemsConsent: {
			name: 'Intranet Systems Consent',
			tab: 6
		},
		promotionalMediaConsent: {
			name: 'Promotional Media Consent',
			tab: 6
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		additionalNames: fieldsMissing.map(e => e?.name),
		additionalTabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

//* COMPONENT
const GDPR: FC<IAdmissionSheetTabs> = ({
	control,
	errors,
	watch,
	handleWarning,
	additionalErrors,
	submitClick,
	setSubmitClick,
	formFields,
	isEmployee
}) => {
	const classes = useStyles();
	const companyInfo = useSelector(state => state.company);
	useEffect(() => {
		if (submitClick) {
			const formPayload = watch();
			const requiredValues = markRequiredValues(formPayload, formFields.formFieldsConfig, isEmployee);
			const warningValues = {};
			Object.keys(requiredValues).forEach(e => {
				if (requiredValues[e] === false) {
					warningValues[e] = '';
				}
			});

			const { names, tabs } = getMissingFieldsNames(warningValues);
			const { additionalNames, additionalTabs } = getAdditionalErrors(additionalErrors);

			let mergedNames = names.concat(additionalNames);

			var mergedTabs = new Set([...tabs, ...additionalTabs]);

			if (mergedNames.length !== 0) {
				handleWarning(mergedNames, [...mergedTabs]);
				addNotification(
					'danger',
					`Admission Sheet's form is not completed, the following fields must be valid: ${mergedNames.join(
						', '
					)}`
				);
			}
			setSubmitClick(false);
		}
	}, [submitClick]);

	return (
		<Grid container spacing={4} className={classes.root}>
			{formFields.formFieldsConfig.biometricSystemsConsent && (
				<Grid item {...sizes[12]}>
					<Checkbox
						name="biometricSystemsConsent"
						label="BIOMETRIC SYSTEMS CONSENT"
						helperText={`I acknowledge ${companyInfo.companyText}'s legitimate interest in collecting my biometric data, such as fingerprints or eyepiece, for exclusive use in ${companyInfo.companyText}'s facilities access control systems, thereby giving my express consent to the collection , processing and use of such data for said purposes, leaving ${companyInfo.companyText} as the entity responsible for complying with obligations regarding the protection of personal data, in accordance with the applicable law, namely for its confidentiality and elimination.`}
						control={control}
						errors={errors}
						disabled
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.intranetSystemsConsent && (
				<Grid item {...sizes[12]}>
					<Checkbox
						name="intranetSystemsConsent"
						label="INTRANET SYSTEMS CONSENT"
						helperText={`I acknowledge ${companyInfo.companyText}'s legitimate interest in collecting my personal data, such as facial photography and personal mobile phone, for exclusive use in its internal information systems, for work purposes and for internal and corporate organization and operation, giving to ${companyInfo.companyText} my express consent to the collection, processing and use of such data for said purposes, leaving ${companyInfo.companyText} as the entity responsible for complying with obligations regarding the protection of personal data, in accordance with the applicable law, namely for its confidentiality and elimination.`}
						control={control}
						errors={errors}
						disabled
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.promotionalMediaConsent && (
				<Grid item {...sizes[12]}>
					<Checkbox
						name="promotionalMediaConsent"
						label="PROMOTIONAL MEDIA CONSENT"
						helperText={`I acknowledge ${companyInfo.companyText}'s legitimate interest in collecting of my personal data, such as personal image in photographs, videos or any media type, its recording, editing and public display in any media in Portugal and abroad, solely for the purpose of ${companyInfo.companyText} using it for its institutional communication and promotional marketing, thereby giving ${companyInfo.companyText}, my express consent to the collection, processing and use of such data for those mentioned purposes, leaving ${companyInfo.companyText} as the entity responsible for complying with obligations regarding the protection of personal data, in accordance with the applicable law, namely for its confidentiality and elimination.`}
						control={control}
						errors={errors}
						disabled
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default GDPR;
