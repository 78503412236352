/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import * as yup from 'yup';

import { EMPLOYEE } from 'pages/Admin/_ProposalsFormUtils/constants';

import {
	string,
	stringRequired,
	stringLongRequired,
	number,
	numberPositiveRequired,
	numberZeroOrPositiveRequired,
	numberRequired,
	boolean,
	dateDefault,
	dateRequired,
	passwordSecure,
	numberPositive
} from 'lib/utils/formUtils';
import {
	BALWURK_EMPLOYEE_ID,
	INSTANCE,
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_HR,
	XPAND_EMPLOYEE_ID_UK,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_DE
} from 'lib/utils/constants';
import { store } from '../../../../store/company/reducer';

const state = store.getState();
const general = formFields => {
	return {
		// GENERAL INFORMATION - left
		collaborationType: stringRequired,
		name: formFields?.name ? stringRequired : string,
		username: formFields?.username
			? stringRequired
					.min(4, 'Username must have at least 4 characters')
					.test('invalid-username', 'Username already in use.', function (username) {
						if ((username as string).length < 3) return false; // false -> show error

						if (this.parent.usersList.includes(username)) return false; // false -> show error

						return true;
					})
			: string,
		email: formFields?.email
			? stringRequired.test('valid-email', 'Must be a valid email', function (newEmail) {
					/* eslint-disable no-useless-escape */
					const emailRegex =
						/^(([^0123456789áÁàÀâÂäÄãÃåÅæÆçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØœŒßúÚùÙûÛüÜ/\\[^#$§£%&"«»*¨ºª;,:?+-_€~!=}{'´`<>()\[\]\\.,;:\s@"]+(\.[^0123456789áÁàÀâÂäÄãÃåÅæÆçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØœŒßúÚùÙûÛüÜ/\\[^#$§£%&"«»*¨ºª;,:?+-_€~!=}{'´`<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([a-z\-]+\.)+[a-zA-Z]{2,})$/;
					/* eslint-enable no-useless-escape */
					const emailComplete = `${newEmail}@${state.companyDomain}`;

					if (!emailRegex.test(emailComplete)) return false; // false -> show erro

					if (this.parent?.emailsList?.includes(emailComplete)) return false; // false -> show error

					return true;
			  })
			: string,
		jiraCandidateApplication: string,
		startDate: formFields?.startDate ? dateRequired : dateDefault,
		// GENERAL INFORMATION - right
		company: formFields?.company ? stringRequired : string,
		businessUnit: formFields?.businessUnit ? stringRequired : string,
		division: formFields?.division ? stringRequired : string,
		department: formFields?.department ? stringRequired : string,
		employeeCategory: formFields?.employeeCategory ? stringRequired : string,
		collaborationRole: formFields?.collabRole ? stringRequired : string
	};
};
const generalHR = formFields => {
	return {
		// GENERAL INFORMATION - left
		collaborationType: stringRequired,
		name: formFields?.name ? stringRequired : string,
		username: formFields?.username
			? stringRequired
					.min(4, 'Username must have at least 4 characters')
					.test('invalid-username', 'Username already in use.', function (username) {
						if ((username as string).length < 3) return false; // false -> show error

						if (this.parent.usersList.includes(username)) return false; // false -> show error

						return true;
					})
			: string,
		email: formFields?.email
			? stringRequired.test('valid-email', 'Must be a valid email', function (newEmail) {
					/* eslint-disable no-useless-escape */
					const emailRegex =
						/^(([^0123456789áÁàÀâÂäÄãÃåÅæÆçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØœŒßúÚùÙûÛüÜ/\\[^#$§£%&"«»*¨ºª;,:?+-_€~!=}{'´`<>()\[\]\\.,;:\s@"]+(\.[^0123456789áÁàÀâÂäÄãÃåÅæÆçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØœŒßúÚùÙûÛüÜ/\\[^#$§£%&"«»*¨ºª;,:?+-_€~!=}{'´`<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([a-z\-]+\.)+[a-zA-Z]{2,})$/;
					/* eslint-enable no-useless-escape */
					const emailComplete = `${newEmail}@${state.companyDomain}`;

					if (!emailRegex.test(emailComplete)) return false; // false -> show erro

					if (this.parent?.emailsList?.includes(emailComplete)) return false; // false -> show error

					return true;
			  })
			: string,
		jiraCandidateApplication: string,
		startDate: formFields?.startDate ? dateRequired : dateDefault,
		// GENERAL INFORMATION - right
		company: formFields?.company ? stringRequired : string,
		businessUnit: formFields?.businessUnit ? stringRequired : string,
		division: formFields?.division ? stringRequired : string,
		department: formFields?.department ? stringRequired : string,
		employeeCategory: formFields?.employeeCategory ? stringRequired : string,
		collaborationRole: formFields?.collabRole ? stringRequired : string
	};
};

const employeeCategoryCompare = INSTANCE.toLowerCase().includes('xpand')
	? [XPAND_EMPLOYEE_ID, XPAND_EMPLOYEE_ID_HR, XPAND_EMPLOYEE_ID_UK, XPAND_EMPLOYEE_ID_SE, XPAND_EMPLOYEE_ID_DE]
	: [BALWURK_EMPLOYEE_ID];

const careerFinancial = formFields => {
	return {
		// ------------------------------------------- CAREER & FINANCIAL
		// CAREER & FINANCIAL - CAREER INFO
		softwareDevelopmentCenter: boolean,
		careerPosition: formFields?.careerPosition ? stringRequired : string,
		careerJobRole: formFields?.careerJobRole ? stringRequired : string,
		careerManager: formFields?.careerManager ? stringRequired : string,
		careerJobRoleLevel: formFields?.careerJobRoleLevel ? stringRequired : string,
		// CAREER & FINANCIAL - CAREER INFO
		holdersNumber: formFields?.holders
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		disabled: boolean,
		maritalStatus: formFields?.maritalStatus
			? stringRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		tsuExemption: boolean,
		dependentsNumber: formFields?.numberDependents
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		taxZone: string,
		// CAREER & FINANCIAL - GENERAL AMOUNTS
		baseValue: formFields?.baseValue
			? number.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		irsToApply: numberRequired, // readOnly
		ihtValue: numberRequired, // readOnly
		ihtPercentage: number,
		annualGrossValue: number,
		hasTwelfths: boolean,
		vacationsTwelfth: formFields?.vacationsTwelfth ? numberRequired : number, // readOnly
		christmasTwelfth: formFields?.christmasTwelfth ? numberRequired : number, // readOnly
		grossBaseValue: formFields?.grossBaseValue ? numberRequired : number, // readOnly
		totalTaxableValue: formFields?.totalTaxableValue ? numberRequired : number, // readOnly
		foodAllowanceType: formFields?.foodAllowanceType
			? stringRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		foodAllowance: formFields?.foodAllowance
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		foodAllowanceDays: formFields?.foodAllowanceDays
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		nationalAllowanceDays: number,
		nationalAllowance: formFields?.nationalAllowance
			? number.when('nationalAllowanceDays', {
					is: (val: number) => val > 0,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		internationalAllowanceDays: number,
		internationalAllowance: formFields?.internationalAllowance
			? number.when('internationalAllowanceDays', {
					is: (val: number) => val > 0,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		travelPass: number,
		additionalExpenses: number,
		nurseryCheck: number,
		lifeInsurance: number,
		hourlyRate: formFields?.hourlyRate
			? number.when('employeeCategory', {
					is: (val: string) => !employeeCategoryCompare.includes(val),
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		budget: formFields?.monthlyBudget
			? number.when('employeeCategory', {
					is: (val: string) => !employeeCategoryCompare.includes(val),
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		companySocSec: numberPositiveRequired,
		estimuloMedida: boolean,
		// CAREER & FINANCIAL - PRIZE BONUS
		createPrizeBonusErp: boolean,
		prizeBonus: number,
		prizeBonusPercentage: number,
		monthsApplicable: number,
		eligiblePrizeValue: number,
		prizeBonusGoals: yup.array().when('prizeBonus', {
			is: (val: number) => val > 0,
			then: yup
				.array()
				.of(
					yup.object().shape({
						id: string,
						goalDetails: stringLongRequired,
						goalTypeId: numberRequired,
						prizePercentage: numberPositiveRequired,
						prizeValue: numberPositiveRequired
					})
				)
				.min(1, 'Must have at least one prize goal when MAXIMUM PRIZE BONUS is set.')
				.test('not-100-percent', 'The sum of all prize goals must be 100%', (data): boolean => {
					if (data) {
						const totalPercentages = data.reduce(
							(total, current) => (current?.prizePercentage ? current.prizePercentage + total : total),
							0
						);
						// return true -> the error will NOT show
						// return false -> the error will show
						return totalPercentages === 100;
					}
					return true;
				})
				.test('not-empty-percentages', "Can't exist goals with no percentage value", data =>
					// return true -> the error will NOT show
					// return false -> the error will show
					data ? !data.map(e => e && Number(e.prizePercentage)).includes(0) : true
				),
			otherwise: yup.array()
		}),
		annualPrizeIRS: stringRequired,
		totalComponentsPrizePercentage: stringRequired,
		totalComponentsPrizeValue: stringRequired
	};
};
const careerFinancialHR = formFields => {
	return {
		// ------------------------------------------- CAREER & FINANCIAL
		// CAREER & FINANCIAL - CAREER INFO
		softwareDevelopmentCenter: boolean,
		careerPosition: formFields?.careerPosition ? stringRequired : string,
		careerJobRole: formFields?.careerJobRole ? stringRequired : string,
		careerManager: formFields?.careerManager ? stringRequired : string,
		// CAREER & FINANCIAL - CAREER INFO
		holdersNumber: formFields?.holders
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		disabled: boolean,
		maritalStatus: formFields?.maritalStatus
			? stringRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		tsuExemption: boolean,
		dependentsNumber: formFields?.numberDependents
			? numberRequired.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		surtaxZone: formFields?.surtaxZone ? stringRequired : string,
		payBonusSalary: boolean,
		bonusSalary: formFields?.bonusSalary
			? number.when('payBonusSalary', {
					is: (val: boolean) => val,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		pensionFund: formFields?.pensionFund ? numberRequired : number,
		// CAREER & FINANCIAL - GENERAL AMOUNTS
		baseValue: formFields?.baseValue
			? number.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		irsToApply: numberRequired, // readOnly
		ihtValue: numberRequired, // readOnly
		ihtPercentage: number,
		annualGrossValue: number,
		hasTwelfths: boolean,
		vacationsTwelfth: formFields?.vacationsTwelfth ? numberRequired : number, // readOnly
		christmasTwelfth: formFields?.christmasTwelfth ? numberRequired : number, // readOnly
		grossBaseValue: formFields?.grossBaseValue ? numberRequired : number, // readOnly
		totalTaxableValue: formFields?.totalTaxableValue ? numberRequired : number, // readOnly
		nationalAllowanceDays: number,
		nationalAllowance: formFields?.nationalAllowance
			? number.when('nationalAllowanceDays', {
					is: (val: number) => val > 0,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		internationalAllowanceDays: number,
		internationalAllowance: formFields?.internationalAllowance
			? number.when('internationalAllowanceDays', {
					is: (val: number) => val > 0,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		travelPass: number,
		additionalExpenses: number,
		nurseryCheck: number,
		lifeInsurance: number,
		hourlyRate: formFields?.hourlyRate
			? number.when('employeeCategory', {
					is: (val: string) => !employeeCategoryCompare.includes(val),
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		budget: formFields?.monthlyBudget
			? number.when('employeeCategory', {
					is: (val: string) => !employeeCategoryCompare.includes(val),
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		estimuloMedida: boolean,
		// CAREER & FINANCIAL - PRIZE BONUS
		createPrizeBonusErp: boolean,
		prizeBonus: number,
		prizeBonusPercentage: number,
		monthsApplicable: number,
		eligiblePrizeValue: number,
		prizeBonusGoals: yup.array().when('prizeBonus', {
			is: (val: number) => val > 0,
			then: yup
				.array()
				.of(
					yup.object().shape({
						id: string,
						goalDetails: stringLongRequired,
						goalTypeId: numberRequired,
						prizePercentage: numberPositiveRequired,
						prizeValue: numberPositiveRequired
					})
				)
				.min(1, 'Must have at least one prize goal when MAXIMUM PRIZE BONUS is set.')
				.test('not-100-percent', 'The sum of all prize goals must be 100%', (data): boolean => {
					if (data) {
						const totalPercentages = data.reduce(
							(total, current) => (current?.prizePercentage ? current.prizePercentage + total : total),
							0
						);
						// return true -> the error will NOT show
						// return false -> the error will show
						return totalPercentages === 100;
					}
					return true;
				})
				.test('not-empty-percentages', "Can't exist goals with no percentage value", data =>
					// return true -> the error will NOT show
					// return false -> the error will show
					data ? !data.map(e => e && Number(e.prizePercentage)).includes(0) : true
				),
			otherwise: yup.array()
		}),
		annualPrizeIRS: stringRequired,
		totalComponentsPrizePercentage: stringRequired,
		totalComponentsPrizeValue: stringRequired
	};
};
const otherBenefits = formFields => {
	return {
		commsPlafondFlag: boolean,
		commsPlafond: formFields?.communicationsPlafondValue
			? number.when('commsPlafondFlag', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		healthInsuranceCheck: boolean,
		healthInsuranceValue: formFields?.healthInsuranceValue
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		includeFamilyMembers: boolean,
		numberMembersDep: formFields?.numberOfFamilyMembers
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		familyMembersComments: string,
		otherbenefits: boolean,
		otherBenefitsList: yup.array().of(
			yup.object().shape({
				id: string,
				goalDetails: stringRequired,
				prizeValue: numberPositiveRequired
			})
		),
		benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag', 'employeeCategory'], {
			is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean, employeeCategory: string) =>
				employeeCategoryCompare.includes(employeeCategory) && (!healthInsuranceCheck || !commsPlafondFlag),
			then: stringRequired,
			otherwise: string
		}),
		password: formFields?.password ? passwordSecure : string
	};
};
const otherBenefitsHR = formFields => {
	return {
		commsPlafondFlag: boolean,
		commsPlafond: formFields?.communicationsPlafondValue
			? number.when('commsPlafondFlag', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		healthInsuranceCheck: boolean,
		healthInsuranceValue: formFields?.healthInsuranceValue
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberPositiveRequired,
					otherwise: number
			  })
			: number,
		includeFamilyMembers: boolean,
		numberMembersDep: formFields?.numberOfFamilyMembers
			? number.when('healthInsuranceCheck', {
					is: true,
					then: numberRequired,
					otherwise: number
			  })
			: number,
		familyMembersComments: string,
		otherbenefits: boolean,
		otherBenefitsList: yup.array().of(
			yup.object().shape({
				id: string,
				goalDetails: stringRequired,
				prizeValue: numberPositiveRequired
			})
		),
		benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag', 'employeeCategory'], {
			is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean, employeeCategory: string) =>
				employeeCategoryCompare.includes(employeeCategory) && (!healthInsuranceCheck || !commsPlafondFlag),
			then: stringRequired,
			otherwise: string
		}),
		password: formFields?.password ? passwordSecure : string
	};
};

const contractCompany = formFields => {
	return {
		contractType: formFields?.contractType
			? string.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		contractPosition: formFields?.contractPosition
			? string.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		daysOfProbationPeriod: formFields?.contractPosition
			? string.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: string.when('contractType', {
						is: 10, // Assuming 10 is the value you're checking for in contractType
						then: stringRequired,
						otherwise: string // You might want to adjust this depending on your needs
					}),
					otherwise: string
			  })
			: string,
		probationPeriodEndDate: dateDefault,
		customerInContract: formFields?.customerInContract
			? string.when('contractType', {
					is: (value: string) =>
						// 20 - fixed term
						value === '20',
					then: stringRequired,
					otherwise: string
			  })
			: string,
		traineeType: string.when('contractType', {
			is: (value: string) =>
				// 80 - trainee
				value === '80',
			then: stringRequired,
			otherwise: string
		}),
		fixedTermContractDuration: string.when('contractType', {
			is: (value: string) =>
				// 20 - fixed term
				// 80 - trainee
				value === '20' || value === '80',
			then: stringRequired,
			otherwise: string
		}),
		endDate: dateDefault,
		primaryOffice: formFields?.primaryOffice ? stringRequired : string,
		workingOffice: formFields?.workingOffice ? stringRequired : string,
		employeeManager: formFields?.employeeManager ? stringRequired : string,
		holidayType: formFields?.holidayType ? stringRequired : string,
		referralType: formFields?.referralType ? stringRequired : string,
		reference: formFields?.reference
			? string.when('referralType', {
					is: (value: string) => value === 'Employee',
					then: stringRequired,
					otherwise: string
			  })
			: string,
		referralId: string,
		noTimeOffPolicy: boolean,
		vacationDays: formFields?.nrVacationDays
			? number.when('noTimeOffPolicy', {
					is: (value: boolean) => Boolean(value) === false,
					then: numberRequired,
					otherwise: number.notRequired()
			  })
			: number,
		timeOffApprovalType: formFields?.timeoffApprovalType
			? string.when('noTimeOffPolicy', {
					is: (value: boolean) => Boolean(value) === false,
					then: stringRequired,
					otherwise: string.notRequired()
			  })
			: string,
		timeoffPolicy: formFields?.timeoffPolicy
			? string.when('noTimeOffPolicy', {
					is: (value: boolean) => Boolean(value) === false,
					then: stringRequired,
					otherwise: string.notRequired()
			  })
			: string,
		contactListLevel: string
	};
};
const projects = {
	projects: yup.array().of(
		yup.object().shape({
			projectId: stringRequired,
			projectName: stringRequired
		})
	)
};
const contractCompanyHR = formFields => {
	return {
		contractType: formFields?.contractType
			? string.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		contractPosition: formFields?.contractPosition
			? string.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: stringRequired,
					otherwise: string
			  })
			: string,
		daysOfProbationPeriod: formFields?.contractPosition
			? string.when('employeeCategory', {
					is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
					then: string.when('contractType', {
						is: 10, // Assuming 10 is the value you're checking for in contractType
						then: stringRequired,
						otherwise: string // You might want to adjust this depending on your needs
					}),
					otherwise: string
			  })
			: string,
		probationPeriodEndDate: dateDefault,
		traineeType: string.when('contractType', {
			is: (value: string) =>
				// 80 - trainee
				value === '80',
			then: stringRequired,
			otherwise: string
		}),
		fixedTermContractDuration: string.when('contractType', {
			is: (value: string) =>
				// 20 - fixed term
				// 80 - trainee
				value === '20' || value === '80',
			then: stringRequired,
			otherwise: string
		}),
		endDate: dateDefault,
		primaryOffice: formFields?.primaryOffice ? stringRequired : string,
		workingOffice: formFields?.workingOffice ? stringRequired : string,
		employeeManager: formFields?.employeeManager ? stringRequired : string,
		holidayType: formFields?.holidayType ? stringRequired : string,
		referralType: formFields?.referralType ? stringRequired : string,
		reference: formFields?.reference
			? string.when('referralType', {
					is: (value: string) => value === 'Employee',
					then: stringRequired,
					otherwise: string
			  })
			: string,
		referralId: string,
		noTimeOffPolicy: boolean,
		vacationDays: formFields?.nrVacationDays
			? number.when('noTimeOffPolicy', {
					is: (value: boolean) => Boolean(value) === false,
					then: numberRequired,
					otherwise: number.notRequired()
			  })
			: number,
		timeOffApprovalType: formFields?.timeoffApprovalType
			? string.when('noTimeOffPolicy', {
					is: (value: boolean) => Boolean(value) === false,
					then: stringRequired,
					otherwise: string.notRequired()
			  })
			: string,
		timeoffPolicy: formFields?.timeoffPolicy
			? string.when('noTimeOffPolicy', {
					is: (value: boolean) => Boolean(value) === false,
					then: stringRequired,
					otherwise: string.notRequired()
			  })
			: string,
		contactListLevel: string
	};
};

export const schema = formFields => {
	if (formFields?.country == 'HR') {
		return yup.object().shape({
			// PROPOSAL DB INFO
			proposalId: string,
			token: string,
			// GENERAL INFORMATION
			...generalHR(formFields),
			// CAREER & FINANCIAL
			...careerFinancialHR(formFields),
			// OTHER BENEFITS
			...otherBenefitsHR(formFields),
			// CONTRACT & COMPANY
			...contractCompanyHR(formFields),
			// PROJECTS
			...projects
		});
	}
	return yup.object().shape({
		// PROPOSAL DB INFO
		proposalId: string,
		token: string,
		// GENERAL INFORMATION
		...general(formFields),
		// CAREER & FINANCIAL
		...careerFinancial(formFields),
		// OTHER BENEFITS
		...otherBenefits(formFields),
		// CONTRACT & COMPANY
		...contractCompany(formFields),
		// PROJECTS
		...projects
	});
};

export const createDefaultValues = (employeeCode: string, referenceData: any) => {
	if (employeeCode == 'HR') {
		return {
			// PROPOSAL DB INFO
			proposalId: '',
			token: '',
			// ------------------------------------------- GENERAL INFORMATION
			collaborationType: EMPLOYEE,
			name: '',
			username: '',
			email: '',
			jiraCandidateApplication: '',
			startDate: null,
			//
			company: state.defaultCompanyKey,
			businessUnit: '',
			division: '',
			department: '',
			employeeCategory: INSTANCE.toLowerCase().includes('xpand') ? XPAND_EMPLOYEE_ID : BALWURK_EMPLOYEE_ID,
			collaborationRole: 'EMPLOYEE',
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - USER DATA
			// importFromAnotherProposal: false, // form helper
			importFromAnotherProposal: true, // form helper
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: false,
			careerPosition: '',
			careerJobRole: '',
			careerJobRoleLevel: '',
			careerManager: '',
			// CAREER & FINANCIAL - TAXES
			holdersNumber: '1',
			annualGrossValue: 0,
			companyName: '',
			fullName: '',
			internshipDescription: '',
			irsTwelfth: '',
			password: '',
			disabled: false,
			maritalStatus: 'Single',
			tsuExemption: false,
			estimuloMedida: false,
			dependentsNumber: '0',
			taxZone: undefined,
			surtaxZone: undefined,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: '0',
			payBonusSalary: true,
			bonusSalary: '0',
			irsToApply: '0',
			ihtValue: '0',
			ihtPercentage: '25.00',
			hasTwelfths: false,
			vacationsTwelfth: '0',
			christmasTwelfth: '0',
			grossBaseValue: '0',
			totalTaxableValue: '0',
			nationalAllowanceDays: '0',
			nationalAllowance: '0',
			internationalAllowanceDays: '0',
			internationalAllowance: '0',
			travelPass: '0',
			additionalExpenses: '0',
			nurseryCheck: '0',
			lifeInsurance: '0',
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: false,
			prizeBonus: '0',
			prizeBonusPercentage: 0,
			monthsApplicable: '12',
			eligiblePrizeValue: 0,
			prizeBonusGoals: [],
			annualPrizeIRS: '0',
			totalComponentsPrizePercentage: '0',
			totalComponentsPrizeValue: '0',
			careerAndFinancialNotes: '',
			// OTHER BENEFITS
			commsPlafondFlag: true,
			commsPlafond: referenceData?.xphrMonStdComPlafond,
			healthInsuranceCheck: true,
			healthInsuranceValue: '29.33',
			includeFamilyMembers: false,
			numberMembersDep: '0',
			familyMembersComments: '',
			otherbenefits: true,
			otherBenefitsList: [{ goalDetails: 'Transportation Allowance', prizeValue: 33 }],
			benefitsComments: '',
			// CONTRACT & COMPANY
			contractType: '',
			daysOfProbationPeriod: '180',
			probationPeriodEndDate: null,
			customerInContract: '',
			traineeType: '',
			fixedTermContractDuration: '',
			endDate: '',
			contractPosition: '',
			primaryOffice: '',
			workingOffice: '',
			employeeManager: '',
			holidayType: '',
			referralType: '',
			reference: '',
			referralId: '',
			noTimeOffPolicy: false,
			contactListLevel: '',
			vacationDays: '',
			timeOffApprovalType: '',
			timeoffPolicy: '',
			// PROJECTS
			admissionProcessSheetId: undefined as undefined | number,
			projects: [] as any,
			usersList: [] as any,
			emailsList: [] as any,
			pensionFund: '0'
		};
	}

	return {
		// PROPOSAL DB INFO
		proposalId: '',
		token: '',
		// ------------------------------------------- GENERAL INFORMATION
		collaborationType: EMPLOYEE,
		name: '',
		companySocSec: '23.75',
		username: '',
		email: '',
		jiraCandidateApplication: '',
		startDate: null,
		//
		company: state.defaultCompanyKey,
		businessUnit: '',
		division: '',
		department: '',
		employeeCategory: INSTANCE.toLowerCase().includes('xpand') ? XPAND_EMPLOYEE_ID : BALWURK_EMPLOYEE_ID,
		collaborationRole: 'EMPLOYEE',
		// ------------------------------------------- CAREER & FINANCIAL
		// CAREER & FINANCIAL - USER DATA
		// importFromAnotherProposal: false, // form helper
		importFromAnotherProposal: true, // form helper
		// CAREER & FINANCIAL - CAREER INFO
		softwareDevelopmentCenter: false,
		careerPosition: '',
		careerJobRole: '',
		careerJobRoleLevel: '',
		careerManager: '',
		// CAREER & FINANCIAL - TAXES
		holdersNumber: '1',
		annualGrossValue: 0,
		companyName: '',
		fullName: '',
		internshipDescription: '',
		irsTwelfth: '',
		password: '',
		disabled: false,
		maritalStatus: 'Single',
		tsuExemption: false,
		estimuloMedida: false,
		dependentsNumber: '0',
		taxZone: undefined,
		// CAREER & FINANCIAL - GENERAL AMOUNTS
		baseValue: '0',
		payBonusSalary: false,
		bonusSalary: '0',
		hourlyRate: 0,
		budget: 0,
		irsToApply: '0',
		ihtValue: '0',
		ihtPercentage: '25.00',
		hasTwelfths: true,
		vacationsTwelfth: '0',
		christmasTwelfth: '0',
		grossBaseValue: '0',
		totalTaxableValue: '0',
		foodAllowanceType: 'Card',
		foodAllowance: referenceData?.xphrCrFoodAllowValue,
		foodAllowanceDays: referenceData?.xphrFoodAllowanceDays,
		nationalAllowanceDays: '0',
		nationalAllowance: '0',
		internationalAllowanceDays: '0',
		internationalAllowance: '0',
		travelPass: '0',
		additionalExpenses: '0',
		nurseryCheck: '0',
		lifeInsurance: '0',
		// CAREER & FINANCIAL - PRIZE BONUS
		createPrizeBonusErp: false,
		prizeBonus: '0',
		prizeBonusPercentage: 0,
		monthsApplicable: '12',
		eligiblePrizeValue: 0,
		prizeBonusGoals: [],
		annualPrizeIRS: '0',
		totalComponentsPrizePercentage: '0',
		totalComponentsPrizeValue: '0',
		careerAndFinancialNotes: '',
		// OTHER BENEFITS
		commsPlafondFlag: true,
		commsPlafond: referenceData?.xphrMonStdComPlafond,
		healthInsuranceCheck: true,
		healthInsuranceValue: referenceData?.xphrMonStdHeaInsCost,
		includeFamilyMembers: false,
		numberMembersDep: '0',
		familyMembersComments: '',
		otherbenefits: true,
		otherBenefitsList: [{ goalDetails: 'Remote Work Allowance', prizeValue: 15 }],
		benefitsComments: '',
		// CONTRACT & COMPANY
		contractType: '',
		daysOfProbationPeriod: '180',
		probationPeriodEndDate: null,
		customerInContract: '',
		traineeType: '',
		fixedTermContractDuration: '',
		endDate: '',
		contractPosition: '',
		primaryOffice: '',
		workingOffice: '',
		employeeManager: '',
		holidayType: '',
		referralType: '',
		reference: '',
		referralId: '',
		noTimeOffPolicy: false,
		contactListLevel: '',
		vacationDays: '',
		timeOffApprovalType: '',
		timeoffPolicy: '',
		// PROJECTS
		admissionProcessSheetId: undefined as undefined | number,
		projects: [] as any,
		usersList: [] as any,
		emailsList: [] as any
	};
};
