//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay } from 'xpand-ui/core';

//* TYPINGS
import { IUserPermission } from 'typings/store/systemTypes';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { getLSField } from 'lib/utils/cookies';
import withLayout from 'lib/hocs/withLayout';
import { Roles } from 'lib/roles';

//* LOCAL COMPONENT IMPORTS
import { ApproveProposalProps } from '.';
// import { useStyles  } from './styles';

//* COMPONENT INTERFACES
interface IApproveProposal extends ApproveProposalProps {
	match: Match<{
		token: string;
		proposalId: string;
		manager: string;
	}>;
	goToPage: (path: string) => void;
}

const notificationPayload = {
	area: 'Recruitment',
	section: 'Collaboration Proposals'
};

//* COMPONENT
const ApproveProposal: FC<IApproveProposal> = ({
	goToPage,
	match,
	proposals,
	submitProposalApprove,
	setNotificationAsRead
}) => {
	// const classes = useStyles();
	const { proposalsActions } = proposals;
	const { params } = match;
	const token = params?.token;
	const proposalId = params?.proposalId;
	const manager = params?.manager;
	const userPermissions = getLSField('userPermissions');
	const userPerms = userPermissions && JSON.parse(userPermissions);

	useEffect(() => {
		submitProposalApprove(token, Number(proposalId), manager);
		setNotificationAsRead(manager, notificationPayload);
	}, []);

	useEffect(() => {
		if (proposalsActions && userPerms) {
			if (
				userPerms.some(
					(e: IUserPermission) =>
						e.name.toUpperCase() === Roles.CP_ROLE_TALENT_ACQUISITION ||
						e.name.toUpperCase() === Roles.CP_ROLE_TALENT_ACQUISITION_ADMIN ||
						e.name.toUpperCase() === Roles.CP_ROLE_SYSTEM_ADMIN
				)
			) {
				goToPage(`/admin/recruitment/proposals/${token}`);
			} else {
				goToPage('/profile/personalFiscal');
			}
		}
	}, [proposalsActions]);

	return <LoadingOverlay />;
};

export default withLayout(ApproveProposal);
