import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import {
	getAdmissionManagementData,
	sendAdmission,
	resendAdmission,
	cancelAdmission,
	clearNewEmployeeForm,
	clearAdmissionsError
} from 'store/administration/admissions/thunks';
import { setManageAdmissionsPageFilter } from 'store/filters/actions';

import AdmissionManagement from './AdmissionManagement';

const mapStateToProps = ({ admissions, filters }: AppState) => ({ admissions, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getAdmissionManagementData,
			sendAdmission,
			resendAdmission,
			cancelAdmission,
			clearNewEmployeeForm,
			clearAdmissionsError,
			setManageAdmissionsPageFilter
		},
		dispatch
	);

export type AdmissionManagementProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionManagement);
