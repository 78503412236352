/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Card, Divider, Button } from '@mui/material';
import { Phone, Email } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { filterOnlySelected } from 'xpand-ui/utils/sort';

//* TYPINGS
import { HelperProps } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';

// common size for the cards on each resolution size
export const cardSizes = {
	xs: 6,
	sm: 6,
	md: 4,
	lg: 3,
	xl: 2
};

export const capitalizeWord = (word: string): string =>
	(word &&
		word
			.toLowerCase()
			.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase())
			.replace('Ux', 'UX')
			.replace('Pt', 'PT')
			.replace('It', 'IT')
			.replace('Hub', 'HUB')
			.replace('Do', 'do')) ||
	'';

/**
 ** Helper Method to filter the employees list by company, bu, div, dep, starting letter and custom search
 * @param allEmployees
 * @param filter
 * @param currentLetter
 * @param searchValue
 * @returns list of filtered employees
 */
export const filterData = (
	allEmployees: any,
	filter: HelperProps,
	currentLetter: string,
	searchValue: string
): any[] => {
	if (!allEmployees) return [];

	let data = [...allEmployees.users];

	//* get company selected in tree
	const copSelected =
		(filter.company && allEmployees.companies.find((e: any) => e.searchKey === filter.company)) || null;
	const filterCompany = copSelected?.name || null;

	//* get BusinessUnit selected in tree
	const buSelected =
		(copSelected &&
			filter.businessUnit &&
			copSelected.companyTree.childNodes.find((e: any) => e.organization === filter.businessUnit)) ||
		null;

	//* get Division selected in tree
	const divSelected =
		(buSelected && filter.division && buSelected.childNodes.find((e: any) => e.organization === filter.division)) ||
		null;

	//* get Department selected in tree
	const depSelected =
		(divSelected &&
			filter.department &&
			divSelected.childNodes.find((e: any) => e.organization === filter.department)) ||
		null;

	// ? filter by company
	if (filter.company && !filter.businessUnit && !filter.division && !filter.department) {
		// Option to filter all companies whenver the * option is selected
		if (filter.company !== '*') {
			data = data.filter(e => {
				if (!e.summaryData || !e.summaryData.company) return false;

				return e.summaryData.company === filterCompany;
			});
		}
	}

	// ? filter by businessUnit
	if (filter.company && filter.businessUnit && !filter.division && !filter.department) {
		data = data.filter(e => {
			if (!e.summaryData || !e.summaryData.businessUnit || !buSelected) return false;
			return (
				e.summaryData.businessUnit.toLowerCase().includes(buSelected.organization$_identifier.toLowerCase()) &&
				e.summaryData.company === filterCompany
			);
		});
	}

	// ? filter by division
	if (filter.company && filter.businessUnit && filter.division && !filter.department) {
		data = data.filter(e => {
			if (!e.summaryData || !e.summaryData.division || !divSelected) return false;
			return (
				e.summaryData.division.toLowerCase().includes(divSelected.organization$_identifier.toLowerCase()) &&
				e.summaryData.company === filterCompany &&
				e.summaryData.businessUnit.toLowerCase().includes(buSelected.organization$_identifier.toLowerCase())
			);
		});
	}

	// ? filter by department
	if (filter.company && filter.businessUnit && filter.division && filter.department) {
		data = data.filter(e => {
			if (!e.summaryData || !e.summaryData.department || !depSelected) return false;
			return (
				e.summaryData.department.toLowerCase().includes(depSelected.organization$_identifier.toLowerCase()) &&
				e.summaryData.division.toLowerCase().includes(divSelected.organization$_identifier.toLowerCase()) &&
				e.summaryData.company === filterCompany &&
				e.summaryData.businessUnit.toLowerCase().includes(buSelected.organization$_identifier.toLowerCase())
			);
		});
	}

	// ? filter by location
	if (filter.location) {
		data = data.filter(e => {
			if (!e.summaryData || !e.summaryData.office || !e.summaryData.office) return false;
			return e.summaryData.office.toLowerCase().includes((filter.location as string).toLowerCase());
		});
	}

	// ? filter by letter
	if (currentLetter !== 'All') {
		data = data.filter(e => e.name.startsWith(currentLetter));
	}

	// ? filter by search field
	if (searchValue) {
		data = filterOnlySelected(data, searchValue, ['username', 'name', 'company']);
	}

	return data;
};

export const CardsContainer: FC<any> = ({ children }) => {
	const classes = useStyles();
	return (
		<Grid
			container
			className={classes.root}
			spacing={2}
			direction="row"
			justifyContent="flex-start"
			alignContent="space-around"
			alignItems="flex-start">
			{children}
		</Grid>
	);
};
export const UserCards: FC<any> = ({ user, accentCard }) => {
	const classes = useStyles();
	const { id, name, username, summaryData } = user;

	return (
		<Grid key={id} component="div" item {...cardSizes} className={classes.cardGridItem}>
			<Card className={clsx(classes.cardBase, { [classes.accentCard]: accentCard === username })}>
				{/* //* Photo + name + username */}
				<span className={classes.firstLine}>
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>
					</div>
					<span>
						<span className={classes.nameAndUsernameContainer}>
							<span
								className={classes.text16Semi}
								style={{
									margin: '0 5px 0 15px',
									...(accentCard === username && { color: '#3F80EF' })
								}}>
								{name}
							</span>
							<span
								className={classes.text16Reg}
								style={{ ...(accentCard === username && { color: '#3F80EF' }) }}>
								({username})
							</span>
						</span>
						<span>
							<span className={classes.jobRole}>{capitalizeWord(summaryData?.jobRole || '')}</span>
						</span>
					</span>
				</span>
				{/* //* department + division + office */}
				<span className={classes.secondLine}>
					<Typography className={classes.text14Semi}>
						{capitalizeWord(summaryData?.department || summaryData?.businessUnit || '')}
					</Typography>
					<Typography className={classes.text14Reg}>{capitalizeWord(summaryData?.division || '')}</Typography>
					<Typography className={classes.text14Reg}>{capitalizeWord(summaryData?.office || '')}</Typography>
				</span>
				<Divider />
				{/* //* Phone + email */}
				<span className={classes.thirdLine}>
					<Typography className={classes.text14Semi} style={{ display: 'flex', paddingBottom: '5px' }}>
						<Phone color="yale" style={{ marginRight: '5px' }} /> {summaryData?.phone || ''}
					</Typography>
					<Button
						size="large"
						style={{ justifyContent: 'flex-start', paddingLeft: '5px', marginBottom: '5px' }}
						variant="text"
						fullWidth
						target="_top"
						color="primary"
						rel="noopener noreferrer"
						startIcon={<Email color="yale" style={{ marginRight: '5px' }} />}
						href={`mailto:${summaryData?.email}`}>
						<Typography className={classes.text14Semi} variant="body1">
							{summaryData?.email || ''}
						</Typography>
					</Button>
				</span>
			</Card>
		</Grid>
	);
};
