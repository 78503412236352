/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { StarBorder, CancelOutlined, HourglassEmpty, Check, Clear, ExitToAppOutlined } from '@mui/icons-material';

export const CHECK_ADMISSION_DATA_TYPES = {
	SUBMISSION_INFO: 'submissionInfo',
	VALIDATE_SUBMISSION: 'validateSubmission'
};

export const AVAILABLE_STATUS = {
	NEW: { id: 0, listPosition: 1 },
	APPROVED: { id: 1, listPosition: 3 },
	WAITING_APPROVAL: { id: 2, listPosition: 2 },
	CANCELED: { id: 3, listPosition: 6 },
	REJECTED: { id: 4, listPosition: 4 },
	CLOSED: { id: 6, listPosition: 5 },
	PENDING: { id: 7, listPosition: 0 }
};

export const AVAILABLE_ACTIONS = {
	SEND: { id: 0, label: 'send' },
	RESEND: { id: 1, label: 'resend' },
	SUBMISSION_INFO: { id: 2, label: null },
	CREATE_EMPLOYEE: { id: 3, label: null },
	VALIDATE_SUBMISSION: { id: 4, label: null },
	CANCEL: { id: 5, label: 'cancel' },
	EMPLOYEE_DATA: { id: 6, label: null }
};

// ADMISSION MANAGEMENT STATUS HELPER
export const admissionStatus = (classes: Record<string, string>): any[] => [
	{
		id: AVAILABLE_STATUS.PENDING.id,
		listPosition: AVAILABLE_STATUS.PENDING.listPosition,
		icon: <HourglassEmpty color="primary" className={classes.statusIcon} />,
		name: 'pending',
		label: 'PENDING'
	},
	{
		id: AVAILABLE_STATUS.NEW.id,
		listPosition: AVAILABLE_STATUS.NEW.listPosition,
		icon: <StarBorder color="primary" className={classes.statusIcon} />,
		name: 'new',
		label: 'NEW'
	},
	{
		id: AVAILABLE_STATUS.WAITING_APPROVAL.id,
		listPosition: AVAILABLE_STATUS.WAITING_APPROVAL.listPosition,
		icon: <HourglassEmpty color="primary" className={classes.statusIcon} />,
		name: 'awaiting',
		label: 'AWAITING APPROVAL'
	},
	{
		id: AVAILABLE_STATUS.APPROVED.id,
		listPosition: AVAILABLE_STATUS.APPROVED.listPosition,
		icon: <Check color="primary" className={classes.statusIcon} />,
		name: 'approved',
		label: 'APPROVED'
	},
	{
		id: AVAILABLE_STATUS.REJECTED.id,
		listPosition: AVAILABLE_STATUS.REJECTED.listPosition,
		icon: <ExitToAppOutlined color="primary" className={classes.statusIcon} />,
		name: 'rejected',
		label: 'REJECTED'
	},
	{
		id: AVAILABLE_STATUS.CLOSED.id,
		listPosition: AVAILABLE_STATUS.CLOSED.listPosition,
		icon: <Clear color="primary" className={classes.statusIcon} />,
		name: 'closed',
		label: 'CLOSED'
	},
	{
		id: AVAILABLE_STATUS.CANCELED.id,
		listPosition: AVAILABLE_STATUS.CANCELED.listPosition,
		icon: <CancelOutlined color="primary" className={classes.statusIcon} />,
		name: 'canceled',
		label: 'CANCELED'
	}
];
