import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	navBar: {
		marginTop: '5px',
		width: '100%',
		background: '#F8F9FC', //
		borderRadius: 10,
		'& .mbsc-calendar-title': { fontSize: '24px' }
	},
	calendarNavs: { fontSize: '22px' },
	displayFlex: {
		display: 'flex',
		width: 'max-content'
	},
	filterButton: {
		borderRadius: 15,
		background: 'white',
		color: 'black',
		alignSelf: 'center',
		padding: '9px 35px 9px 35px',
		fontSize: '13px',
		margin: 'auto',
		'&:hover': {
			color: 'white',
			background: theme.accentColor
		}
	},
	newEntryButton: {
		borderRadius: 15,
		background: '#BCC3D6 0% 0% no-repeat padding-box',
		padding: '9px 35px 9px 35px',
		fontSize: '13px',
		color: 'white',
		alignSelf: 'center',
		border: 'solid 1px #BCC3D6',
		'&:hover': {
			color: 'white',
			background: theme.accentColor
		}
	},
	headerActions: { marginTop: '3px' },
	footerStatus: {
		position: 'sticky',
		bottom: '0',
		left: '0',
		backgroundColor: 'white',
		paddingLeft: '30px',
		paddingRight: '30px',
		paddingBottom: '10px',
		paddingTop: '20px',
		zIndex: '1'
	},
	selectedBadge: {
		paddingRight: '1px',
		marginTop: '-7px'
	},
	absencesDetailLink: { paddingLeft: '30px' }
}));

export { useStyles };
