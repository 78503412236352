import { action } from 'typesafe-actions';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import {
	AdmissionsActions as Actions,
	IAdmissionDetails,
	IAdmissions,
	IAdmissionSheetFormData,
	ICreateEmployeeData,
	INewAdmissionData,
	IPostAdmissionResponse,
	IResignFormGet,
	ISubmissionInfo,
	IUsersResignationList
} from 'typings/store/admin/admissions';
import { IDispatchType } from 'typings/store/storeTypes';
import { IProposalSummary } from 'typings/store/admin/proposals';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setAdmissionsError = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_ERROR, payload);

export const setNewAdmission = (payload: INewAdmissionData | []): IDispatchType =>
	action(Actions.GET_NEW_ADMISSION, payload);

export const setPostNewAdmission = (payload: OriginalResponse | Success | null): IDispatchType =>
	action(Actions.POST_NEW_ADMISSION, payload);

export const setAdmissionSheet = (payload: IAdmissionSheetFormData | null): IDispatchType =>
	action(Actions.GET_ADMISSION_SHEET, payload);

export const setPostAdmissionSheet = (payload: IPostAdmissionResponse | null): IDispatchType =>
	action(Actions.POST_ADMISSION_SHEET, payload);

export const setInvalidDocuments = (payload: string[]): IDispatchType => action(Actions.SET_INVALID_DOCS, payload);

export const setBlockAdmissionSheetView = (payload: boolean): IDispatchType =>
	action(Actions.SET_BLOCK_ADMISSION_SHEET, payload);

export const setAdmissionManagement = (payload: IAdmissions | null): IDispatchType =>
	action(Actions.GET_ADMISSION_MANAGEMENT, payload);

export const setAdmissionDetails = (payload: IAdmissionDetails | null): IDispatchType =>
	action(Actions.GET_ADMISSION_DETAILS, payload);

export const setEmployeeData = (payload: IProposalSummary | null): IDispatchType =>
	action(Actions.GET_EMPLOYEE_DATA, payload);

export const setSubmissionInfo = (payload: ISubmissionInfo | null): IDispatchType =>
	action(Actions.GET_SUBMISSION_INFO, payload);

export const setPostSendAdmission = (payload: OriginalResponse | Success | null): IDispatchType =>
	action(Actions.POST_SEND_ADMISSION, payload);

export const setPostResendAdmission = (payload: OriginalResponse | Success | null): IDispatchType =>
	action(Actions.POST_RESEND_ADMISSION, payload);

export const setPostCancelAdmission = (payload: OriginalResponse | Success | null): IDispatchType =>
	action(Actions.POST_CANCEL_ADMISSION, payload);

export const setPostApproveAdmission = (payload: unknown): IDispatchType =>
	action(Actions.POST_APPROVE_ADMISSION, payload);

export const setPostInvalidateAdmission = (payload: OriginalResponse | Success | null): IDispatchType =>
	action(Actions.POST_INVALIDATE_ADMISSION, payload);

export const setGetFile = (payload: unknown): IDispatchType => action(Actions.GET_FILE, payload);

export const setDeleteFile = (payload: unknown): IDispatchType => action(Actions.DELETE_FILE, payload);

export const setCreateEmployee = (payload: ICreateEmployeeData | OriginalResponse | null): IDispatchType =>
	action(Actions.GET_CREATE_EMPLOYEE, payload);

export const setPostCreateEmployee = (payload: OriginalResponse | Success | null): IDispatchType =>
	action(Actions.SUBMIT_CREATE_EMPLOYEE, payload);

export const setClearNewEmployeeForm = (): IDispatchType => action(Actions.CLEAR_CREATE_EMPLOYEE_FORM);

export const setClearEmployeeData = (): IDispatchType => action(Actions.CLEAR_EMPLOYEE_DATA);

// 	RESIGNATION
export const setResignationList = (payload: IUsersResignationList | null): IDispatchType =>
	action(Actions.GET_USERS_RESIGNATION, payload);

export const setResignationForm = (payload: IResignFormGet | null): IDispatchType =>
	action(Actions.GET_RESIGNATION_FORM, payload);

export const setResignationFormSubmit = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.GET_RESIGNATION_FORM_SUBMIT, payload);

export const setDeactivateForm = (payload: IResignFormGet | null): IDispatchType =>
	action(Actions.GET_DEACTIVATE_FORM, payload);

export const setDeactivateFormSubmit = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.GET_DEACTIVATE_FORM_SUBMIT, payload);

export const setResginAction = (): IDispatchType => action(Actions.RESET_RESIGN_ACTION);

export const setExportCertificate = (payload: unknown): IDispatchType =>
	action(Actions.GET_EXPORT_CERTIFICATE, payload);

export const setExportReceipt = (payload: unknown): IDispatchType => action(Actions.GET_EXPORT_RECEIPT, payload);
