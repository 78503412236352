/* eslint-disable @typescript-eslint/no-explicit-any */
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import { IChoosableBaseInfo } from '../generalTypes';
import { ICollaborationProposal, IProposalSummary, ICompanyInfo } from './proposals';
import { IUsersResignationList, IResignFormGet, IDeactivateFormGet } from './resignations';

export * from './resignations';

export enum AdmissionsActions {
	SET_LOADING = '@@administration/admissions/SET_LOADING',
	SET_ERROR = '@@personalInfo/SET_ERROR',
	GET_NEW_ADMISSION = '@@administration/admissions/GET_NEW_ADMISSION',
	POST_NEW_ADMISSION = '@@administration/admissions/POST_NEW_ADMISSION',
	GET_ADMISSION_SHEET = '@@administration/admissions/GET_ADMISSION_SHEET',
	POST_ADMISSION_SHEET = '@@administration/admissions/POST_ADMISSION_SHEET',
	SET_INVALID_DOCS = '@@administration/admissions/SET_INVALID_DOCS',
	SET_BLOCK_ADMISSION_SHEET = '@@administration/admissions/SET_BLOCK_ADMISSION_SHEET',
	GET_ADMISSION_MANAGEMENT = '@@administration/admissions/GET_ADMISSION_MANAGEMENT',
	GET_ADMISSION_DETAILS = '@@administration/admissions/GET_ADMISSION_DETAILS',
	GET_EMPLOYEE_DATA = '@@administration/admissions/GET_EMPLOYEE_DATA',
	// GET_ADMISSION_VALIDATION = '@@administration/admissions/GET_ADMISSION_VALIDATION',
	GET_SUBMISSION_INFO = '@@administration/admissions/GET_SUBMISSION_INFO',
	POST_SEND_ADMISSION = '@@administration/admissions/POST_SEND_ADMISSION',
	POST_RESEND_ADMISSION = '@@administration/admissions/POST_RESEND_ADMISSION',
	POST_CANCEL_ADMISSION = '@@administration/admissions/POST_CANCEL_ADMISSION',
	POST_APPROVE_ADMISSION = '@@administration/admissions/POST_APPROVE_ADMISSION',
	POST_INVALIDATE_ADMISSION = '@@administration/admissions/POST_APPROVE_INVALIDATION',
	GET_FILE = '@@administration/admissions/GET_FILE',
	DELETE_FILE = '@@administration/admissions/DELETE_FILE',
	CLEAR_EMPLOYEE_DATA = '@@administration/admissions/CLEAR_EMPLOYEE_DATA',
	//	CREATE EMPLOYEE
	// Admission Management - Create Employee GET
	GET_CREATE_EMPLOYEE = '@@administration/admissions/GET_CREATE_EMPLOYEE',
	// Admission Management - Create Employee POST
	SUBMIT_CREATE_EMPLOYEE = '@@administration/admissions/SUBMIT_CREATE_EMPLOYEE',
	CLEAR_CREATE_EMPLOYEE_FORM = '@@administration/admissions/CLEAR_CREATE_EMPLOYEE_FORM',
	// 	RESIGNATION
	GET_USERS_RESIGNATION = '@@administration/admissions/GET_USERS_RESIGNATION',
	GET_RESIGNATION_FORM = '@@administration/admissions/GET_RESIGNATION_FORM',
	GET_RESIGNATION_FORM_SUBMIT = '@@administration/admissions/GET_RESIGNATION_FORM_SUBMIT',
	GET_DEACTIVATE_FORM = '@@administration/admissions/GET_DEACTIVATE_FORM',
	GET_DEACTIVATE_FORM_SUBMIT = '@@administration/admissions/GET_DEACTIVATE_FORM_SUBMIT',
	RESET_RESIGN_ACTION = '@@administration/admissions/RESET_RESIGN_ACTION',
	GET_EXPORT_CERTIFICATE = '@@administration/admissions/GET_EXPORT_CERTIFICATE',
	GET_EXPORT_RECEIPT = '@@administration/admissions/GET_EXPORT_RECEIPT'
}

export type AdmissionsState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly newAdmission: INewAdmissionData | null;
	readonly admissionSheetData: IAdmissionSheetData | null;
	readonly newAdmissionSheet: IPostAdmissionResponse | null;
	readonly invalidDocs: string[];
	readonly blockAdmissionSheet: boolean;
	readonly admissionManagementData: IAdmissionManagementData | null;
	readonly userAdmissionDetailsData: IAdmissionDetails | null;
	readonly employeeDataResult: IEmployeeData | null;
	readonly sentAdmissionResult: OriginalResponse | Success | null;
	readonly canceledAdmissionResult: OriginalResponse | Success | null;
	readonly admissionApprovalResult: unknown;
	readonly admissionInvalidationResult: OriginalResponse | Success | null;
	readonly fetchFileResult: unknown;
	readonly deleteFileResult: unknown;
	// readonly submissionInfoData: null;
	// create employee
	readonly newEmployee: ICreateEmployeeData | OriginalResponse | null;
	readonly admissionAction: OriginalResponse | Success | null;
	// 	RESIGNATION
	readonly resignationList: IUsersResignationList | null;
	readonly resignationForm: IResignFormGet | null;
	readonly resignationAction: OriginalResponse | null;
	readonly deactivateForm: IDeactivateFormGet | null;
	readonly deactivateAction: OriginalResponse | null;
	readonly exportCertificate: unknown | null;
	readonly exportReceipt: unknown | null;
};

export interface IAdmissionManagementData {
	admissions: IAdmission[];
}

export interface IAdmissionFoundStatus {
	icon: unknown;
	id: number;
	label: string;
	listPosition: number;
	name: string;
}

export interface IAdmissionAction {
	id: number;
	label: string;
}

export interface IAdmission {
	actions?: unknown;
	admissionDate: string;
	admissionDateFormated?: string;
	admissionLastUpdateDate: string;
	admissionLastUpdateDateFormated?: string;
	admissionStartDate: string;
	admissionStartDateFormated?: string;
	admissionStatus: number;
	company: string;
	email: string;
	employeeName: string;
	files?: Ifile[];
	foundStatus?: IAdmissionFoundStatus;
	fromEmploymentCenter: boolean;
	id: number;
	token: string;
	status?: string;
	created?: unknown;
}

export interface IAdmissionPersonalInfo {
	employeeName: string;
	genderName: string;
	dateOfBirth: string;
	idDocumentTypeName: string;
	citizenCardNumber: string;
	citizenCardValidityDate: string;
	fiscalNumber: string;
	socialSecurityNumber: string;
	licensePlate: string;
	hasHadWithoutTermContract: boolean;
	ageOnContractStartDate: string;
}

export interface IAdmissionContactInfo {
	address: string;
	city: string;
	zipCode: string;
	email: string;
	mobilePhone: string;
	nationality: string;
	emergencyContact: string;
	emergencyContactName: string;
	placeOfBirth: string;
	maritalStatus: string;
	holdersNumber?: string | number;
	dependentsNumber: string | number;
	taxZone: string;
}

export interface IAdmissionAcademicInfo {
	courseQualificationIdName: string;
	coursePostBologna: boolean;
	educationStatus: string;
	educationCodeName: string;
	courseName: string;
	courseInstitution: string;
	courseStartDate: string;
	courseEndDate: string;
}

export interface IAdmissionBankInfo {
	bankName: string;
	bankNib: string;
}

export interface IAdmissionPersonalSummaryInfo {
	careerStartDate: string;
	professionalExperience: string;
}

export interface IAdmissionGDPRInfo {
	biometricSystemsConsent: boolean;
	intranetSystemsConsent: boolean;
	promotionalMediaConsent: boolean;
}

export interface IAdmissionSheetPayload
	extends IAdmissionContactInfo,
		IAdmissionBankInfo,
		IAdmissionPersonalSummaryInfo,
		IAdmissionAcademicInfo,
		IAdmissionGDPRInfo {
	disabled: boolean;
	validatePlate: boolean;
	showContractCheckbox: boolean;
	admissionProcessId: number | null;
	bankId: string;
	citizenCardNumber: string;
	allCitizenCardNumber: string[] | undefined;
	citizenCardValidityDate: string;
	company: string | null;
	courseQualificationId: string;
	docType?: string;
	dateOfBirth: string;
	educationCode: string;
	bankIdName: string;
	allFiscalNumber: string[] | undefined;
	fiscalNumber: string;
	fullName: string;
	genderName: string;
	id: number | null;
	idDocumentType: string;
	idDocumentTypeName: string;
	licensePlate: string;
	nationalityName: string;
	socialSecurityNumber: string;
	allSocialSecurityNumber: string[] | undefined;
	pensionFundNumber: string;
	allPensionFundNumber: string[] | undefined;
	hasHadWithoutTermContract: boolean;
	ageOnContractStartDate: string;
	idFile?: File;
	qcFile?: File;
	nibFile?: File;
	gender: string;
	hasIDDoc: string;
	hasBankDoc: string;
}

// type of user identification file
export interface Ifile {
	fileType: string;
	id: number;
	name: string;
}
// structure of uploaded file
export interface IUploadFile {
	file: File | null;
	name: string | null;
}

export interface IAdmissions {
	data?: {
		admissions: IAdmission[];
	};
}

export interface ISubmissionInfo {
	data?: {
		admissions: IAdmission[];
		sheet: IAdmissionSheetPayload;
	};
}

export interface INewAdmissionData {
	companies: ICompanyInfo[];
	emails: string[];
}
export interface INewAdmissionPayload {
	collaborationProposalId: string;
	company: string;
	email: string;
	emails?: string[];
	fromEmploymentCenter: boolean;
	name: string;
}

export interface IBankInfo {
	id: string;
	name: string;
	swiftCode: string;
}
export interface IAdmissionSheetData {
	[index: string]: {
		banks: IBankInfo[];
		courseQualifications: IChoosableBaseInfo[];
		educationCodes: IChoosableBaseInfo[];
		educationStatus: IChoosableBaseInfo[];
		idDocumentTypes: any[];
		maritalStatus: IChoosableBaseInfo[];
		nationalities: IChoosableBaseInfo[];
		process: IAdmission;
		sheet: IAdmissionSheetPayload;
		associatedProposal?: ICollaborationProposal;
		contractCheckboxMaximumDate: string;
		allCC?: string[];
		allNifs?: string[];
		allSSN?: string[];
		allPFN?: string[];
	};
}

export interface IAdmissionSheetFormData {
	token?: string;
	data?: IAdmissionSheetData;
}
export interface IAdmissionDetails {
	admission: IAdmission;
	sheet: IAdmissionSheetPayload;
}
export interface IInvalidateAdmission {
	errorDetails: string;
}
export interface IIdDocs {
	name: string;
	value: string;
}

export interface IPostAdmissionResponse {
	token: string;
	response: OriginalResponse | Success;
}

export interface ICompanyUsers {
	email: string;
	employeeId: string;
	firstName: string;
	lastName: string;
	username: string;
}

export interface ICreateEmployeeData {
	code?: number;
	proposal?: ICollaborationProposal;
	admission: IAdmission;
	collaborationRoles: IChoosableBaseInfo[];
	companies: ICompanyInfo[];
	contractTypes: IChoosableBaseInfo[];
	foodAllowanceTypes: IChoosableBaseInfo[];
	holidayTypes: IChoosableBaseInfo[];
	maritalStatus: IChoosableBaseInfo[];
	previousFreeReferences: string[];
	primaryWorkPlaces: IChoosableBaseInfo[];
	prizeBonusGoalTypes: IChoosableBaseInfo[];
	projects: IChoosableBaseInfo[];
	referralTypes: IChoosableBaseInfo[];
	sheet: IAdmissionSheetPayload;
	timeOffApprovalTypes: IChoosableBaseInfo[];
	timeOffPolicies: IChoosableBaseInfo[];
	users: ICompanyUsers[];
	workPlaces: IChoosableBaseInfo[];
	xtrackerEmployees: IChoosableBaseInfo[];
	contactListLevels: IChoosableBaseInfo[];
	defaultProjects: Record<string, string[]>;
	defaultDivisionProjects: Record<string, string[]>;
}
export interface IEmployeeData {
	prizeBonusGoalTypes: IChoosableBaseInfo[];
	proposal: ICollaborationProposal;
	summary: IProposalSummary;
}
