/* eslint-disable func-names */
import * as yup from 'yup';

import { PROPOSAL } from 'pages/Admin/_ProposalsFormUtils/constants';
import { parseDateToShow } from 'xpand-ui/utils/dates';

import {
	string,
	stringRequired,
	stringLongRequired,
	number,
	numberPositiveRequired,
	numberRequired,
	boolean,
	dateDefault,
	dateRequired,
	numberPositive
} from 'lib/utils/formUtils';
import { IPrizeGoal } from 'typings/store/admin/proposals';
import {
	BALWURK_EMPLOYEE_ID,
	INSTANCE,
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_HR,
	XPAND_EMPLOYEE_ID_UK,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_DE
} from 'lib/utils/constants';
import { store } from '../../../../store/company/reducer';

const state = store.getState();

const employeeCategoryCompare = INSTANCE.toLowerCase().includes('xpand')
	? [XPAND_EMPLOYEE_ID, XPAND_EMPLOYEE_ID_HR, XPAND_EMPLOYEE_ID_UK, XPAND_EMPLOYEE_ID_SE, XPAND_EMPLOYEE_ID_DE]
	: [BALWURK_EMPLOYEE_ID];
// -------------------------------    PT    -------------------------------

export const createYupSchema = (companyCode: string) => {
	let schema;
	if (companyCode === 'PT') {
		const general = {
			// GENERAL INFORMATION - left
			collaborationType: stringRequired,
			name: stringRequired,
			email: stringRequired.email('Must be a valid email'),
			jiraCandidateApplication: string,
			startDate: dateRequired.min(
				new Date().toISOString(),

				({ min }) => `Collaboration start date must be after ${parseDateToShow(min as string)}`
			),
			// GENERAL INFORMATION - right
			company: stringRequired,
			businessUnit: stringRequired,
			division: stringRequired,
			department: stringRequired,
			collaborationRole: stringRequired,
			employeeCategory: stringRequired
		};

		const careerFinancial = {
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: boolean,
			careerPosition: stringRequired,
			careerJobRole: stringRequired,
			careerManager: stringRequired,
			careerJobRoleLevel: stringRequired,
			// CAREER & FINANCIAL - TAXES
			holdersNumber: numberRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberRequired,
				otherwise: number
			}),
			disabled: boolean,
			maritalStatus: stringRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: stringRequired,
				otherwise: string
			}),
			tsuExemption: boolean,
			dependentsNumber: numberRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberRequired,
				otherwise: number
			}),
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: number.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberPositiveRequired,
				otherwise: number
			}),
			irsToApply: numberRequired, // readOnly
			ihtValue: numberRequired, // readOnly
			ihtPercentage: number,
			annualGrossValue: number,
			hasTwelfths: boolean,
			vacationsTwelfth: numberRequired, // readOnly
			christmasTwelfth: numberRequired, // readOnly
			grossBaseValue: numberRequired, // readOnly
			totalTaxableValue: numberRequired, // readOnly
			foodAllowanceType: stringRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: stringRequired,
				otherwise: string
			}),
			foodAllowance: numberRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberRequired,
				otherwise: number
			}),
			foodAllowanceDays: numberRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberRequired,
				otherwise: number
			}),
			nationalAllowanceDays: number,
			nationalAllowance: number.when('nationalAllowanceDays', {
				is: (val: number) => val > 0,
				then: numberRequired,
				otherwise: number
			}),
			internationalAllowanceDays: number,
			internationalAllowance: number.when('internationalAllowanceDays', {
				is: (val: number) => val > 0,
				then: numberRequired,
				otherwise: number
			}),
			travelPass: number,
			additionalExpenses: number,
			nurseryCheck: number,
			lifeInsurance: number,
			hourlyRate: number.when('employeeCategory', {
				is: (val: string) => !employeeCategoryCompare.includes(val),
				then: numberPositiveRequired,
				otherwise: number
			}),
			budget: number.when('employeeCategory', {
				is: (val: string) => !employeeCategoryCompare.includes(val),
				then: numberPositiveRequired,
				otherwise: number
			}),
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: boolean,
			prizeBonus: number,
			prizeBonusPercentage: number,
			monthsApplicable: number,
			eligiblePrizeValue: number,
			prizeBonusGoals: yup.array().when('prizeBonus', {
				is: (val: number) => val > 0,
				then: yup
					.array()
					.of(
						yup.object().shape({
							id: string,
							goalDetails: stringLongRequired,
							goalTypeId: numberRequired,
							prizePercentage: numberPositiveRequired,
							prizeValue: numberPositiveRequired
						})
					)
					.min(1, 'Must have at least one prize goal when MAXIMUM PRIZE BONUS is set.')
					.test('not-100-percent', 'The sum of all prize goals must be 100%', (data): boolean => {
						if (data) {
							const totalPercentages = data.reduce(
								(total, current) =>
									current?.prizePercentage ? current.prizePercentage + total : total,
								0
							);
							// return true -> the error will NOT show
							// return false -> the error will show
							return totalPercentages === 100;
						}
						return true;
					})
					.test('not-empty-percentages', "Can't exist goals with no percentage value", data =>
						// return true -> the error will NOT show
						// return false -> the error will show
						data ? !data.map(e => e && Number(e.prizePercentage)).includes(0) : true
					),
				otherwise: yup.array()
			}),
			annualPrizeIRS: stringRequired,
			totalComponentsPrizePercentage: stringRequired,
			totalComponentsPrizeValue: stringRequired,
			taxZone: string
		};
		const otherBenefits = {
			commsPlafondFlag: boolean,
			commsPlafond: number.when('commsPlafondFlag', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			healthInsuranceCheck: boolean,
			healthInsuranceValue: number.when('healthInsuranceCheck', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			includeFamilyMembers: boolean,
			numberMembersDep: number.when('healthInsuranceCheck', {
				is: true,
				then: numberRequired,
				otherwise: number
			}),
			familyMembersComments: string,
			otherbenefits: boolean,
			otherBenefitsList: yup.array().of(
				yup.object().shape({
					id: string,
					goalDetails: stringLongRequired,
					prizeValue: numberPositiveRequired
				})
			),
			benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag', 'employeeCategory'], {
				is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean, employeeCategory: string) =>
					employeeCategoryCompare.includes(employeeCategory) && (!healthInsuranceCheck || !commsPlafondFlag),
				then: stringRequired,
				otherwise: string
			})
		};
		const contractCompany = {
			contractType: string.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: stringRequired,
				otherwise: string
			}),
			contractPosition: string.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: stringRequired,
				otherwise: string
			}),
			customerInContract: string.when('contractType', {
				is: (value: string) =>
					// 20 - fixed term
					value === '20',
				then: stringRequired,
				otherwise: string
			}),
			traineeType: string.when('contractType', {
				is: (value: string) =>
					// 80 - trainee
					value === '80',
				then: stringRequired,
				otherwise: string
			}),
			fixedTermContractDuration: string.when('contractType', {
				is: (value: string) =>
					// 20 - fixed term
					// 80 - trainee
					value === '20' || value === '80',
				then: stringRequired,
				otherwise: string
			}),
			endDate: dateDefault,
			primaryOffice: stringRequired,
			workingOffice: stringRequired,
			employeeManager: stringRequired,
			holidayType: stringRequired,
			referralType: stringRequired,
			reference: string.when('referralType', {
				is: (value: string) => value === 'Employee',
				then: stringRequired,
				otherwise: string
			}),
			referralId: string
		};
		const projects = {
			projects: yup.array().of(
				yup.object().shape({
					projectId: stringRequired,
					projectName: stringRequired
				})
			)
		};

		schema = yup.object().shape({
			// PROPOSAL DB INFO
			proposalId: yup.string(),
			token: yup.string(),
			// GENERAL INFORMATION
			...general,
			// CAREER & FINANCIAL
			...careerFinancial,
			// OTHER BENEFITS
			...otherBenefits,
			// CONTRACT & COMPANY
			...contractCompany,
			// PROJECTS
			...projects
		});
	} else if (companyCode === 'HR') {
		const general = {
			// GENERAL INFORMATION - left
			collaborationType: stringRequired,
			name: stringRequired,
			email: stringRequired.email('Must be a valid email'),
			jiraCandidateApplication: string,
			startDate: dateRequired.min(
				new Date().toISOString(),

				({ min }) => `Collaboration start date must be after ${parseDateToShow(min as string)}`
			),
			// GENERAL INFORMATION - right
			company: stringRequired,
			businessUnit: stringRequired,
			division: stringRequired,
			department: stringRequired,
			collaborationRole: stringRequired,
			employeeCategory: stringRequired
		};

		const careerFinancial = {
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: boolean,
			careerPosition: stringRequired,
			careerJobRole: stringRequired,
			careerManager: stringRequired,
			// CAREER & FINANCIAL - TAXES
			holdersNumber: numberRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberRequired,
				otherwise: number
			}),
			maritalStatus: stringRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: stringRequired,
				otherwise: string
			}),
			dependentsNumber: numberRequired.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberRequired,
				otherwise: number
			}),
			surtaxZone: string,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: number.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: numberPositiveRequired,
				otherwise: number
			}),
			travelPass: number,
			additionalExpenses: number,
			nurseryCheck: number,
			lifeInsurance: number,
			hourlyRate: number.when('employeeCategory', {
				is: (val: string) => !employeeCategoryCompare.includes(val),
				then: numberPositiveRequired,
				otherwise: number
			}),
			budget: number.when('employeeCategory', {
				is: (val: string) => !employeeCategoryCompare.includes(val),
				then: numberPositiveRequired,
				otherwise: number
			})
		};

		const otherBenefits = {
			commsPlafondFlag: boolean,
			commsPlafond: number.when('commsPlafondFlag', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			healthInsuranceCheck: boolean,
			healthInsuranceValue: number.when('healthInsuranceCheck', {
				is: true,
				then: numberPositiveRequired,
				otherwise: number
			}),
			includeFamilyMembers: boolean,
			numberMembersDep: number.when('healthInsuranceCheck', {
				is: true,
				then: numberRequired,
				otherwise: number
			}),
			familyMembersComments: string,
			otherbenefits: boolean,
			otherBenefitsList: yup.array().of(
				yup.object().shape({
					id: string,
					goalDetails: stringLongRequired,
					prizeValue: numberPositiveRequired
				})
			),
			benefitsComments: string.when(['healthInsuranceCheck', 'commsPlafondFlag', 'employeeCategory'], {
				is: (healthInsuranceCheck: boolean, commsPlafondFlag: boolean, employeeCategory: string) =>
					employeeCategoryCompare.includes(employeeCategory) && (!healthInsuranceCheck || !commsPlafondFlag),
				then: stringRequired,
				otherwise: string
			})
		};
		const contractCompany = {
			contractType: string.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: stringRequired,
				otherwise: string
			}),
			contractPosition: string.when('employeeCategory', {
				is: (val: string) => employeeCategoryCompare.includes(val) || val === undefined,
				then: stringRequired,
				otherwise: string
			}),

			fixedTermContractDuration: string.when('contractType', {
				is: (value: string) =>
					// 20 - fixed term
					// 80 - trainee
					value === '20' || value === '80',
				then: stringRequired,
				otherwise: string
			}),
			endDate: dateDefault,
			primaryOffice: stringRequired,
			workingOffice: stringRequired,
			employeeManager: stringRequired,
			holidayType: stringRequired,
			referralType: stringRequired,
			reference: string.when('referralType', {
				is: (value: string) => value === 'Employee',
				then: stringRequired,
				otherwise: string
			}),
			referralId: string
		};
		const projects = {
			projects: yup.array().of(
				yup.object().shape({
					projectId: stringRequired,
					projectName: stringRequired
				})
			)
		};

		schema = yup.object().shape({
			// PROPOSAL DB INFO
			proposalId: yup.string(),
			token: yup.string(),
			// GENERAL INFORMATION
			...general,
			// CAREER & FINANCIAL
			...careerFinancial,
			// OTHER BENEFITS
			...otherBenefits,
			// CONTRACT & COMPANY
			...contractCompany,
			// PROJECTS
			...projects
		});
	}
	return schema;
};

export const createDefaultValues = (companyCode: string, referenceData: any) => {
	// PROPOSAL DB INFO
	if (companyCode === 'PT') {
		return {
			proposalId: '',
			token: undefined,
			// ------------------------------------------- GENERAL INFORMATION
			collaborationType: 'Collaboration Proposal',
			name: '',
			email: '',
			jiraCandidateApplication: '',
			startDate: '',
			//
			company: state.defaultCompanyKey,
			businessUnit: '',
			division: '',
			department: '',
			collaborationRole: 'EMPLOYEE',
			employeeCategory: INSTANCE.toLowerCase().includes('xpand') ? XPAND_EMPLOYEE_ID : BALWURK_EMPLOYEE_ID,
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - USER DATA
			// importFromAnotherProposal: false, // form helper
			importFromAnotherProposal: true, // form helper
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: false,
			careerPosition: '',
			careerJobRole: '',
			careerJobRoleLevel: '',
			careerManager: '',
			// CAREER & FINANCIAL - TAXES
			holdersNumber: '1',
			annualGrossValue: 0,
			disabled: false,
			maritalStatus: 'Single',
			tsuExemption: false,
			dependentsNumber: '0',
			taxZone: undefined,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: '0',
			hourlyRate: 0,
			budget: 0,

			payBonusSalary: false,
			bonusSalary: 0,
			irsToApply: '0',
			ihtValue: '0',
			ihtPercentage: '25.00',
			hasTwelfths: true,
			vacationsTwelfth: '0',
			christmasTwelfth: '0',
			grossBaseValue: '0',
			totalTaxableValue: '0',
			foodAllowanceType: 'Card',
			foodAllowance: referenceData?.xphrCrFoodAllowValue,
			foodAllowanceDays: '20',
			nationalAllowanceDays: '0',
			nationalAllowance: '0',
			internationalAllowanceDays: '0',
			internationalAllowance: '0',
			travelPass: '0',
			additionalExpenses: '0',
			nurseryCheck: '0',
			lifeInsurance: '0',
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: false,
			prizeBonus: '0',
			prizeBonusPercentage: 0,
			monthsApplicable: '12',
			eligiblePrizeValue: '0',
			prizeBonusGoals: [] as IPrizeGoal[],
			annualPrizeIRS: '0',
			totalComponentsPrizePercentage: '0',
			totalComponentsPrizeValue: '0',
			careerAndFinancialNotes: '',
			// OTHER BENEFITS
			commsPlafondFlag: true,
			commsPlafond: referenceData?.xphrMonStdComPlafond,
			healthInsuranceCheck: true,
			healthInsuranceValue: referenceData?.xphrMonStdHeaInsCost,
			includeFamilyMembers: false,
			numberMembersDep: '0',
			familyMembersComments: '',
			otherbenefits: true,
			otherBenefitsList: [{ goalDetails: 'Remote Work Allowance', prizeValue: 15 }],
			benefitsComments: '',
			// CONTRACT & COMPANY
			contractType: '',
			customerInContract: '',
			traineeType: '',
			fixedTermContractDuration: '',
			endDate: '',
			contractPosition: '',
			primaryOffice: '',
			workingOffice: '',
			employeeManager: '',
			holidayType: '',
			referralType: '',
			reference: '',
			referralId: '',
			// PROJECTS
			projects: []
		};
	} else if (companyCode === 'HR') {
		return {
			proposalId: '',
			token: undefined,
			// ------------------------------------------- GENERAL INFORMATION
			collaborationType: 'Collaboration Proposal',
			name: '',
			email: '',
			jiraCandidateApplication: '',
			startDate: '',
			//
			company: 'HR',
			businessUnit: '',
			division: '',
			department: '',
			collaborationRole: 'EMPLOYEE',
			employeeCategory: INSTANCE.toLowerCase().includes('xpand') ? XPAND_EMPLOYEE_ID_HR : BALWURK_EMPLOYEE_ID,
			// ------------------------------------------- CAREER & FINANCIAL
			// CAREER & FINANCIAL - USER DATA
			// importFromAnotherProposal: false, // form helper
			importFromAnotherProposal: true, // form helper
			// CAREER & FINANCIAL - CAREER INFO
			softwareDevelopmentCenter: false,
			careerPosition: '',
			careerJobRole: '',
			careerJobRoleLevel: '',
			careerManager: '',
			// CAREER & FINANCIAL - TAXES
			holdersNumber: '1',
			annualGrossValue: 0,
			disabled: false,
			maritalStatus: 'Single',
			tsuExemption: false,
			dependentsNumber: '0',
			taxZone: undefined,
			surtaxZone: undefined,
			// CAREER & FINANCIAL - GENERAL AMOUNTS
			baseValue: '0',
			hourlyRate: 0,
			budget: 0,
			payBonusSalary: true,
			bonusSalary: 0,
			irsToApply: '0',
			ihtValue: '0',
			ihtPercentage: '0',
			hasTwelfths: false,
			vacationsTwelfth: '0',
			christmasTwelfth: '0',
			grossBaseValue: '0',
			totalTaxableValue: '0',
			foodAllowanceType: 'Card',
			foodAllowance: '0',
			foodAllowanceDays: '0',
			nationalAllowanceDays: '0',
			nationalAllowance: '0',
			internationalAllowanceDays: '0',
			internationalAllowance: '0',
			travelPass: '0',
			additionalExpenses: '0',
			nurseryCheck: '0',
			lifeInsurance: '0',
			// CAREER & FINANCIAL - PRIZE BONUS
			createPrizeBonusErp: false,
			prizeBonus: '0',
			prizeBonusPercentage: 0,
			monthsApplicable: '12',
			eligiblePrizeValue: '0',
			prizeBonusGoals: [] as IPrizeGoal[],
			annualPrizeIRS: '0',
			totalComponentsPrizePercentage: '0',
			totalComponentsPrizeValue: '0',
			careerAndFinancialNotes: '',
			// OTHER BENEFITS
			commsPlafondFlag: true,
			commsPlafond: referenceData?.xphrMonStdComPlafond,
			healthInsuranceCheck: true,
			healthInsuranceValue: referenceData?.xphrMonStdHeaInsCost,
			includeFamilyMembers: false,
			numberMembersDep: '0',
			familyMembersComments: '',
			otherbenefits: true,
			otherBenefitsList: [{ goalDetails: 'Transportation Allowance', prizeValue: 33 }],
			benefitsComments: '',
			// CONTRACT & COMPANY
			contractType: '',
			customerInContract: '',
			traineeType: '',
			fixedTermContractDuration: '',
			endDate: '',
			contractPosition: '',
			primaryOffice: '',
			workingOffice: '',
			employeeManager: '',
			holidayType: '',
			referralType: '',
			reference: '',
			referralId: '',
			// PROJECTS
			projects: []
		};
	}
};
