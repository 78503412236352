/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, FC, useCallback, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Dialog } from 'xpand-ui/core';
import { Button, Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import { defaultValues, schema } from './yupSchema';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker, momentTimezone } from '@mobiscroll/react';
import moment from 'moment-timezone';
import '../MyTimeoff.css';
//* EXTERNAL LIBS --> XPAND-UI
import { ExpandedSelect, InfoField, Input, Select } from 'xpand-ui/forms';
import {
	convertDateToYYYYMMDD,
	hasTwoDaysOfDifference,
	isOverlap,
	statusColor,
	convertDateToDDMMYY,
	calculateTimeOff,
	splitDateRangesByWeekendAndHoliday
} from '../utils';
import { addNotification } from 'lib/utils/notifications';
import { Check, SaveOutlined } from '@mui/icons-material';

const TimeOffRequestModal: FC<any> = ({
	saveTimeoff,
	isTimeOffRequestModalOpen,
	setTimeOffRequestModalOpen,
	isEditingTimeoffRequest,
	setEditingTimeoffRequest,
	timeOffRequestStartDate,
	setTimeOffRequestStartDate,
	timeOffRequestEndDate,
	setTimeOffRequestEndDate,
	selectedTimeOffRequest,
	setSelectedTimeOffRequest,
	setIsAfterSave,
	setDateAfterSave,
	statusCredits,
	timeoffRequests,
	setTimeoffRequests,
	setStatusUnderlines,
	statusUnderlines,
	onCellHoverIn,
	onCellHoverOut,
	holidays,
	hrAbsenceProject,
	mainDatePickerRef
}) => {
	const classes = useStyles();
	momentTimezone.moment = moment;
	const datePickerRef = useRef(null);

	const [timeOffTypes, setTimeOffTypes] = useState<
		{
			id: any;
			color: any;
			title: any;
			description: any;
		}[]
	>([]);
	const [initialTimeoffRequests, setInitialTimeoffRequests] = useState(null);
	const [initialStatusUnderlines, setInitialStatusUnderlines] = useState(null);
	const [enableToCreate, setEnableToCreate] = useState(true);
	const [entryWasClearedOnEdit, setEntryWasClearedOnEdit] = useState(false);
	const [selectedTimeOffType, setSelectedTimeOffType] = useState<{
		id: any;
		color: any;
		title: any;
		scheduleWeekends: boolean;
	}>({
		id: 'ACDEAC2C-8C30-42B4-826C-2ACFBC97BA66',
		color: '#F4DAA8',
		title: 'VACATIONS LEAVE',
		scheduleWeekends: false
	});
	const [isSubmit, setIsSubmit] = useState(false);
	const [timeoffSubmittionModal, setTimeoffSubmittionModal] = useState(false);
	const [initialAccountTimeOffTypeId, setInitialAccountTimeOffTypeId] = useState(null);
	const [initialHoursOff, setInitialHoursOff] = useState(0);
	const [accountEmployeeTimeOffRequestId, setAccountEmployeeTimeOffRequestId] = useState(null);

	// form
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		reset,
		getValues,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues
	});
	// On Modal Open/Close
	useEffect(() => {
		if (isTimeOffRequestModalOpen) {
			// Set initial time off requests when the modal opens
			setInitialTimeoffRequests(timeoffRequests);
			setInitialStatusUnderlines(statusUnderlines);

			// Create Date Range if both start and end dates are provided
			if (timeOffRequestStartDate && timeOffRequestEndDate) {
				if (isEditingTimeoffRequest) {
					setValue('timeOffType', selectedTimeOffRequest.accountTimeOffTypeId);
					setValue('daysOff', selectedTimeOffRequest.dayOff.toString());
					setValue('hoursOff', selectedTimeOffRequest.hoursOff.toString());
					setValue(
						'description',
						selectedTimeOffRequest.timeOffDescription ? selectedTimeOffRequest.timeOffDescription : ''
					);
					setInitialHoursOff(selectedTimeOffRequest.hoursOff);
					setInitialAccountTimeOffTypeId(selectedTimeOffRequest.accountTimeOffTypeId);
					setAccountEmployeeTimeOffRequestId(selectedTimeOffRequest.accountEmployeeTimeOffRequestId);
				} else {
					createDateRange(timeOffRequestStartDate, timeOffRequestEndDate, selectedTimeOffType);
				}
				setEnableToCreate(false);
			}
		} else {
			// Reset state when the modal closes
			setInitialTimeoffRequests(null);
			setInitialStatusUnderlines(null);
			setTimeOffRequestStartDate(null);
			setTimeOffRequestEndDate(null);
			setSelectedTimeOffRequest(null);
			setEditingTimeoffRequest(false);
			setEnableToCreate(true);
			setEntryWasClearedOnEdit(false);
			setInitialAccountTimeOffTypeId(null);
			setInitialHoursOff(0);
			setAccountEmployeeTimeOffRequestId(null);
			setValue('daysOff', '0');
			setValue('hoursOff', '0');
			setValue('description', '');
		}
	}, [isTimeOffRequestModalOpen]);

	// Load Timeoff Types for Selector
	useEffect(() => {
		const sortedStatusCredits = [...statusCredits].sort((a, b) => b.available - a.available);

		setTimeOffTypes(
			sortedStatusCredits.map(leave => {
				const {
					accountTimeOffType,
					available,
					color,
					accountTimeOffTypeId,
					scheduleWeekends,
					briefExplanation
				} = leave;
				const description =
					isEditingTimeoffRequest &&
					entryWasClearedOnEdit &&
					selectedTimeOffRequest.accountTimeOffTypeId == accountTimeOffTypeId
						? `${(Number(available) + initialHoursOff) / 8} Days Available`.replace('.', ',')
						: `${Number(available) / 8} Days Available`.replace('.', ',');

				const tooltip = <span style={{ fontSize: '12px' }}>{briefExplanation}</span>;

				return {
					id: accountTimeOffTypeId,
					color,
					title: accountTimeOffType,
					description,
					available,
					tooltip: tooltip,
					asterisk: scheduleWeekends
				};
			})
		);
		statusCredits.forEach((e: React.SetStateAction<{ id: any; color: any; title: any }>) => {
			if (e.accountTimeOffTypeId == getValues('timeOffType')) {
				setSelectedTimeOffType(e);
			}
		});
	}, [statusCredits, enableToCreate]);

	// Handle change event for the Datepicker
	const onChange = useCallback(
		(event: { value: (string | number | Date)[] }) => {
			// Create Date Range if both start and end dates are select
			if (event.value[1] !== null) {
				const startDate = new Date(event.value[0]);
				const endDate = new Date(event.value[1]);
				endDate.setDate(endDate.getDate() - 1); // Adjust end date to exclude the next day

				const newRange = { start: startDate, end: endDate, cellCssClass: 'range' };

				// Check for overlap with existing time off requests
				if (isOverlap(newRange, timeoffRequests)) {
					clearDatePickersSelectedValue();
					addNotification('info', 'The selected date range overlaps with an existing Timeoff Request!');
					return;
				}
				setTimeOffRequestStartDate(startDate);
				setTimeOffRequestEndDate(endDate);

				// Handle the new time off request range
				createDateRange(startDate, endDate, selectedTimeOffType);
				setEnableToCreate(false);
				//Set the new selected range value
				if (datePickerRef.current) {
					datePickerRef.current.setVal([startDate, new Date(event.value[1])]);
					datePickerRef.current.setActiveDate([startDate, new Date(event.value[1])]);
				}
			}
		},
		[timeoffRequests, selectedTimeOffType]
	);

	// Function to handle the creation and updating of time off requests
	const createDateRange = (
		startDate: Date,
		endDate: Date,
		selectedTimeOffType: { id?: any; color: any; title: any; scheduleWeekends: boolean }
	) => {
		const startEvent = createRangeEdges(startDate, 'start-range', selectedTimeOffType, 'Not Saved');
		const endEvent = createRangeEdges(endDate, 'end-range', selectedTimeOffType, 'Not Saved');

		// Adjust cellCssClass for single-day requests
		if (startDate.getTime() === endDate.getTime()) {
			startEvent.cellCssClass = '';
		}

		const { daysOff, hoursOff } = calculateTimeOff(startDate, endDate, selectedTimeOffType, holidays);

		// Update form values for days and hours off
		setValue('daysOff', daysOff.toString());
		setValue('hoursOff', hoursOff.toString());

		// Update the time off requests state
		setTimeoffRequests((prevData: any) => {
			const newData = [...prevData, startEvent];
			if (startDate.getTime() !== endDate.getTime()) {
				newData.push(endEvent);
			}

			// Add range event if there are days in between the start and end dates
			if (hasTwoDaysOfDifference(startEvent.date, endDate)) {
				const rangeStart = new Date(startEvent.date);
				rangeStart.setDate(rangeStart.getDate() + 1);
				const rangeEnd = new Date(endDate);
				newData.push({
					start: rangeStart,
					end: rangeEnd,
					cellCssClass: 'range',
					backgroundAux: selectedTimeOffType.color,
					approvalStatus: 'Not Saved',
					accountTimeOffType: selectedTimeOffType.title,
					statusColor: statusColor('Saved')
				});
			}

			return newData;
		});
	};

	// Helper function to create date ranges
	const createRangeEdges = (
		date: Date,
		cellCssClass: string,
		selectedTimeOffType: { id?: any; color: any; title: any },
		status: string
	) => ({
		date,
		highlight: selectedTimeOffType.color,
		cellCssClass,
		backgroundAux: selectedTimeOffType.color,
		approvalStatus: status,
		accountTimeOffType: selectedTimeOffType.title,
		statusColor: statusColor(status)
	});

	const saveClickHandler = async (payloadForm: {
		timeOffType: string;
		daysOff: number;
		hoursOff: number;
		description: string;
	}) => {
		if (Number(payloadForm.daysOff) == 0 || Number(payloadForm.hoursOff) == 0) {
			addNotification(
				'info',
				timeOffRequestStartDate
					? 'You need select to select a range of correct dates to use with this Time Off Type!'
					: 'You need select to select a range of dates!'
			);
			return;
		}
		if (
			isEditingTimeoffRequest && initialAccountTimeOffTypeId == selectedTimeOffType?.accountTimeOffTypeId
				? Number(payloadForm.hoursOff) > selectedTimeOffType?.available + initialHoursOff
				: Number(payloadForm.hoursOff) > selectedTimeOffType?.available
		) {
			addNotification('info', 'You don’t have enough assigned credits!');
			return;
		}

		// Split time off Request by weekends and holidays if needed (no scheduleWeekends on timeOffType)
		const splitedRanges = splitDateRangesByWeekendAndHoliday(
			timeOffRequestStartDate,
			timeOffRequestEndDate,
			selectedTimeOffType,
			holidays,
			payloadForm.hoursOff
		);
		const splitedTimeoffRequests = [];

		if (isEditingTimeoffRequest) {
			for (let index = 0; index < splitedRanges.length; index++) {
				const element = splitedRanges[index];
				if (index == 0) {
					splitedTimeoffRequests.push({
						accountEmployeeId: selectedTimeOffType?.accountEmployeeId,
						hoursOff: Number(element.hoursOff),
						startDate: convertDateToYYYYMMDD(element.startDate),
						endDate: convertDateToYYYYMMDD(element.endDate),
						description: payloadForm.description,
						daysOff: element.daysOff,
						projectId: hrAbsenceProject?.id.toString(), //Xpand IT - HUB - HR Absense
						availableTimeOffBalance:
							initialAccountTimeOffTypeId == selectedTimeOffType?.accountTimeOffTypeId
								? selectedTimeOffType?.available + initialHoursOff
								: selectedTimeOffType?.available,
						actionType: 'Update',
						timeOffRequestId: accountEmployeeTimeOffRequestId,
						timeOffTypeId: selectedTimeOffType?.accountTimeOffTypeId,
						isSubmit: isSubmit
					});
				} else {
					splitedTimeoffRequests.push({
						accountEmployeeId: selectedTimeOffType?.accountEmployeeId,
						hoursOff: Number(element.hoursOff),
						startDate: convertDateToYYYYMMDD(element.startDate),
						endDate: convertDateToYYYYMMDD(element.endDate),
						description: payloadForm.description,
						daysOff: element.daysOff,
						projectId: hrAbsenceProject?.id.toString(), //Xpand IT - HUB - HR Absense
						availableTimeOffBalance:
							initialAccountTimeOffTypeId == selectedTimeOffType?.accountTimeOffTypeId
								? selectedTimeOffType?.available + initialHoursOff
								: selectedTimeOffType?.available,
						actionType: 'NotUpdate',
						timeOffRequestId: '',
						timeOffTypeId: selectedTimeOffType?.accountTimeOffTypeId,
						isSubmit: isSubmit
					});
				}
			}
		} else {
			splitedRanges.forEach(element => {
				splitedTimeoffRequests.push({
					accountEmployeeId: selectedTimeOffType?.accountEmployeeId,
					hoursOff: Number(element.hoursOff),
					startDate: convertDateToYYYYMMDD(element.startDate),
					endDate: convertDateToYYYYMMDD(element.endDate),
					description: payloadForm.description,
					daysOff: element.daysOff,
					projectId: hrAbsenceProject?.id.toString(), //Xpand IT - HUB - HR Absense
					availableTimeOffBalance: selectedTimeOffType?.available,
					actionType: 'NotUpdate',
					timeOffRequestId: '',
					timeOffTypeId: selectedTimeOffType?.accountTimeOffTypeId,
					isSubmit: isSubmit
				});
			});
		}
		saveTimeoff({ timeoffRequests: splitedTimeoffRequests });
		setIsAfterSave(true);
		setDateAfterSave(splitedRanges[0].startDate);
	};

	// Time off Submittion Actions
	const timeoffSubmittionActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setTimeoffSubmittionModal(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			type: 'submit',
			form: 'form-new-timeoff-request',
			onClick: () => {
				setTimeoffSubmittionModal(false);
			}
		}
	];
	// Render header for Datepicker
	const calendarHeader = useCallback(
		() => (
			<Grid container direction="row" justifyContent="space-between" className={classes.navBarModal}>
				<Grid item>
					<CalendarPrev />
				</Grid>
				<Grid item>
					<CalendarNav />
				</Grid>
				<Grid item>
					<CalendarNext />
				</Grid>
			</Grid>
		),
		[classes.navBarModal]
	);

	// Function that clears date pickers values (blue selection)
	const clearDatePickersSelectedValue = () => {
		if (datePickerRef.current) {
			datePickerRef.current.setVal(undefined);
			datePickerRef.current.setActiveDate(undefined);
		}
		if (mainDatePickerRef.current) {
			mainDatePickerRef.current.setVal(undefined);
			mainDatePickerRef.current.setActiveDate(undefined);
		}
	};

	// Function that closes modal and reset all changes
	const closeModal = () => {
		setEnableToCreate(true);
		setTimeoffRequests(initialTimeoffRequests);
		setStatusUnderlines(initialStatusUnderlines);
		setTimeOffRequestModalOpen(false);
		clearDatePickersSelectedValue();
	};

	return (
		<>
			{/* NEW ENTRY MODAL */}
			<Dialog
				title={!isEditingTimeoffRequest ? 'NEW ENTRY' : 'EDIT ENTRY'}
				scroll="body"
				maxWidth="md"
				modal={{
					open: isTimeOffRequestModalOpen,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							closeModal();
						}
					},
					content: (
						<form
							id="form-new-timeoff-request"
							onSubmit={e => handleSubmit(() => saveClickHandler(watch()))(e)}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<ExpandedSelect
										name="timeOffType"
										label="TIME OFF TYPE"
										additionalOnChange={() => {
											setEnableToCreate(true);
											if (isEditingTimeoffRequest) {
												// Filter out the entries where accountEmployeeTimeOffRequestId matches
												const filteredTimeoffRequests = initialTimeoffRequests.filter(
													request =>
														request.accountEmployeeTimeOffRequestId !==
														accountEmployeeTimeOffRequestId
												);
												setTimeoffRequests(filteredTimeoffRequests);
												if (!entryWasClearedOnEdit) {
													const filteredStatusUnderlines = initialStatusUnderlines.filter(
														underline =>
															new Date(
																new Date(underline.start).setHours(0, 0, 0, 0)
															).getTime() !==
															new Date(
																new Date(timeOffRequestStartDate).setHours(0, 0, 0, 0)
															).getTime()
													);
													setStatusUnderlines(filteredStatusUnderlines);
												}
												setEntryWasClearedOnEdit(true);
											} else {
												setTimeoffRequests(initialTimeoffRequests);
											}
											setValue('daysOff', '0');
											setValue('hoursOff', '0');
											clearDatePickersSelectedValue();
											statusCredits.forEach(e => {
												if (e.accountTimeOffTypeId == getValues('timeOffType')) {
													setSelectedTimeOffType(e);
												}
											});
										}}
										options={timeOffTypes}
										control={control}
										errors={errors}
									/>
								</Grid>
								{selectedTimeOffType.scheduleWeekends && (
									<Grid item xs={12} className={classes.asteriskInfoField}>
										<InfoField
											value={
												<>
													<span style={{ color: '#3F80EF' }}>*</span> can be used on weekend
													days or holidays
												</>
											}
										/>
									</Grid>
								)}
								<Grid item xs={12} className={classes.datePickerGrid}>
									<Datepicker
										onInit={(event, inst) => {
											datePickerRef.current = inst; // Store the instance in the ref
											if (timeOffRequestStartDate && timeOffRequestEndDate) {
												//Loads the selected range value
												datePickerRef.current.setVal([
													timeOffRequestStartDate,
													new Date(
														new Date(
															new Date(timeOffRequestEndDate).setHours(0, 0, 0, 0)
														).setDate(timeOffRequestEndDate.getDate() + 1)
													)
												]);
												datePickerRef.current.setActiveDate([
													timeOffRequestStartDate,
													new Date(
														new Date(
															new Date(timeOffRequestEndDate).setHours(0, 0, 0, 0)
														).setDate(timeOffRequestEndDate.getDate() + 1)
													)
												]);
												// Ensure the view is always set to pre-select ranged
												inst.navigate(timeOffRequestStartDate);
											}
										}}
										controls={['calendar']}
										select="range"
										display="inline"
										dataTimezone="utc"
										displayTimezone="Europe/London"
										timezonePlugin={momentTimezone}
										renderCalendarHeader={calendarHeader}
										calendarType={'month'}
										calendarSize={2}
										dayNamesMin={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
										firstDay={1}
										showRangeLabels={false}
										onChange={(event: { value: (string | number | Date)[] }) => {
											if (enableToCreate) {
												onChange(event);
											} else {
												//Forces to keep the same selected range value
												datePickerRef.current.setVal([
													timeOffRequestStartDate,
													new Date(
														new Date(timeOffRequestEndDate).setDate(
															timeOffRequestEndDate.getDate() + 1
														)
													)
												]);
												datePickerRef.current.setActiveDate([
													timeOffRequestStartDate,
													new Date(
														new Date(timeOffRequestEndDate).setDate(
															timeOffRequestEndDate.getDate() + 1
														)
													)
												]);
											}
										}}
										colors={timeoffRequests.map(
											(timeoffRequest: { cellCssClass: any }, index: any) => ({
												...timeoffRequest,
												cellCssClass: `custom-${timeoffRequest.cellCssClass}-${index}`
											})
										)}
										labels={statusUnderlines}
										onCellHoverIn={onCellHoverIn}
										onCellHoverOut={onCellHoverOut}
									/>
								</Grid>
								{!enableToCreate && (
									<Grid item xs={12} className={classes.clearButton}>
										<Button
											onClick={() => {
												setEnableToCreate(true);
												setValue('daysOff', '0');
												setValue('hoursOff', '0');
												setTimeOffRequestStartDate(null);
												setTimeOffRequestEndDate(null);
												if (isEditingTimeoffRequest) {
													// Filter out the entries where accountEmployeeTimeOffRequestId matches
													const filteredTimeoffRequests = initialTimeoffRequests.filter(
														request =>
															request.accountEmployeeTimeOffRequestId !==
															accountEmployeeTimeOffRequestId
													);
													setTimeoffRequests(filteredTimeoffRequests);
													if (!entryWasClearedOnEdit) {
														const filteredStatusUnderlines = initialStatusUnderlines.filter(
															underline =>
																new Date(
																	new Date(underline.start).setHours(0, 0, 0, 0)
																).getTime() !==
																new Date(
																	new Date(timeOffRequestStartDate).setHours(
																		0,
																		0,
																		0,
																		0
																	)
																).getTime()
														);
														setStatusUnderlines(filteredStatusUnderlines);
													}
													setEntryWasClearedOnEdit(true);
												} else {
													setTimeoffRequests(initialTimeoffRequests);
												}
												clearDatePickersSelectedValue();
											}}>
											{new Date(timeOffRequestStartDate).getTime() ===
											new Date(timeOffRequestEndDate).getTime() ? (
												<span>
													Clear Day <b>{convertDateToDDMMYY(timeOffRequestStartDate)}</b>
												</span>
											) : (
												<span>
													Clear Interval (
													<b>{convertDateToDDMMYY(timeOffRequestStartDate)}</b> to{' '}
													<b>{convertDateToDDMMYY(timeOffRequestEndDate)}</b>)
												</span>
											)}
										</Button>
									</Grid>
								)}
								<Grid item xs={4} className={classes.gridItemsPaddingTop}>
									<InfoField label="PROJECT NAME" value={hrAbsenceProject?.projectName} />
								</Grid>
								<Grid item container xs={4} className={classes.gridItemsPaddingTop}>
									<Grid item xs={5} />
									<Grid item xs={3}>
										<Input name="daysOff" disabled control={control} errors={errors} />
									</Grid>
									<Grid item xs={4} className={classes.textAlign}>
										<InfoField value="Days Off" />
									</Grid>
								</Grid>
								<Grid item container xs={4} className={classes.gridItemsPaddingTop}>
									<Grid item xs={6} />
									<Grid item xs={6}>
										{getValues('daysOff') == '1' && (
											<Select
												name="hoursOff"
												options={[
													{ id: '4', label: 'Half Day' },
													{ id: '8', label: 'Full Day' }
												]}
												control={control}
												errors={errors}
											/>
										)}
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Input
										name="description"
										label="Description"
										multiline={true}
										maxRows={3}
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item container xs={12}>
									<Grid item xs={6}>
										<Button
											size="large"
											style={{
												color: 'black',
												border: 'none',
												borderRadius: '10px',
												width: '150px'
											}}
											onClick={closeModal}>
											Cancel
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											size="large"
											style={{
												color: 'black',
												border: '1px solid grey',
												borderRadius: '10px',
												width: '150px',
												float: 'right'
											}}
											onClick={() => {
												setIsSubmit(false);
												setTimeoffSubmittionModal(true);
											}}>
											Save <SaveOutlined style={{ marginLeft: '10px' }} />
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											color="primary"
											size="large"
											variant="contained"
											style={{
												borderRadius: '10px',
												opacity: '0.8',
												width: '150px',
												marginRight: '10px',
												float: 'right'
											}}
											onClick={() => {
												setIsSubmit(true);
												setTimeoffSubmittionModal(true);
											}}>
											Submit <Check style={{ marginLeft: '10px' }} />
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)
				}}
			/>
			{/* Dialog for time off request submittion */}
			<Dialog
				modal={{
					open: Boolean(timeoffSubmittionModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setTimeoffSubmittionModal(false);
					},
					content: isSubmit ? (
						<Typography gutterBottom>Are you sure you want to submit your time off request?</Typography>
					) : (
						<Typography gutterBottom>Are you sure you want to save your time off request?</Typography>
					)
				}}
				title={isSubmit ? 'Submit Time Off Request' : 'Save Time Off Request'}
				actions={timeoffSubmittionActions}
				scroll="body"
			/>
		</>
	);
};
export default TimeOffRequestModal;
