/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, ChangeEvent } from 'react';

//* EXTERNAL LIBS --> MUI
import { FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle, Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';

//* TYPINGS
import { ITableAction, ITableColumn, ITableColumnsFilter } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import Adicionar from '../../../../assets/icons/Adicionar.svg';
import GrantAccess from './GrantAccess';
import { useStyles } from './styles';
import { AccessControlProps } from '.';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IAccessControl extends AccessControlProps {
	goToPage: (path: string) => void;
}

//* COMPONENT
const AccessControl: FC<IAccessControl> = ({
	goToPage,
	administration,
	filters,
	getAccessControlList,
	sendRemoveAllAccesses,
	clearAdministrationError,
	setAccessControlPageFilter
}) => {
	const classes = useStyles();
	const { accessControlList, loading, error } = administration;
	const { accessControlFilter } = filters;

	const [confirmModal, setConfirmModal] = useState<number | boolean>();
	const [editModal, setEditModal] = useState(null);

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(accessControlFilter);

	useEffect(() => {
		setAccessControlPageFilter({ searchValue });
	}, [searchValue]);

	useEffect(() => {
		if (!error) {
			getAccessControlList();
		}
	}, []);

	const confirmRemoveAll = (payload: number) => {
		sendRemoveAllAccesses(payload);
		setConfirmModal(false);
	};

	const handleCloseModal = () => {
		setEditModal(null);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				width: '5%',
				format: row => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${row.username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{
				label: 'User',
				id: 'username',
				width: '15%'
			},
			{
				label: 'Name',
				id: 'name',
				width: '20%'
			}
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'editAction',
				type: 'button',
				icon: <EditIcon />,
				render: () => true,
				disabled: () => false,
				onClick: row => setEditModal(row.id)
			},
			{
				id: 'removeAction',
				type: 'button',
				icon: <DeleteIcon />,
				render: () => true,
				disabled: () => false,
				onClick: row => setConfirmModal(row.id)
			}
		],
		[accessControlList]
	);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'grantAccess',
				to: '/cp/admin/administration/accessControl/addUser',
				onClick: () => goToPage('/admin/administration/accessControl/addUser'),
				icon: <Adicionar />,
				label: 'Grant Access to User'
			}
		],
		[]
	);

	// TABLE DATA
	const tableData = useMemo(
		() =>
			accessControlList?.users?.map(e => ({
				...e
			})),
		[accessControlList]
	);

	if (error) return handleErrorPage(error, clearAdministrationError);

	const isLoading = accessControlList === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle title="" actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="name"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				modal={{
					open: Boolean(confirmModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: <Typography gutterBottom>Are you sure you want to remove all accesses?</Typography>
				}}
				title="Remove All Accesses"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(false)
					},
					{
						id: 'confirmRemoveAllAccesses',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => confirmRemoveAll(confirmModal as number),
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>

			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				modal={{
					open: Boolean(editModal),
					handleClose: handleCloseModal,
					content: <GrantAccess handleClose={handleCloseModal} userId={editModal} />
				}}
				actions={[]}
			/>
		</>
	);
};

export default withLayout(AccessControl);
