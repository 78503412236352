/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Typography } from '@mui/material';

import { PageTitle, IPageTitleAction, LoadingOverlay } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

import { EMPLOYEE } from 'pages/Admin/_ProposalsFormUtils/constants';
import { addNotification } from 'lib/utils/notifications';
import Download from 'assets/icons/downloadButton.svg';

import { getProposalSummaryInfoHelper, getMissingFieldsNames, getAdditionalErrors } from '../utils';
import SummaryTable from '../components/SummaryTable';
import { useStyles } from './styles';

interface ISummary {
	[x: string | number]: any;
	handleWarning: (names: string[], tabs: number[]) => void;
}

const Summary: FC<ISummary> = ({
	type,
	proposals,
	selectOptions,
	referenceData,
	watch,
	getProposalSummary,
	clearSummary,
	getProposalExport,
	handleWarning,
	additionalErrors,
	isContractor,
	isCollabType,
	formFields,
	setValue
}) => {
	const classes = useStyles();
	const { loading, proposalSummary } = proposals;
	const proposal = watch();
	useEffect(() => {
		const formPayload = watch();
		const requiredValues = getProposalSummaryInfoHelper(formPayload, type, isContractor, formFields);
		const warningValues = {};
		Object.keys(requiredValues).forEach(e => {
			if (requiredValues[e] === false) {
				warningValues[e] = '';
			}
		});

		const { names, tabs } = getMissingFieldsNames(warningValues, isContractor);
		const { additionalNames, additionalTabs } = getAdditionalErrors(additionalErrors, isContractor);
		let mergedNames = names.concat(additionalNames);
		var mergedTabs = new Set([...tabs, ...additionalTabs]);
		if (mergedNames.length !== 0) {
			handleWarning(mergedNames, [...mergedTabs]);
			addNotification('danger', `${type} is not completed, this fields must be valid: ${mergedNames.join(', ')}`);
		}

		if (!proposalSummary && !Object.values(requiredValues).includes(false)) {
			getProposalSummary({
				...formPayload,
				workScheduleExemptionValue: formPayload.ihtValue,
				prizeValue: formPayload.prizeBonus,
				formFields: formFields.formFieldsConfig
			});
		}

		return () => clearSummary();
	}, []);

	const mergedProposal = {
		...proposal,
		...proposalSummary,
		prizeBonusGoalTypes: selectOptions.prizeBonusGoalTypes,
		formFields
	};

	const pageTitleActions: IPageTitleAction[] =
		type !== EMPLOYEE && isContractor !== 'Contractor'
			? [
					{
						id: 'pdf',
						onClick: () => proposalSummary && getProposalExport('pdf', mergedProposal),
						disabled: !proposalSummary || !proposalSummary?.exportConfig?.find(e => e.exportType == 'PDF'),
						icon: null,
						label: (
							<>
								<Download />
								<Typography className={classes.downloadButtonText}>PDF</Typography>
							</>
						) as React.ReactNode
					},
					{
						id: 'excel',
						onClick: () => proposalSummary && getProposalExport('excel', mergedProposal),
						disabled:
							!proposalSummary || !proposalSummary?.exportConfig?.find(e => e.exportType == 'EXCEL'),
						icon: null,
						label: (
							<>
								<Download />
								<Typography className={classes.downloadButtonText}>EXCEL</Typography>
							</>
						) as React.ReactNode
					}
			  ]
			: [];

	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.root}>
				{/* CONTAINER TITLE AND ACTIONS */}
				{/* <Grid item xs={12} className={classes.rows}> */}
				<PageTitle
					title={
						<>
							<Typography gutterBottom color="primary" className={classes.name}>
								{mergedProposal?.name || 'N/A'}
							</Typography>
							<Typography className={classes.date}>
								<span className={classes.dateLabel}>DATE: </span>
								{(mergedProposal?.startDate && parseDateToShow(mergedProposal?.startDate)) || ' N/A'}
							</Typography>
						</>
					}
					actions={pageTitleActions}
				/>

				<Scrollbars
					style={{ marginTop: '10px', height: 'calc(100vh - 320px)', overflowX: 'hidden', width: '100%' }}
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					{proposalSummary && (
						<SummaryTable
							proposal={mergedProposal}
							selectOptions={selectOptions}
							referenceData={referenceData}
							isContractor={isContractor}
							isCollabType={isCollabType}
							formFields={formFields.formFieldsConfig}
						/>
					)}
				</Scrollbars>
			</div>
		</>
	);
};

export default Summary;
