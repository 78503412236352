import React, { useEffect } from 'react';
import {
	Person,
	PersonOutlineOutlined,
	Apps,
	Book,
	Euro,
	Group,
	Work,
	Settings,
	SettingsOutlined,
	Workspaces,
	WorkspacesOutlined,
	MailOutline,
	FolderShared,
	FolderSharedOutlined,
	Today,
	Handyman
} from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';

import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import TimeSheet from '../../assets/icons/TimeSheet.svg';

// Icons for sidebar
import ResumeIcon from 'assets/images/sidebar/ResumeIcon.svg';
import ClientProjectIcon from 'assets/images/sidebar/ClientProjectIcon.svg';
import ProposalsIcon from 'assets/images/sidebar/ProposalsIcon.svg';
import AdmissionIcon from 'assets/images/sidebar/AdmissionIcon.svg';
import SkillsIcon from 'assets/images/sidebar/SkillsIcon.svg';
import MyCompanyIcon from 'assets/images/sidebar/MyCompany.svg';

// Icons for page titles
import AdmissionIconOutlined from 'assets/images/pageTitles/AdmissionIconOutlined.svg';
import ResumeIconOutlined from 'assets/images/pageTitles/ResumeIconOutlined.svg';
import TrainingIconOutlined from 'assets/images/pageTitles/TrainingIconOutlined.svg';
import CareerIconOutlined from 'assets/images/pageTitles/CareerIconOutlined.svg';
import EmployeesIconOutlined from 'assets/images/pageTitles/UsersIconOutlined.svg';
import ProposalsIconOutlined from 'assets/images/pageTitles/ProposalsIconOutlined.svg';
import ClientProjectIconOutlined from 'assets/images/pageTitles/ClientProjectIconOutlined.svg';
import SkillsOutlined from 'assets/images/pageTitles/SkillsOutlined.svg';
import MyCompanyIconOutlined from 'assets/images/pageTitles/MyCompanyOutlined.svg';
import MyTimeOffIcon from 'assets/images/pageTitles/MyTimeOffIcon.svg';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Roles } from 'lib/roles';
import { ISidebarGroupItem } from 'xpand-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { getSystemSkills } from 'store/personalInfo/thunks';
import { useIdToken } from 'pages/General/Login/utils';

const {
	CP_ROLE_SYSTEM_ADMIN,
	CP_ROLE_PAYROLL_AND_BENEFITS,
	CP_ROLE_IT_OPERATIONS,
	CP_ROLE_TALENT_ENGAGEMENT,
	CP_ROLE_BUSINESS_UNIT_MANAGERS,
	CP_ROLE_TALENT_ACQUISITION,
	CP_ROLE_CLIENTS,
	CP_ROLE_PROJECTS,
	CP_ROLE_RESUMES,
	CP_ROLE_SKILLS,
	CP_ROLE_SALARY_CATEGORIES,
	CP_ROLE_TALENT_ACQUISITION_ADMIN,
	CP_ROLE_BUDGET,
	CP_ROLE_BUDGET_ADMIN,
	CP_ROLE_PLANNER,
	CP_ROLE_XTRACKER_TESTER,
	CP_ROLE_LOGISTICS
} = Roles;

// SVG TITLE STYLES
const styles = {
	marginRight: '10px',
	marginTop: '5px',
	height: '100%',
	width: '40px',
	color: '#3D464D'
};

// PATHS
const adminPath = '/admin';

const personalInfo = {
	apps: {
		label: 'Company Apps',
		path: '/apps',
		icon: <Apps />,
		titleIcon: <Apps style={styles} />,
		roles: undefined
	},
	bookings: {
		label: 'Bookings',
		path: '/bookings',
		icon: <Workspaces />,
		titleIcon: <WorkspacesOutlined style={styles} />,
		roles: undefined
	},
	profile: {
		label: 'My Profile',
		path: '/profile',
		icon: <Person />,
		titleIcon: <PersonOutlineOutlined style={styles} />,
		roles: undefined
	},
	personalFiscal: {
		label: 'Personal & Fiscal Information',
		path: '/profile/personalFiscal',
		icon: <Person />,
		titleIcon: <PersonOutlineOutlined style={styles} />,
		roles: undefined
	},
	consentInformation: {
		label: 'Consent Information',
		path: '/profile/consent',
		icon: <Person />,
		titleIcon: <PersonOutlineOutlined style={styles} />,
		roles: undefined
	},
	myCompany: {
		label: 'Company',
		path: '/company',
		icon: <MyCompanyIcon />,
		titleIcon: <MyCompanyIconOutlined style={styles} />,
		roles: undefined
	},
	myCompanyOrgChart: {
		label: 'Organizational Chart',
		path: '/company/organizationalChart',
		icon: <MyCompanyIcon />,
		titleIcon: <MyCompanyIconOutlined style={styles} />,
		roles: undefined
	},
	myCompanyAllEmployees: {
		label: 'All Employees',
		path: '/company/allEmployees',
		icon: <MyCompanyIcon />,
		titleIcon: <MyCompanyIconOutlined style={styles} />,
		roles: undefined
	},
	myCompanyMyTeam: {
		label: 'My Team',
		path: '/company/myTeam',
		icon: <MyCompanyIcon />,
		titleIcon: <MyCompanyIconOutlined style={styles} />,
		roles: undefined
	},
	skills: {
		label: 'Skills',
		path: '/profile/skills',
		subPath: '/skills/:skillCategory',
		icon: <SkillsIcon />,
		titleIcon: <SkillsOutlined style={styles} />,
		roles: undefined
	},
	resume: {
		label: 'Resume',
		path: '/profile/resume',
		icon: <ResumeIcon />,
		titleIcon: <ResumeIconOutlined style={styles} />,
		roles: undefined
	},
	trainings: {
		label: 'Trainings',
		path: '/profile/trainings',
		icon: <Book />,
		titleIcon: <TrainingIconOutlined style={styles} />,
		roles: undefined
	},
	benefits: {
		label: 'Benefits',
		path: '/financial/benefits',
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: undefined
	},
	career: {
		label: 'Career',
		path: '/profile/career',
		icon: <Work />,
		titleIcon: <CareerIconOutlined style={styles} />,
		roles: undefined
	},
	financial: {
		label: 'My Financial',
		path: '/financial',
		icon: <FolderShared />,
		titleIcon: <FolderSharedOutlined style={styles} />,
		roles: undefined
	},
	financialDocuments: {
		label: 'Documents',
		path: '/financial/financial-documents',
		icon: <FolderShared />,
		titleIcon: <FolderSharedOutlined style={styles} />,
		roles: undefined
	},
	xtracker: {
		label: 'My Time Tracking',
		path: `/time-tracking`,
		icon: <TimerSharpIcon />,
		titleIcon: <FolderSharedOutlined style={styles} />,
		roles: undefined
	},
	xtrackerTimesheets: {
		label: 'Timesheets',
		path: `/time-tracking/my-timesheets`,
		icon: <TimerSharpIcon />,
		titleIcon: <TimeSheet style={styles} />,
		roles: undefined
	},
	xtrackerTimeoffs: {
		label: 'Time Off',
		path: `${adminPath}/time-tracking/time-off`,
		icon: <MyTimeOffIcon />,
		titleIcon: <MyTimeOffIcon style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_XTRACKER_TESTER]
	},
	xtrackerMyExpenses: {
		label: 'Expenses',
		path: `${adminPath}/financial/my-expenses`,
		icon: <TimerSharpIcon />,
		titleIcon: <TimeSheet style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_XTRACKER_TESTER]
	},
	xtrackerMyExpensesNew: {
		label: 'Expense Sheet',
		path: `${adminPath}/financial/my-expenses/sheet/:id`,
		icon: <TimerSharpIcon />,
		titleIcon: <TimeSheet style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_XTRACKER_TESTER]
	}
};
//! start EMPLOYEES MANAGEMENT
const employeeManagment = {
	employees: {
		label: 'Employees Management',
		path: `${adminPath}/employees`,
		icon: <Group />,
		titleIcon: <EmployeesIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_IT_OPERATIONS,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_TALENT_ENGAGEMENT,
			CP_ROLE_LOGISTICS
		]
	},
	newEmployee: {
		label: 'New Employee',
		path: `${adminPath}/employees/add`,
		icon: <Group />,
		titleIcon: <EmployeesIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_IT_OPERATIONS, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	listEmployees: {
		label: 'List Employees',
		path: `${adminPath}/employees/list`,
		icon: <Group />,
		titleIcon: <EmployeesIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_IT_OPERATIONS,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_TALENT_ENGAGEMENT,
			CP_ROLE_LOGISTICS
		]
	},
	adminProfile: {
		label: 'Employee Profile',
		path: `${adminPath}/employees/profile/:path/:username`,
		icon: <Person />,
		titleIcon: <PersonOutlineOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_IT_OPERATIONS,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_TALENT_ENGAGEMENT,
			CP_ROLE_LOGISTICS
		]
	},
	sendWelcomeEmail: {
		label: 'Send Welcome Email',
		path: `${adminPath}/employees/sendWelcomeEmail/:username`,
		icon: <MailOutline />,
		titleIcon: <MailOutline style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_IT_OPERATIONS, CP_ROLE_PAYROLL_AND_BENEFITS]
	}
};
//! end EMPLOYEES MANAGEMENT
//! start ADMISSIONS AND RESIGNATIONS
const admissionsResignation = {
	admissionsAndResignations: {
		label: 'Admissions & Resignations',
		path: `${adminPath}/admissions`,
		icon: <AdmissionIcon />,
		titleIcon: <AdmissionIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	admissionSheet: {
		label: 'Admission Sheet',
		path: '/admissionSheet/:token',
		icon: <AdmissionIcon />,
		titleIcon: <AdmissionIconOutlined style={styles} />,
		roles: []
	},
	newAdmission: {
		label: 'New Admission',
		path: `${adminPath}/admissions/newAdmission`,
		icon: <AdmissionIcon />,
		titleIcon: <AdmissionIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	admissionsManagement: {
		label: 'Manage Admissions',
		path: `${adminPath}/admissions/manageAdmissions`,
		icon: <AdmissionIcon />,
		titleIcon: <AdmissionIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	admissionsDetails: {
		label: 'Admission Details',
		path: `${adminPath}/admissions/admissionDetails/:type/:id`,
		icon: <AdmissionIcon />,
		titleIcon: <AdmissionIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	createEmployee: {
		label: 'Create Employee',
		path: `${adminPath}/createEmployee/:id`,
		icon: <AdmissionIcon />,
		titleIcon: <AdmissionIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	// RESIGNATION
	resignationManagement: {
		label: 'Resignation Management',
		path: `${adminPath}/admissions/resignations`,
		icon: <AdmissionIcon />,
		titleIcon: <AdmissionIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	resignUser: {
		label: 'Resign User',
		path: `${adminPath}/admissions/resignations/resign/:userId`,
		icon: <Person />,
		titleIcon: <Person style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	},
	deactivateUser: {
		label: 'Deactivate User',
		path: `${adminPath}/admissions/resignations/deactivate/:userId`,
		icon: <Person />,
		titleIcon: <Person style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	}
};
//! end ADMISSIONS AND RESIGNATIONS
//! start SALARIES AND CONTRACTS
const salariesAndContracts = {
	salariesAndContracts: {
		label: 'Salaries & Contracts',
		path: `${adminPath}/salariesAndContracts`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	// EMPLOYEE SALARY CATEGORIES
	employeeSalaryCategories: {
		label: 'Salary Categories',
		path: `${adminPath}/salariesAndContracts/employeeSalaryCategories`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	employeeSalaryCategoriesList: {
		label: 'Employee Salary Categories',
		path: `${adminPath}/salariesAndContracts/employeeSalaryCategories/details/:hash`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	editEmployeeCategory: {
		label: 'New Collaboration Type',
		path: `${adminPath}/editEmployee/:hash`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS, CP_ROLE_BUSINESS_UNIT_MANAGERS]
	},
	editEmployeeCategoryProposal: {
		label: 'Edit Collaboration Type',
		path: `${adminPath}/salariesAndContracts/editCollaborationType/:hash/:token/:id`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS, CP_ROLE_BUSINESS_UNIT_MANAGERS]
	},
	collaborationTypesManagment: {
		label: 'Collaboration Types',
		path: `${adminPath}/salariesAndContracts/collaborationTypes`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS, CP_ROLE_BUSINESS_UNIT_MANAGERS]
	},
	rejectEmployeeCategory: {
		label: 'Reject Collaboration Type',
		path: `${adminPath}/salariesAndContracts/collaborationTypes/reject/:token/:proposalId/:manager`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS, CP_ROLE_BUSINESS_UNIT_MANAGERS]
	},
	approveEmployeeCategory: {
		label: 'Approve Collaboration Type',
		path: `${adminPath}/salariesAndContracts/collaborationTypes/approve/:token/:proposalId/:manager`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	collaborationTypeList: {
		label: 'Collaboration Type List',
		path: `${adminPath}/salariesAndContracts/collaborationTypes/details/:hash`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS, CP_ROLE_BUSINESS_UNIT_MANAGERS]
	},
	newEmployeeSalary: {
		label: 'New Employee Salary',
		path: `${adminPath}/salariesAndContracts/newEmployeeSalary/:hash`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	editEmployeeSalary: {
		label: 'Edit Employee Salary',
		path: `${adminPath}/salariesAndContracts/editEmployeeSalary/:hash/:token/:proposalId`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	approveCostSalaryCategory: {
		label: 'Employee Salary Categories',
		path: `${adminPath}/salariesAndContracts/employeeSalaryCategories/approve/:token/:proposalId/:manager`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	rejectCostSalaryCategory: {
		label: 'Employee Salary Categories',
		path: `${adminPath}/salariesAndContracts/employeeSalaryCategories/reject/:token/:proposalId/:manager`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_PAYROLL_AND_BENEFITS,
			CP_ROLE_SALARY_CATEGORIES,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	cscAuditLog: {
		label: 'Csc Audit Log',
		path: `${adminPath}/salariesAndContracts/employeeSalaryCategories/audit/:hash/:token/:proposalId`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS, CP_ROLE_SALARY_CATEGORIES]
	},
	contractManagement: {
		label: 'Contract Management',
		path: `${adminPath}/salariesAndContracts/contracts/list`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PAYROLL_AND_BENEFITS]
	}
};
//! start RECRUITMENT
//! end SALARIES AND CONTRACTS
const recruitment = {
	recruitment: {
		label: 'Recruitment',
		path: `${adminPath}/recruitment`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_TALENT_ACQUISITION_ADMIN,
			CP_ROLE_TALENT_ACQUISITION,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	// PROPOSALS
	proposals: {
		label: 'Collaboration Proposals',
		path: `${adminPath}/recruitment/proposals`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_TALENT_ACQUISITION_ADMIN,
			CP_ROLE_TALENT_ACQUISITION,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	userProposals: {
		label: 'User Collaboration Proposals',
		path: `${adminPath}/recruitment/proposals/:token`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_TALENT_ACQUISITION_ADMIN,
			CP_ROLE_TALENT_ACQUISITION,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	sendProposalEmail: {
		label: 'Send Collaboration Proposals',
		path: `${adminPath}/recruitment/sendProposalEmail/:token/:proposalId`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_TALENT_ACQUISITION_ADMIN, CP_ROLE_TALENT_ACQUISITION]
	},
	newProposal: {
		label: 'New Proposal',
		path: `${adminPath}/recruitment/newProposal`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_TALENT_ACQUISITION_ADMIN, CP_ROLE_TALENT_ACQUISITION]
	},
	newProposalWithToken: {
		label: 'New Proposal',
		path: `${adminPath}/recruitment/newProposal/:token`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_TALENT_ACQUISITION_ADMIN, CP_ROLE_TALENT_ACQUISITION]
	},
	editProposal: {
		label: 'Edit Proposal',
		path: `${adminPath}/recruitment/editProposal/:token/:proposalId`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_TALENT_ACQUISITION_ADMIN,
			CP_ROLE_TALENT_ACQUISITION,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	approveProposal: {
		label: 'Collaboration Proposals',
		path: `${adminPath}/recruitment/proposals/approve/:token/:proposalId/:manager`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_TALENT_ACQUISITION_ADMIN,
			CP_ROLE_TALENT_ACQUISITION,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	rejectProposal: {
		label: 'Collaboration Proposals',
		path: `${adminPath}/recruitment/proposals/reject/:token/:proposalId/:manager`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [
			CP_ROLE_SYSTEM_ADMIN,
			CP_ROLE_TALENT_ACQUISITION_ADMIN,
			CP_ROLE_TALENT_ACQUISITION,
			CP_ROLE_BUSINESS_UNIT_MANAGERS
		]
	},
	proposalAuditLog: {
		label: 'Proposal Audit Log',
		path: `${adminPath}/recruitment/proposals/audit/:token/:proposalId`,
		icon: <ProposalsIcon />,
		titleIcon: <ProposalsIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_TALENT_ACQUISITION_ADMIN, CP_ROLE_TALENT_ACQUISITION]
	}
};
//! end RECRUITMENT
//! start FINANCIAL MANAGEMENT
const financialManagement = {
	financialManagement: {
		label: 'Financial Management',
		path: `${adminPath}/budget`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_BUDGET_ADMIN, CP_ROLE_BUDGET]
	},
	budget: {
		label: 'Budget',
		path: `${adminPath}/budget`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_BUDGET_ADMIN, CP_ROLE_BUDGET]
	},
	editBudget: {
		label: 'Edit Budget',
		path: `${adminPath}/budget/:budgetId`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_BUDGET_ADMIN, CP_ROLE_BUDGET]
	}
};
//! end FINANCIAL MANAGEMENT
//! start ORGANIZATIONAL MANAGEMENT
const organizationalManagement = {
	orgManagement: {
		label: 'Organizational Management',
		path: `${adminPath}/organizational/management`,
		icon: <CorporateFareIcon />,
		titleIcon: <CorporateFareIcon style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_CLIENTS, CP_ROLE_PROJECTS]
	},
	orgStructure: {
		label: 'Organizational Structure',
		path: `${adminPath}/organizational/management/organizational/structure`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_CLIENTS, CP_ROLE_PROJECTS]
	},
	projects: {
		label: 'Projects',
		path: `${adminPath}/organizational/management/projects`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PROJECTS]
	},
	listProjects: {
		label: 'List Projects',
		path: `${adminPath}/organizational/management/projects/listProjects`,
		icon: <ClientProjectIcon />,
		titleIcon: <ClientProjectIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PROJECTS]
	},
	newProject: {
		label: 'New Project',
		path: `${adminPath}/organizational/management/projects/newProject`,
		icon: <ClientProjectIcon />,
		titleIcon: <ClientProjectIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PROJECTS]
	},
	clients: {
		label: 'Client',
		path: `${adminPath}/organizational/management/clients`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_CLIENTS]
	},
	newClient: {
		label: 'New Client',
		path: `${adminPath}/organizational/management/newClient`,
		icon: <Euro />,
		titleIcon: <Euro style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_CLIENTS]
	}
};
//! end FINANCIAL MANAGEMENT
//! start SKILLS AND RESUMES
const skillsAndResumes = {
	skillsAndResumes: {
		label: 'Skills & Resumes Management',
		path: `${adminPath}/skillsResumes`,
		icon: <SkillsIcon />,
		titleIcon: <SkillsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_RESUMES, CP_ROLE_SKILLS, CP_ROLE_TALENT_ENGAGEMENT]
	},
	manageSkills: {
		label: 'Manage Skills',
		path: `${adminPath}/skillsResumes/manageSkills`,
		icon: <SkillsIcon />,
		titleIcon: <SkillsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_TALENT_ENGAGEMENT]
	},
	skillsEvaluation: {
		label: 'Skills Reminder',
		path: `${adminPath}/skillsResumes/skillsEvaluation`,
		icon: <SkillsIcon />,
		titleIcon: <SkillsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_SKILLS, CP_ROLE_TALENT_ENGAGEMENT]
	},
	skillsFeedback: {
		label: 'My Skills Feedback',
		path: '/skillsFeedback',
		icon: <SkillsIcon />,
		titleIcon: <SkillsOutlined style={styles} />,
		roles: undefined
	},
	searchResumes: {
		label: 'Search Resumes',
		path: `${adminPath}/skillsResumes/searchResumes`,
		icon: <SkillsIcon />,
		titleIcon: <SkillsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_RESUMES, CP_ROLE_TALENT_ENGAGEMENT]
	}
};
//! end SKILLS AND RESUMES
//! start PLANNER
const planner = {
	planner: {
		label: 'Planner',
		path: `${adminPath}/planner`,
		icon: <Today />,
		titleIcon: <Today style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_PLANNER]
	}
};
//! end PLANNER
//! start SYSTEMS MANAGEMENT
const systemsManagement = {
	systemsManagement: {
		label: 'System Management',
		path: `${adminPath}/system/management`,
		icon: <VpnKeyIcon />,
		titleIcon: <VpnKeyIcon style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_IT_OPERATIONS]
	},
	vpnAccess: {
		label: 'VPN Access',
		path: `${adminPath}/system/management/vpn`,
		icon: <VpnKeyIcon />,
		titleIcon: <VpnKeyIcon style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_IT_OPERATIONS]
	},
	accessControl: {
		label: 'Access Control',
		path: `${adminPath}/system/management/accessControl`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_IT_OPERATIONS]
	},
	accessControlAddUser: {
		label: 'Access Control',
		path: `${adminPath}/system/management/accessControl/addUser`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_IT_OPERATIONS]
	},
	roles: {
		label: 'Roles',
		path: `${adminPath}/administration/roles`,
		icon: <MailOutline />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_IT_OPERATIONS]
	}
};
//! end SYSTEMS MANAGEMENT
//! start APPROVALS
const approvals = {
	approvals: {
		label: 'Approvals',
		path: `${adminPath}/approvals`,
		icon: <CheckBoxIcon />,
		titleIcon: <CheckBoxIcon style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_XTRACKER_TESTER]
	},
	timesheets: {
		label: 'Timesheets',
		path: `${adminPath}/approvals/timesheets`,
		icon: <Group />,
		titleIcon: <EmployeesIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_XTRACKER_TESTER]
	},
	expenses: {
		label: 'Expenses',
		path: `${adminPath}/approvals/expenses`,
		icon: <Group />,
		titleIcon: <EmployeesIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_XTRACKER_TESTER]
	},
	timeOff: {
		label: 'Time Off',
		path: `${adminPath}/approvals/timeoff`,
		icon: <Group />,
		titleIcon: <EmployeesIconOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN, CP_ROLE_XTRACKER_TESTER]
	}
};
//! end APPROVALS
//! start ADMINISTRATION
const administration = {
	administration: {
		label: 'Administration',
		path: `${adminPath}/administration`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	emailTemplates: {
		label: 'Email Templates',
		path: `${adminPath}/administration/emailTemplates`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	cacheManagement: {
		label: 'Cache Management',
		path: `${adminPath}/administration/cacheManagement`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	fileTemplates: {
		label: 'File Templates',
		path: `${adminPath}/administration/fileTemplates`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	renewAuthorizationCode: {
		label: 'Renew Digital Sign Authorization Code',
		path: `${adminPath}/renewAuthorizationCode`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	releaseNotes: {
		label: 'Release History',
		path: `${adminPath}/administration/releaseNotes`,
		icon: <Settings />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	sendReleaseNotesEmail: {
		label: 'Send Release Notes Email',
		path: `${adminPath}/administration/releaseNotes/sendEmail/:id/:name/:projName`,
		icon: <MailOutline />,
		titleIcon: <MailOutline style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	jobExecutions: {
		label: 'Job Executions',
		path: `${adminPath}/administration/jobs/executions`,
		icon: <MailOutline />,
		titleIcon: <Handyman style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	appLinks: {
		label: 'App Links',
		path: `${adminPath}/configurations/app/links`,
		icon: <MailOutline />,
		titleIcon: <LinkIcon style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	users: {
		label: 'Users',
		path: `${adminPath}/administration/users`,
		icon: <MailOutline />,
		titleIcon: <Handyman style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	},
	userGuides: {
		label: 'User Guides',
		path: `${adminPath}/administration/userGuides`,
		icon: <MailOutline />,
		titleIcon: <SettingsOutlined style={styles} />,
		roles: [CP_ROLE_SYSTEM_ADMIN]
	}
};
export let pages: Record<string, ISidebarGroupItem> = {
	...personalInfo,
	//! start EMPLOYEES MANAGEMENT
	...employeeManagment,
	//! end EMPLOYEES MANAGEMENT
	//! start ADMISSIONS AND RESIGNATIONS
	...admissionsResignation,
	//! end ADMISSIONS AND RESIGNATIONS
	//! start SALARIES AND CONTRACTS
	...salariesAndContracts,
	//! end SALARIES AND CONTRACTS
	//! start RECRUITMENT
	...recruitment,
	//! end RECRUITMENT
	//! start FINANCIAL MANAGEMENT
	...financialManagement,
	//! end FINANCIAL MANAGEMENT
	...organizationalManagement,
	//! start SKILLS AND RESUMES
	...skillsAndResumes,
	//! end SKILLS AND RESUMES
	//! start PLANNER
	...planner,
	//! end PLANNER
	...systemsManagement,
	//! start REQUESTS
	...approvals,
	//! start ADMINISTRATION
	...administration
	//! end ADMINISTRATION
};

const defaultPages = {
	...personalInfo,
	//! start EMPLOYEES MANAGEMENT
	...employeeManagment,
	//! end EMPLOYEES MANAGEMENT
	//! start ADMISSIONS AND RESIGNATIONS
	...admissionsResignation,
	//! end ADMISSIONS AND RESIGNATIONS
	//! start SALARIES AND CONTRACTS
	...salariesAndContracts,
	//! end SALARIES AND CONTRACTS
	//! start RECRUITMENT
	...recruitment,
	//! end RECRUITMENT
	//! start FINANCIAL MANAGEMENT
	...financialManagement,
	//! end FINANCIAL MANAGEMENT
	...organizationalManagement,
	//! start SKILLS AND RESUMES
	...skillsAndResumes,
	//! end SKILLS AND RESUMES
	//! start PLANNER
	...planner,
	//! end PLANNER
	...systemsManagement,
	//! start REQUESTS
	...approvals,
	//! end REQUESTS
	//! start ADMINISTRATION
	...administration
	//! end ADMINISTRATION
};
export const NewRoutes: React.FunctionComponent = ({}) => {
	const skills = useSelector(state => state.personalInfo.systemSkills);

	useEffect(() => {
		if (skills) {
			//Whenever skills changes reset pages
			pages = defaultPages;
			//get all added skills
			let lastSkill = [];
			//Key for pages object
			let key = '';
			for (let skill of skills) {
				//Do the necessary changes to skill name
				let endTag = skill.name.split(' ');
				let name: string = '';
				for (let word of endTag) {
					name += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
				}
				key = skill.name.replace(/\s/g, '');
				//Check if their is skills with the same name, if so adds a number to the indentifier
				let repeatedNameSkills = 0;
				for (let sk of lastSkill) {
					if (sk === skill.name.replace(/\s/g, '')) {
						repeatedNameSkills++;
						key = skill.name.replace(/\s/g, '') + repeatedNameSkills;
					}
				}

				//Updates pages with the skills
				pages = {
					...pages,
					[key]: {
						label: name,
						path: `/skills/${endTag[0].toLowerCase() + '_' + skill.id}`,
						icon: <SkillsIcon />,
						titleIcon: <SkillsOutlined style={styles} />,
						roles: undefined
					}
				};
				lastSkill.push(skill.name.replace(/\s/g, ''));
			}
		}
	}, [skills]);

	return <></>;
};
