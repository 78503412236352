/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';
import {
	fetchNewAdmission,
	postNewAdmission,
	fetchAdmissionSheetData,
	postAdmissionSheet,
	fetchAdmissionManagementData,
	fetchAdmissionDataToValidate,
	fetchEmployeeData,
	sendPendingAdmission,
	resendPendingAdmission,
	postCancelAdmission,
	postApproveAdmission,
	postInvalidateAdmission,
	fetchFileById,
	deleteFileById,
	fetchSubmissionInfo,
	// create employee
	fetchCreateEmployee,
	sendCreateEmployee,

	// 	RESIGNATION
	fetchResignationList,
	fetchResignationForm,
	submitResignUser,
	fetchDeactivateForm,
	submitDeactivateUser,
	fetchExportCertificate,
	fetchExportReceipt
} from 'lib/api/administration/admissions';
import {
	// Admissions
	postDocumentsValidation
} from 'lib/api/general/apps';
import {
	IDeactivateFormPayload,
	IIdDocs,
	IInvalidateAdmission,
	INewAdmissionPayload,
	IResignFormGet,
	IResignFormPayload,
	IUsersResignationList
} from 'typings/store/admin/admissions';
import { IProposalPayload } from 'typings/store/admin/proposals';
import { OriginalResponse } from 'lib/handlers/requestTypes';
import {
	// Admissions
	setLoading,
	setNewAdmission,
	setPostNewAdmission,
	setAdmissionSheet,
	setPostAdmissionSheet,
	setInvalidDocuments,
	setBlockAdmissionSheetView,
	setAdmissionManagement,
	setAdmissionDetails,
	setEmployeeData,
	setPostSendAdmission,
	setPostResendAdmission,
	setPostCancelAdmission,
	setPostApproveAdmission,
	setPostInvalidateAdmission,
	setGetFile,
	setDeleteFile,
	setSubmissionInfo,
	// create employee
	setCreateEmployee,
	setPostCreateEmployee,
	setClearNewEmployeeForm,

	// 	RESIGNATION
	setResignationList,
	setResignationForm,
	setResignationFormSubmit,
	setDeactivateForm,
	setDeactivateFormSubmit,
	setResginAction,
	setAdmissionsError,
	setExportCertificate,
	setExportReceipt
} from './actions';

export const getNewAdmission =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchNewAdmission();
			if (data) {
				dispatch(setNewAdmission(data));
			} else {
				dispatch(setAdmissionsError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setNewAdmission([]));
		}
	};

export const sendNewAdmission =
	(payload: INewAdmissionPayload) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const res = await postNewAdmission(payload);
			dispatch(setPostNewAdmission(res));
		} catch (error) {
			dispatch(setPostNewAdmission(null));
		}
	};

// Admission sheet
export const getAdmissionSheetData =
	(payload: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await fetchAdmissionSheetData(payload).then(response => {
				// if code 403 the Admission sheet page must be blocked
				if (response.code === 403) {
					dispatch(setBlockAdmissionSheetView(true));
				} else {
					dispatch(setAdmissionSheet({ token: payload, data: response.data }));
				}
			});
		} catch (error) {
			dispatch(setAdmissionSheet(null));
		}
	};

export const sendAdmissionSheet =
	(token: string, docs: IIdDocs[], payload: FormData) =>
	async (dispatch: Dispatch): Promise<boolean | void> => {
		try {
			dispatch(setLoading());
			// if docs has documents, they must be validaded by our BE
			if (!!docs && docs.length > 0) {
				const resultCheckDoc = await postDocumentsValidation(docs).then(async response => {
					if (response.payload && response.payload.data) {
						dispatch(setInvalidDocuments(response.payload.data.invalidFields));
						return false;
					}

					return true;
				});

				if (resultCheckDoc) {
					return await postAdmissionSheet(token, payload).then(async response => {
						if (response.code && response.code !== 200) {
							dispatch(setPostAdmissionSheet(null));
							return true;
						}

						dispatch(setPostAdmissionSheet({ token, response }));
						return true;
					});
				}

				// return false;
				return await new Promise(resolve => {
					resolve(false);
				});
			}
			// docs are validated, the data can be submitted
			await postAdmissionSheet(token, payload).then(response =>
				dispatch(setPostAdmissionSheet({ token, response }))
			);

			return await new Promise(resolve => {
				resolve(true);
			});
		} catch (error) {
			dispatch(setPostAdmissionSheet(null));
			return await new Promise(resolve => {
				resolve(false);
			});
		}
	};

// Admission Management
export const getAdmissionManagementData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchAdmissionManagementData();
			if (data) {
				dispatch(setAdmissionManagement(data));
			} else {
				dispatch(setAdmissionsError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAdmissionManagement(null));
		}
	};

// Admission Details
export const getAdmissionDetailsToValidate =
	(admissionId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchAdmissionDataToValidate(admissionId);
			if (data) {
				dispatch(setAdmissionDetails(data));
			} else {
				dispatch(setAdmissionsError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAdmissionDetails(null));
		}
	};

// Admission Details
export const getSubmissionInfo =
	(admissionId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchSubmissionInfo(admissionId);
			if (data) {
				dispatch(setSubmissionInfo(data));
			} else {
				dispatch(setAdmissionsError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setSubmissionInfo(null));
		}
	};

// Admission Details
export const clearAdmissionDetails =
	(isValidate: boolean | null) =>
	async (dispatch: Dispatch): Promise<void> => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		isValidate ? dispatch(setAdmissionDetails(null)) : dispatch(setSubmissionInfo(null));
	};

// Get Employee Data
export const getEmployeeData =
	(admissionId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchEmployeeData(admissionId);
			if (data) {
				dispatch(setEmployeeData(data));
			} else {
				dispatch(setAdmissionsError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeData(null));
		}
	};

export const sendAdmission =
	(admissionId: number | null) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			if (!admissionId) {
				dispatch(setPostSendAdmission(null));
			} else {
				dispatch(setLoading());
				const { data } = await sendPendingAdmission(admissionId);
				dispatch(setPostSendAdmission(data));
			}
		} catch (error) {
			dispatch(setPostSendAdmission(null));
		}
	};

export const resendAdmission =
	(admissionId: number | null) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			if (!admissionId) {
				dispatch(setPostResendAdmission(null));
			} else {
				dispatch(setLoading());
				await resendPendingAdmission(admissionId).then(res => dispatch(setPostResendAdmission(res)));
			}
		} catch (error) {
			dispatch(setPostResendAdmission(null));
		}
	};

export const cancelAdmission =
	(admissionId: number | null) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			if (!admissionId) {
				dispatch(setPostCancelAdmission(null));
			} else {
				dispatch(setLoading());
				await postCancelAdmission(admissionId).then(res => dispatch(setPostCancelAdmission(res)));
			}
		} catch (error) {
			dispatch(setPostCancelAdmission(null)); // ERROR
		}
	};

export const approveAdmission =
	(admissionId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await postApproveAdmission(admissionId).then(res => dispatch(setPostApproveAdmission(res)));
		} catch (error) {
			dispatch(setPostApproveAdmission(null));
		}
	};

export const invalidateAdmission =
	(admissionId: string, payload: IInvalidateAdmission) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await postInvalidateAdmission(admissionId, payload);
			dispatch(setPostInvalidateAdmission(data));
		} catch (error) {
			dispatch(setPostInvalidateAdmission(null));
		}
	};

export const getAdmissionFile =
	(admissionId: string, fileId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setLoading());
		await fetchFileById(admissionId, fileId).then(res => dispatch(setGetFile(res)));
	};

export const deleteAdmissionFile =
	(admissionId: string, fileId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setLoading());
		await deleteFileById(admissionId, fileId).then(res => dispatch(setDeleteFile(res)));
	};

//	CREATE EMPLOYEE
// Admission Management - Create Employee GET
export const getCreateEmployee =
	(employeeAdmissionId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const data = await fetchCreateEmployee(employeeAdmissionId);
			if (data) {
				dispatch(setCreateEmployee(data.code ? data : data.data));
			} else {
				dispatch(setAdmissionsError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setCreateEmployee(null));
		}
	};

// Admission Management - Create Employee POST
export const submitCreateEmployee =
	(admissionId: string, payload: IProposalPayload, token: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const data = await sendCreateEmployee(admissionId, payload, token);
			dispatch(setPostCreateEmployee(data));
		} catch (error) {
			dispatch(setPostCreateEmployee(null));
		}
	};

// Admission Management - Create Employee POST
export const clearNewEmployeeForm =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearNewEmployeeForm());
	};

// Admission Management - Create Employee POST
export const clearEmployeeData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setEmployeeData(null));
	};

// 	RESIGNATION
export const getResignationList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IUsersResignationList } = await fetchResignationList();
			if (response) {
				dispatch(setResignationList(response.data || null));
			} else {
				dispatch(setAdmissionsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setResignationList(null));
		}
	};

export const getResignationForm =
	(userId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IResignFormGet } = await fetchResignationForm(userId);
			if (response) {
				dispatch(setResignationForm(response.data || null));
			} else {
				dispatch(setAdmissionsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setResignationForm(null));
		}
	};

export const submitResignationForm =
	(userId: number, payload: IResignFormPayload) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await submitResignUser(userId, payload);
			dispatch(setResignationFormSubmit(response.data));
		} catch (error) {
			dispatch(setResignationFormSubmit(null));
		}
	};

export const getDeactivateForm =
	(userId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IResignFormGet } = await fetchDeactivateForm(userId);
			if (response) {
				dispatch(setDeactivateForm(response.data || null));
			} else {
				dispatch(setAdmissionsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setDeactivateForm(null));
		}
	};

export const submitDeactivateForm =
	(userId: number, payload: IDeactivateFormPayload) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await submitDeactivateUser(userId, payload);
			dispatch(setDeactivateFormSubmit(response.data));
		} catch (error) {
			dispatch(setDeactivateFormSubmit(null));
		}
	};

export const resetResginAction =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setResginAction());
	};

export const clearAdmissionsError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setAdmissionsError(null));
	};

export const exportCertificate =
	(payload: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const notUsed: unknown = await fetchExportCertificate(payload);
			dispatch(setExportCertificate(notUsed));
		} catch (error) {
			dispatch(setExportCertificate([]));
		}
	};

export const exportReceipt =
	(payload: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const notUsed: unknown = await fetchExportReceipt(payload);
			dispatch(setExportReceipt(notUsed));
		} catch (error) {
			dispatch(setExportReceipt([]));
		}
	};
